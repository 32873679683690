import { Input, message, Select, Tag } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import Table2 from "../../../../../component/antd-table/table2";
import RangePickerESF from "../../../../../component/range-picker";
import {
  ConvertParams,
  DateFormat,
  DisplayNotification,
  GetStatusOfCase,
  GetStatusOfCaseColor,
  PaginationConfig,
  NumberWithCommas,
  GetMarketplaceDropdown,
  GetMarketplaceList,
  DownloadFileUsingApi,
} from "../../../../../config";

const DamagedReturns = (props) => {
  const { case_status_list, manager_list } = props;

  const [sortFilters, setSortFilters] = useState("");
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [tableData, setTableData] = useState();
  const [tableLoading, setTableLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});

  const [selected_marketplace, set_selected_marketplace] = useState(null);
  const [marketplace_list, set_marketplace_list] = useState([]);
  const [currency_list, set_currency_list] = useState({});
  const [selected_currency_sign, set_selected_currency_sign] = useState("");

  const [filters, setFilters] = useState({
    search_text: "",
    // transaction_status: null,
    case_id: null,
    manager_id: null,
    status_of_the_case: null,
  });

  const [date_range, set_date_range] = useState({
    start_date: dayjs().startOf("year"),
    end_date: dayjs().add(-2, "d"),
  });

  const getTableData = async (data) => {
    try {
      setTableData([]);
      setTableLoading(true);
      const response = await MakeApiCall(
        `rms/get-damaged-return${ConvertParams(data)}`,
        `get`,
        null,
        true
      );
      if (response?.status) {
        setTotalPage(response?.data?.pagination?.totalCount || 0);
        setCurrentPage(response?.data?.pagination?.page || 1);
        setPageSize(response?.data?.pagination?.page_size || 1);
        setTableData(response?.data?.records || []);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        DisplayNotification("error", "Error", response?.message);
      }
    } catch (error) {
      DisplayNotification("error", "Error", error?.message);
      setTableData([]);
      setTableLoading(false);
    }
  };

  const GetMarketplace = async () => {
    const marketplace = await GetMarketplaceDropdown();
    if (marketplace?.marketplace_list?.length > 0) {
      set_marketplace_list(marketplace?.marketplace_list);
      set_selected_marketplace(
        marketplace?.marketplace_list[0]?.marketplace_id
      );
      set_currency_list(marketplace?.currency_list);
      set_selected_currency_sign(
        marketplace?.currency_list[
          marketplace?.marketplace_list[0]?.marketplace_id
        ]
      );
      setFilters({
        ...filters,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
      });
      getTableData({
        ...filters,
        page: currentPage,
        sort: sortFilters,
        "per-page": pageSize,
        marketplace_id: marketplace?.marketplace_list[0]?.marketplace_id,
        start_date: moment(date_range?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(date_range?.end_date?.$d).format("YYYY-MM-DD"),
      });
    } else {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    setTableLoading(true);
    setTableData([]);
    GetMarketplace();
    return () => {};
  }, []);

  const columns = [
    {
      title: "#",
      width: 100,
      ellipsis: true,
      fixed: "left",
      render: (_, __, i) => {
        return (
          <>
            <span>{(currentPage - 1) * pageSize + 1 + i}</span>
          </>
        );
      },
    },
    {
      title: "Return Date",
      dataIndex: "return_date",
      key: "return_date",
    },
    {
      title: "Refund/Replacement Date",
      dataIndex: "refund_replacement_date",
      key: "refund_replacement_date",
    },
    {
      title: "Order ID",
      dataIndex: "order_id",
      key: "order_id",
    },
    {
      title: "Order ID Status",
      dataIndex: "order_id_status",
      key: "order_id_status",
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
    },
    {
      title: "FNSKU",
      dataIndex: "fnsku",
      key: "fnsku",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Detailed Disposition",
      dataIndex: "detailed_disposition",
      key: "detailed_disposition",
    },
    {
      title: "Order Payment Amount",
      dataIndex: "order_payment_amount",
      key: "order_payment_amount",
      render: (amount) => `$${amount.toFixed(2)}`, // Format as currency
    },
    {
      title: "Return Report Status",
      dataIndex: "return_report_status",
      key: "return_report_status",
    },
    {
      title: "Case ID",
      dataIndex: "case_id",
      key: "case_id",
    },
    {
      title: "Case Status",
      dataIndex: "case_status",
      key: "case_status",
    },
    {
      title: "RMS ID",
      dataIndex: "rms_id",
      key: "rms_id",
    },
    {
      title: "Quantity Reimbursed Total",
      dataIndex: "quantity_reimbursed_total",
      key: "quantity_reimbursed_total",
    },
    {
      title: "Quantity Reimbursed by Cash",
      dataIndex: "quantity_reimbursed_cash",
      key: "quantity_reimbursed_cash",
    },
    {
      title: "Quantity Reimbursed by Inventory",
      dataIndex: "quantity_reimbursed_inventory",
      key: "quantity_reimbursed_inventory",
    },
    {
      title: "Credited Amount",
      dataIndex: "credited_amount",
      key: "credited_amount",
      render: (amount) => `$${amount.toFixed(2)}`, // Format as currency
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Comment",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "Date Submitted",
      dataIndex: "date_submitted",
      key: "date_submitted",
    },
    {
      title: "Date Resolved",
      dataIndex: "date_resolved",
      key: "date_resolved",
    },
    {
      title: "Manager",
      dataIndex: "manager",
      key: "manager",
      render: (text) => (
        <div className="text-primary fw-bold">{text?.name}</div>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text) => {
        return (
          <div>
            <button
              class="btn btn-icon btn-bg-light btn-active-color-success btn-sm"
              onClick={() => {
                setIsOpenModal(true);
                setSelectedRowData(text);
              }}
            >
              <i class="ki-outline ki-pencil fs-2 text-success"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      {" "}
      <div className="row mb-5 px-0 mx-0">
        <div className="col-xxl-12">
          <div
            className="card"
            style={{ background: " #e9f1fa", border: "1px dashed #3699ff69" }}
          >
            <div className="card-header border-0 d-flex align-items-center p-5">
              <div>
                <div className="d-flex flex-wrap gap-4 align-items-center">
                  <div className="position-relative">
                    <Input
                      style={{ width: "250px" }}
                      prefix={
                        <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                      }
                      onChange={(e) => {
                        setFilters({ ...filters, search_text: e.target.value });
                      }}
                      placeholder="Search by FNSKU"
                      size="large"
                    />
                  </div>
                  <div className="position-relative">
                    <RangePickerESF
                      className="ant_common_input"
                      id="ivcsr_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormat}
                      value={[date_range?.start_date, date_range?.end_date]}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={(e) => {
                        const date_range_ = date_range;
                        date_range_.start_date = e?.[0];
                        date_range_.end_date = e?.[1];
                        set_date_range({ ...date_range_ });
                      }}
                    />
                  </div>
                  {/* <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "200px" }}
                      options={[]}
                      placeholder="Transaction Status"
                      onChange={(e) => {
                        setFilters({ ...filters, transaction_status: e });
                      }}
                    />
                  </div> */}
                  {/* <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "200px" }}
                      options={[]}
                      placeholder="Case Id"
                      onChange={(e) => {
                        setFilters({ ...filters, case_id: e });
                      }}
                    />
                  </div>*/}
                  <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "200px" }}
                      options={manager_list?.map((d) => {
                        return {
                          label: d?.name,
                          value: d?.id,
                        };
                      })}
                      placeholder="Manager"
                      onChange={(e) => {
                        setFilters({ ...filters, manager_id: e });
                      }}
                    />
                  </div>
                  <div className="position-relative">
                    <Select
                      size="large"
                      style={{ width: "200px" }}
                      options={case_status_list?.map((d) => {
                        return {
                          label: d?.status,
                          value: d?.id,
                        };
                      })}
                      placeholder="Case Status"
                      onChange={(e) => {
                        setFilters({ ...filters, status_of_the_case: e });
                      }}
                    />
                  </div>{" "}
                  <div className="position-relative">
                    <Select
                      className="w-200px"
                      size="large"
                      placeholder="Select Marketplace"
                      options={GetMarketplaceList(marketplace_list)}
                      value={selected_marketplace}
                      onChange={(e) => {
                        set_selected_marketplace(e);
                        set_selected_currency_sign(currency_list[e]);
                        const apiObj = {
                          ...filters,
                          marketplace_id: e,
                        };
                        setFilters(apiObj);
                      }}
                    />
                  </div>
                  <div className="position-relative ">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        setPageSize(pageSize);
                        setCurrentPage(1);
                        getTableData({
                          page: 1,
                          "per-page": pageSize,
                          ...filters,
                          sortFilters,
                          start_date: moment(date_range?.start_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                          end_date: moment(date_range?.end_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                        });
                      }}
                    >
                      {" "}
                      <i class="ki-outline ki-magnifier fs-2"></i>
                      {/* Search */}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row gy-5 g-xl-5 px-0 mx-0">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header border-0">
              <div className="d-flex align-items-center flex-wrap p-2">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label fw-bold fs-3 mb-1">
                    Damaged Return
                  </span>
                </h3>
              </div>
              <div className="card-toolbar">
                <div className="d-flex align-items-center flex-wrap p-2 gap-2">
                  {" "}
                  <div className="position-relative">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        const data = {
                          ...filters,
                          page: 1,
                          sort: sortFilters,
                          "per-page": pageSize,
                          start_date: moment(date_range?.start_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                          end_date: moment(date_range?.end_date?.$d).format(
                            "YYYY-MM-DD"
                          ),
                          isExport: 1,
                        };
                        // message.loading("Loading...", 0);
                        // DownloadFileUsingApi(
                        //   `https://api.aalacart.com/api/v1/rms/get-damaged-return${ConvertParams(
                        //     data
                        //   )}`,
                        //   "damaged-return"
                        // );
                      }}
                    >
                      Cases Export
                    </button>
                  </div>
                  <div className="position-relative">
                    <button className="btn btn-light-primary">Import</button>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="separator separator-dashed"></div>
            <div className="card-body py-2">
              <div className="table-responsive">
                <Table2
                  columns={columns}
                  setSortFilters={setSortFilters}
                  sortFilters={sortFilters}
                  dataSource={tableData}
                  loading={tableLoading}
                  scroll={{ x: "max-content" }}
                  rowKey="sku"
                  onSortChange={(sort, currentPage, pageSize) => {
                    setPageSize(pageSize);
                    setCurrentPage(currentPage);
                    getTableData({
                      page: currentPage,
                      "per-page": pageSize,
                      ...filters,
                      sort,
                      start_date: moment(date_range?.start_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                      end_date: moment(date_range?.end_date?.$d).format(
                        "YYYY-MM-DD"
                      ),
                    });
                  }}
                  pagination={PaginationConfig({
                    pageSize,
                    currentPage,
                    totalPage,
                  })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {isOpenModal && (
        <LostInboundModal
          show={setIsOpenModal}
          onHide={() => {
            setIsOpenModal(false);
          }}
          data={selectedRowData}
        />
      )} */}
    </>
  );
};

export default DamagedReturns;
