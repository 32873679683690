import React, {useState}from 'react'
import { Wrapper } from './style'
import { Card, Col, Row, Select, Space, Input, ConfigProvider, Segmented, Button, Table, Flex} from 'antd'
import RangePickerESF from '../../../../component/range-picker'
import { Icon } from '@iconify/react/dist/iconify.js'
import Chart from 'react-apexcharts';
import { primaryColor } from '../../../../config'



function ProductAnalytics() {

    const [value, setValue] = useState('Show All');
    const [selectValue, setSelectValue] = useState(null); 
    const [fixedTop, setFixedTop] = useState(false);

    
    const height = 350; // Set a fixed height for the chart
    const grayColor = '#6c757d'; // Direct color for gray (instead of "--bs-gray-500")
    const borderColor = '#dee2e6'; // Direct color for border (instead of "--bs-border-dashed-color")
    const successColor = '#28a745'; // Direct color for success (instead of "--bs-success")

    const options = {
        chart: {
          type: 'area',
          height: 320,
          fontFamily: 'inherit',
         toolbar: { show: false },
 
        },
        plotOptions: {},
        legend: { show: false },
        dataLabels: { enabled: false },
        fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 80, 100],
            },
          },
          stroke: { curve: 'smooth', show: true, width: 3, colors: [primaryColor] },

          xaxis: {
            categories: ['', 'Oct 02', 'Oct 03', 'Oct 04', 'Oct 05', 'Oct 06', 'Oct 07', 'Oct 08', 'Oct 09', 'Oct 10', 'Oct 11', 'Oct 12', 'Oct 13', 'Oct 14', 'Oct 15', 'Oct 16', 'Oct 17', 'Oct 18', ''],
            axisBorder: { show: false },
            axisTicks: { show: false },

          },
          yaxis: {
            tickAmount: 4,
            max: 24,
            min: 10,
            labels: {
              style: { colors: grayColor, fontSize: '14px' },
              formatter: (value) => `$${value}K`,
            },
          },
          states: {
            normal: { filter: { type: 'none', value: 0 } },
            hover: { filter: { type: 'none', value: 0 } },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: { type: 'none', value: 0 },
            },
          },
          tooltip: {
            style: { fontSize: '14px' },
            y: {
              formatter: (value) => `$${value}K`,
            },
          },
          colors: [primaryColor],
          grid: {
            borderColor: borderColor,
            strokeDashArray: 3,
            yaxis: { lines: { show: true } },
          },
          markers: { strokeColor: primaryColor, strokeWidth: 3 },
      };
    
      const series = [
        {
            name: 'Revenue',
            data: [18, 18, 20, 20, 18, 18, 22, 22, 20, 20, 18, 18, 20, 20, 18, 18, 20, 20, 22],
          },
      ];
    
      


      const customTheme = {
        components: {
          Segmented: {
            itemActiveBg: '#3699ff26',   // Active item background (light red)
            itemColor: '#000',         // Default item text color
            itemHoverBg: '#3699ff26',      // Hover background (lighter red)
            itemHoverColor: '#000',    // Hover text color
            itemSelectedBg: primaryColor, // Selected item background color (red)
            itemSelectedColor: 'white', // Selected item text color (white)
            trackBg: '#fff',  // Background for the Segmented container
            trackPadding: 4,  // Padding for the Segmented container
          },
        },
      };


      const data = [
        {
          key: 1,
          category: 'Winter Set',
          channels: 'USA',
          totalSales: '$8,015.98',
          totalUnits: `${402} Units`,
          avgCogsPerUnit: '$95.68',
          totalCogs: '$1,022.78',
          handlingFees: '$2,604.82',
          referralFees: '$835.83',
          shippingFees: '$26.54',	
          totalGrossMargin: '$6,993.20',	
          marginBeforeAdsCost: '$3,526.01',	
          totalCostofAds: '$1,562.22',
          netProfit: '$1,963.79',
          products: [
            {
              key: '1-1',
              image:'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
              product: '3 Pieces Set Matching Hat, Gloves...',
              sku: 'WSET60-Purple-FBA',
              asin: 'B00PSN3CUE',
              marketplace: 'Amazon.USA',
              qtySold: 88,
              sales: '$19.99',
              totalSales: '$1,759.12',
              cogsPerUnit: '$2.54',
              cogs: '$223.52',
              cogsPercent: '12.71%',
              handlingFees: '$568',
              referralFees: '$176.00',	
              shippingFees: '$0.00',	
              totalGrossMargin: '$1,535.60',		
              marginBeforeAdsCost: '$791.48',		
              costofAds: '$538.12',	
              netProfit: '$253.36',	
              returns: '4',	
              returnRevenue: '$79.96',	
            },
            {
                key: '1-2',
                image:'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
                product: '3 Pieces Set Matching Hat, Gloves...',
                sku: 'WSET60-Purple-FBA',
                asin: 'B00PSN3CUE',
                marketplace: 'Amazon.USA',
                qtySold: 88,
                sales: '$19.99',
                totalSales: '$1,759.12',
                cogsPerUnit: '$2.54',
                cogs: '$223.52',
                cogsPercent: '12.71%',
                handlingFees: '$568',
                referralFees: '$176.00',	
                shippingFees: '$0.00',	
                totalGrossMargin: '$1,535.60',		
                marginBeforeAdsCost: '$791.48',		
                costofAds: '$538.12',	
                netProfit: '$253.36',	
                returns: '4',	
                returnRevenue: '$79.96',	
              },
              {
                key: '1-3',
                image:'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
                product: '3 Pieces Set Matching Hat, Gloves...',
                sku: 'WSET60-Purple-FBA',
                asin: 'B00PSN3CUE',
                marketplace: 'Amazon.USA',
                qtySold: 88,
                sales: '$19.99',
                totalSales: '$1,759.12',
                cogsPerUnit: '$2.54',
                cogs: '$223.52',
                cogsPercent: '12.71%',
                handlingFees: '$568',
                referralFees: '$176.00',	
                shippingFees: '$0.00',	
                totalGrossMargin: '$1,535.60',		
                marginBeforeAdsCost: '$791.48',		
                costofAds: '$538.12',	
                netProfit: '$253.36',	
                returns: '4',	
                returnRevenue: '$79.96',	
              },
      
          ],
        },
        {
            key: 2,
            category: 'Clip-On Tie',
            channels: 'USA',
            totalSales: '$8,015.98',
            totalUnits: `${504} Units`,
            avgCogsPerUnit: '$95.68',
            totalCogs: '$1,022.78',
            handlingFees: '$2,604.82',
            referralFees: '$835.83',
            shippingFees: '$26.54',	
            totalGrossMargin: '$6,993.20',	
            marginBeforeAdsCost: '$3,526.01',	
            totalCostofAds: '$1,562.22',
            netProfit: '$1,963.79',
            products: [
              {
                key: '1-1',
                image:'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
                product: '3 Pieces Set Matching Hat, Gloves...',
                sku: 'WSET60-Purple-FBA',
                asin: 'B00PSN3CUE',
                marketplace: 'Amazon.USA',
                qtySold: 88,
                sales: '$19.99',
                totalSales: '$1,759.12',
                cogsPerUnit: '$2.54',
                cogs: '$223.52',
                cogsPercent: '12.71%',
                handlingFees: '$568',
                referralFees: '$176.00',	
                shippingFees: '$0.00',	
                totalGrossMargin: '$1,535.60',		
                marginBeforeAdsCost: '$791.48',		
                costofAds: '$538.12',	
                netProfit: '$253.36',	
                returns: '4',	
                returnRevenue: '$79.96',	
              },
              {
                  key: '1-2',
                  image:'https://media.istockphoto.com/id/1147544807/vector/thumbnail-image-vector-graphic.jpg?s=612x612&w=0&k=20&c=rnCKVbdxqkjlcs3xH87-9gocETqpspHFXu5dIGB4wuM=',
                  product: '3 Pieces Set Matching Hat, Gloves...',
                  sku: 'WSET60-Purple-FBA',
                  asin: 'B00PSN3CUE',
                  marketplace: 'Amazon.USA',
                  qtySold: 88,
                  sales: '$19.99',
                  totalSales: '$1,759.12',
                  cogsPerUnit: '$2.54',
                  cogs: '$223.52',
                  cogsPercent: '12.71%',
                  handlingFees: '$568',
                  referralFees: '$176.00',	
                  shippingFees: '$0.00',	
                  totalGrossMargin: '$1,535.60',		
                  marginBeforeAdsCost: '$791.48',		
                  costofAds: '$538.12',	
                  netProfit: '$253.36',	
                  returns: '4',	
                  returnRevenue: '$79.96',	
                },
            ],
          },
      ];
      
      const columns = [
        {
          title: 'Category',
          dataIndex: 'category',
          key: 'category',
          render: (_, record) => (
            <h5 className='min-w-125px'>{record.category}</h5>
          ),
        },
        {
          title: 'Channels',
          dataIndex: 'channels',
          key: 'channels',
        },
        {
          title: 'Total Sales',
          dataIndex: 'totalSales',
          key: 'totalSales',
          render: (_, record) => (
            <>
            <h6 className='min-w-125px fw-bold mb-0'>{record.totalSales}</h6>
            <div className='fs-8'>({record.totalUnits})</div>
            </>
          ),
        },
        {
          title: 'Avg COGS Per Unit',
          dataIndex: 'avgCogsPerUnit',
          key: 'avgCogsPerUnit',
        },
        {
          title: 'Total COGS',
          dataIndex: 'totalCogs',
          key: 'totalCogs',
        },
        {
          title: 'Handling Fees',
          dataIndex: 'handlingFees',
          key: 'handlingFees',
        },
        {
            title: 'Referral Fees',
            dataIndex: 'referralFees',
            key: 'referralFees',
        },
        {
            title: 'Shipping Fees',
            dataIndex: 'shippingFees',
            key: 'shippingFees',
        },
        {
            title: 'Total Gross Margin',
            dataIndex: 'totalGrossMargin',
            key: 'totalGrossMargin',
        },
        {
            title: 'Margin Before Ads Cost',
            dataIndex: 'marginBeforeAdsCost',
            key: 'marginBeforeAdsCost',
        },
        {
            title: 'Total Cost of Ads',
            dataIndex: 'totalCostofAds',
            key: 'totalCostofAds',
        },
        {
            title: 'Net Profit',
            dataIndex: 'netProfit',
            key: 'netProfit',
        },
      ];
          
      const expandedRowRender = (record) => {
        const productColumns = [
          {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            render: (_, record) => (
                <div className="d-flex align-items-center border border-dashed border-gray-300 p-3 rounded bdr-left-user1 w-400px">
                <div className="symbol symbol-60px overflow-hidden me-3 shadow-sm">
                    <img src={record.image} alt />
                </div>
                {/*end::Avatar*/}
                {/*begin::User details*/}
                <div className="d-flex flex-column">
                    <a href className="text-dark mb-2 fw-bolder text-one w-275px fs-5">{record.product}</a>
                    <div><span className="fs-7"><b>ASIN:</b> <span className="text-primary">{record.asin}</span></span> | 
                    <span className="fs-7 ms-1"><b>SKU:</b> <span className="text-primary">{record.sku}</span></span> </div>
                </div>
                </div>
            ),
          },
          {
            title: 'Marketplace',
            dataIndex: 'marketplace',
            key: 'marketplace',
          },
          {
            title: 'Qty Sold',
            dataIndex: 'qtySold',
            key: 'qtySold',
          },
          {
            title: 'Sales',
            dataIndex: 'sales',
            key: 'sales',
          },
          {
            title: 'Total Sales',
            dataIndex: 'totalSales',
            key: 'totalSales',
          },
          {
            title: 'COGS per Unit',
            dataIndex: 'cogsPerUnit',
            key: 'cogsPerUnit',
          },
          {
            title: 'COGS',
            dataIndex: 'cogs',
            key: 'cogs',
          },
          {
            title: 'COGS %',
            dataIndex: 'cogsPercent',
            key: 'cogsPercent',
          },
          {
            title: 'Handling Fees',
            dataIndex: 'handlingFees',
            key: 'handlingFees',
          },
          {
            title: 'Referral Fees',
            dataIndex: 'referralFees',
            key: 'referralFees',
          },
          {
            title: 'Shipping Fees',
            dataIndex: 'shippingFees',
            key: 'shippingFees',
          },
          {
            title: 'Total Gross Margin',
            dataIndex: 'totalGrossMargin',
            key: 'totalGrossMargin',
          },
          {
            title: 'Margin Before Ads Cost',
            dataIndex: 'marginBeforeAdsCost',
            key: 'marginBeforeAdsCost',
          },
          {
            title: 'Cost of Ads',
            dataIndex: 'costofAds',
            key: 'costofAds',
          },
          {
            title: 'Net Profit',
            dataIndex: 'netProfit',
            key: 'netProfit',
          },
          {
            title: 'Returns',
            dataIndex: 'returns',
            key: 'returns',
          },
          {
            title: 'Return Revenue',
            dataIndex: 'returnRevenue',
            key: 'returnRevenue',
          },
              	
        ];
        return <Table columns={productColumns} scroll={{ x: 'max-content' }} dataSource={record.products} pagination={false} />;
      };

      const handleChange = (selectedValue) => {
        // setSelectValue(selectedValue); // Update the value on selection
        setSelectValue(selectedValue);
      };

  return (
    <Wrapper className="container-fluid fadeInLeftAllPage"  id="kt_content_container">
        <Row className='' gutter={16}>
            <Col span={24}>
                <Card title="Overview" extra={
                    <Space>
                        <Input size="large" variant='filled' placeholder="Search by ASIN, SKU..." prefix={<Icon icon='tabler:search'></Icon>} />
                        <RangePickerESF size="large" variant='filled' className='w-250px'/>
                        <Select
                            value={selectValue}  // Controlled component with value state
                            onChange={handleChange}
                            className="min-w-150px"
                            placeholder="Select Category"
                            size="large"
                            variant='filled'
                            allowClear
                            options={[
                                { value: 'winterset', label: 'Winter Set' },
                                { value: 'clipontie', label: 'Clip-On Tie' },
                            ]}
                            />
                    </Space>
                    }>

                        <Chart options={options} series={series} type="area" height={350} />
                </Card>
            </Col>
            <Col span={24}>
            <Card
                title="Overview"
                className='mt-5 mb-10'
                extra={
                <>
                    <ConfigProvider theme={customTheme}>
                    <Segmented
                        itemSelectedBg="red"
                        options={[
                        "Show All",
                        "FBA",
                        "FBM",
                        ]}
                        value={value}
                        className='me-3'
                        onChange={setValue}
                    />
                    </ConfigProvider>
                    <Button size='large' className='fs-7 fw-bold' type='primary'>Export Data</Button>
                </>
                }>

                <Table
                columns={columns}
                expandable={{ expandedRowRender }}
                dataSource={data}
                pagination={false}
                scroll={{ x: 'max-content' }}

                summary={(pageData) => {
                    let totalSales = 0;
                    let totalUnits = 0;
                    
                    let totalCogs = 0;
                    let totalHandlingFees = 0;
                    let totalReferralFees = 0;
                    let totalShippingFees = 0;
                    let totalGrossMargin = 0;
                    let totalMarginBeforeAdsCost = 0;
                    let totalCostOfAds = 0;
                    let totalNetProfit = 0;
            
                    pageData.forEach(({ totalSales: sales, totalUnits: units, totalCogs: cogs, handlingFees, referralFees, shippingFees, totalGrossMargin, marginBeforeAdsCost, totalCostofAds, netProfit }) => {
                      totalSales += parseFloat(sales.replace('$', '').replace(',', ''));
                      totalUnits += parseInt(units.replace(' Units', ''), 10);
                      totalCogs += parseFloat(cogs.replace('$', '').replace(',', ''));
                      totalHandlingFees += parseFloat(handlingFees.replace('$', '').replace(',', ''));
                      totalReferralFees += parseFloat(referralFees.replace('$', '').replace(',', ''));
                      totalShippingFees += parseFloat(shippingFees.replace('$', '').replace(',', ''));
                      totalGrossMargin += parseFloat(totalGrossMargin.replace('$', '').replace(',', ''));
                      totalMarginBeforeAdsCost += parseFloat(marginBeforeAdsCost.replace('$', '').replace(',', ''));
                      totalCostOfAds += parseFloat(totalCostofAds.replace('$', '').replace(',', ''));
                      totalNetProfit += parseFloat(netProfit.replace('$', '').replace(',', ''));
                    });
            
                    return (
                      <Table.Summary.Row fixed={fixedTop ? 'top' : 'bottom'} className='bdr bg-gray-200'>
                        <Table.Summary.Cell className=' text-end fs-5 fw-bold' index={0} colSpan={3}>Total</Table.Summary.Cell>
                        <Table.Summary.Cell index={1}>
                            <div>
                                <h5 className='mb-0'>${totalSales.toFixed(2)}</h5>
                                <div className='fs-8'>{totalUnits} Units</div>
                            </div>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2}></Table.Summary.Cell>
                        <Table.Summary.Cell index={4}>${totalCogs.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={5}>${totalHandlingFees.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={6}>${totalReferralFees.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>${totalShippingFees.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={8}>${totalGrossMargin.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={9}>${totalMarginBeforeAdsCost.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={10}>${totalCostOfAds.toFixed(2)}</Table.Summary.Cell>
                        <Table.Summary.Cell index={11}>${totalNetProfit.toFixed(2)}</Table.Summary.Cell>
                      </Table.Summary.Row>
                    );
                  }}
                />

            </Card>    
            </Col>
        </Row>
    </Wrapper>
  )
}

export default ProductAnalytics