import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import {
  ConvertParams,
  DateFormat,
  DisplayNotification,
} from "../../../../../config";
import { Drawer, Input, Popover, Select, Skeleton, Tag, Tooltip } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import NoData from "../../../../../component/no-data";
import dayjs from "dayjs";
import RangePickerESF from "../../../../../component/range-picker";
import moment from "moment";
import { GlobalContext } from "../../../../../commonContext";
import KeywordSearchtermFilter from "./keyword-searchterm-filter";
import CardHeader from "../../../../../component/card-header";
const KeywordSearchTerm = (props) => {
  const {
    loading,
    setLoading,
    selected_marketplace,
    set_selected_marketplace,
    marketplace_list,
    set_selected_currency_sign,
    currency_list,
  } = props;
  const contextValue = useContext(GlobalContext);
  const [adGroupList, setAdGroupList] = useState([]);
  const [campaignlist, setCampaignList] = useState([]);
  const [autoCampaignList, setAutoCampaignList] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [savingCampaignId, setSavingCampaignId] = useState("");
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [campaign_filters, set_campaign_filters] = useState({
    campaign_type: "",
    state: "",
    targeting_type: "MANUAL",
  });
  const [search_term_filters, set_search_term_filters] = useState({
    marketplace_id: "",
    campaign_type: "SPONSORED_PRODUCTS", // SPONSORED_PRODUCTS, SPONSORED_BRANDS, SPONSORED_DISPLAY
    targeting_type: "MANUAL", // AUTO, MANUAL
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
    search: "", // search term, Campaign name, ad group name
    campaign_state: null, // (ENABLED, disable, PAUSED)
    match_type: null, // BROAD, EXACT, PHRASE
    keyword_state: null, // (ENABLED, disable, PAUSED)
  });
  const [isOpenMetricsPopover, setIsOpenMetricsPopover] = useState(false);
  const [isOpenFilterDrawer, setIsOpenFilterDrawer] = useState(false);
  const [metrics_filter_obj, set_metrics_filter_obj] = useState([
    {
      attribute: null,
      operator: null,
      value: "",
    },
  ]);

  useEffect(() => {
    if (selected_marketplace) {
      set_search_term_filters({
        ...search_term_filters,
        marketplace_id: selected_marketplace,
      });
      const data = {
        ...search_term_filters,
        start_date: moment(search_term_filters?.start_date?.$d).format(
          "YYYY-MM-DD"
        ),
        end_date: moment(search_term_filters?.end_date?.$d).format(
          "YYYY-MM-DD"
        ),
        marketplace_id: selected_marketplace,
      };
      GetSearchtermlist(data);
      GetAllCampaigns(campaign_filters);
    }
  }, [selected_marketplace]);

  const GetSearchtermlist = async (data) => {
    setLoading(true);
    const response = await MakeApiCall(
      `keyword-targeting/get-search-term`,
      `post`,
      data,
      true
    );
    if (response?.status) {
      setLoading(false);
      const addKeys = response?.data?.map((d) => {
        return {
          ...d,
          selected_search_term: [],
          selected_campaigns: [],
          selected_ad_groups: [],
          bid: 0,
          match_type: null,
        };
      });
      setCampaignList(addKeys || []);
      setSavingCampaignId("");
      // if (data?.targeting_type === "MANUAL") {
      //   GetAdGroupList(response?.data);
      // }
    } else {
      setLoading(false);
      setCampaignList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAdGroupList = async (campaignData) => {
    const data = {
      marketplace_id: selected_marketplace,
      listing_type: "manual_campaign", // all_campaign, manual_campaign
      campaign_list: campaignData?.map((d) => {
        return {
          campaign_type: d?.campaign_type,
          campaign_id: d?.campaign_id,
        };
      }),
    };
    const response = await MakeApiCall(
      `advertising/get-ad-group-list`,
      `post`,
      data,
      true
    );
    if (response?.status) {
      setAdGroupList(response?.data || []);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const SaveSearchTermAsKeyword = async (finalJson) => {
    const response = await MakeApiCall(
      `keyword-targeting/create-keywords`,
      `post`,
      finalJson,
      true
    );
    if (response?.status) {
      setIsSaving(false);
      setSavingCampaignId("");
      DisplayNotification("success", "Success", response?.message);
    } else {
      setIsSaving(false);
      setSavingCampaignId("");
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllCampaigns = async (filters) => {
    const response = await MakeApiCall(
      `advertising/get-all-campaigns${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setAutoCampaignList(response?.data);
      GetAdGroupList(response?.data);
      // DisplayNotification("success", "Success", response?.message);
    } else {
      setAutoCampaignList([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const [metrics_filter, set_metrics_filter] = useState({
    attributes_dd_data: {
      //   acos: "ACOS",
      //   roas: "ROAS",
      //   spends: "Spends",
      //   sales: "Total Sales",
      //   cpc: "CPC",
      //   cvr: "Conversion Rate",
      //   unit_ordered: "Orders",
      //   clicks: "Clicks",
      //   impressions: "Impressions",
      //   ctr: "CTR",
      //   ad_sales_percentage: "Ad Sales Percentage",
      //   total_sales: "Total Sales",
      //   total_orders: "Total Orders",
      //   total_acos: "Total ACOS",

      clicks: "Clicks",
      impressions: "Impressions",
      cost: "Cost",
      sales: "Sales",
      RoAS: "RoAS",
      RoAS_per: "RoAS %",
      ACos: "ACOS",
      SPC: "SPC",
      CPC: "CPC",
      CPM: "CPM",
      CTR: "CTR",
    },

    operators_dd_data: {
      ">=": ">=",
      "<=": "<=",
      ">": ">",
      "<": "<",
      "=": "=",
    },
  });

  const FilterApply = (search_term_filters_) => {
    const filteredMetrics = metrics_filter_obj.filter(
      (item) =>
        item.attribute !== null && item.operator !== null && item.value !== ""
    );
    set_metrics_filter_obj(filteredMetrics);
    setLoading(true);
    GetSearchtermlist({
      ...search_term_filters_,
      start_date: moment(search_term_filters_?.start_date?.$d).format(
        "YYYY-MM-DD"
      ),
      end_date: moment(search_term_filters_?.end_date?.$d).format("YYYY-MM-DD"),
      metric_filter: filteredMetrics,
    });
    setIsOpenFilterDrawer(false);
  };

  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row">
        <div className="col-12 px-3">
          <CardHeader>
            <div className="d-flex align-items-center justify-content-between py-4 ">
              <h3
                class=" card-title align-items-start flex-column"
                style={{ minWidth: "250px" }}
              >
                <span class="card-label fw-bold fs-3 mb-1">
                  Add Search Term as Keyword
                </span>
              </h3>
              <div className="card-toolbar me-3">
                <div className="d-flex justify-content-end flex-wrap gap-4 align-items-center">
                  <div className="position-relative me-2">
                    <Tooltip title="Search by Campaign, Search term, Ad Group">
                      {" "}
                      <Input
                        style={{ width: "250px" }}
                        prefix={
                          <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                        }
                        size="large"
                        placeholder="Search campaign, searchterm, ad group"
                        value={[search_term_filters?.search]}
                        onChange={(e) => {
                          const data = {
                            ...search_term_filters,
                            search: e.target.value,
                          };
                          set_search_term_filters(data);
                        }}
                        onPressEnter={() => {
                          FilterApply(search_term_filters);
                        }}
                        // onPressEnter={(e) => {
                        //   const data = {
                        //     ...search_term_filters,
                        //     search: e.target.value,
                        //     start_date: moment(
                        //       search_term_filters?.start_date?.$d
                        //     ).format("YYYY-MM-DD"),
                        //     end_date: moment(
                        //       search_term_filters?.end_date?.$d
                        //     ).format("YYYY-MM-DD"),
                        //   };
                        //   set_search_term_filters({
                        //     ...search_term_filters,
                        //     search: e.target.value,
                        //   });
                        //   GetSearchtermlist(data);
                        // }}
                      />
                    </Tooltip>
                  </div>
                  <div className="position-relative me-2">
                    <RangePickerESF
                      className="ant_common_input"
                      id="aalacart_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "250px" }}
                      format={DateFormat}
                      value={[
                        search_term_filters?.start_date,
                        search_term_filters?.end_date,
                      ]}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={(e) => {
                        const filters_ = search_term_filters;
                        filters_.start_date = e?.[0];
                        filters_.end_date = e?.[1];
                        set_search_term_filters({ ...filters_ });
                        FilterApply(filters_);
                        // GetSearchtermlist(filters_);
                      }}
                    />
                  </div>{" "}
                  <div className="position-relative me-2">
                    <Select
                      options={[
                        {
                          label: "Sponsored Products",
                          value: "SPONSORED_PRODUCTS",
                        },
                        {
                          label: "Sponsored Brands",
                          value: "SPONSORED_BRANDS",
                        },
                        {
                          label: "Sponsored Display",
                          value: "SPONSORED_DISPLAY",
                        },
                      ]}
                      value={search_term_filters?.campaign_type}
                      onChange={(e) => {
                        const filters_ = {
                          ...search_term_filters,
                          campaign_type: e,
                        };
                        set_search_term_filters(filters_);
                        FilterApply(filters_);
                      }}
                      style={{ width: "250px" }}
                      allowClear
                      placeholder="Campaign type"
                      size="large"
                    />
                  </div>
                  <div className="position-relative me-2">
                    <Select
                      size="large"
                      allowClear
                      style={{ width: "250px" }}
                      options={[
                        { label: "Enabled", value: "ENABLED" },
                        { label: "Paused", value: "PAUSED" },
                      ]}
                      placeholder="Campaign State"
                      value={search_term_filters?.campaign_state}
                      onChange={(e) => {
                        set_search_term_filters({
                          ...search_term_filters,
                          campaign_state: e,
                        });
                        FilterApply({
                          ...search_term_filters,
                          campaign_state: e,
                        });
                      }}
                    />
                  </div>
                  <div className="position-relative me-2">
                    <Select
                      size="large"
                      allowClear
                      style={{ width: "250px" }}
                      options={[
                        { label: "Broad", value: "BROAD" },
                        { label: "Exact", value: "EXACT" },
                        { label: "Phrase", value: "PHRASE" },
                      ]}
                      placeholder="Match Type"
                      value={search_term_filters?.match_type}
                      onChange={(e) => {
                        set_search_term_filters({
                          ...search_term_filters,
                          match_type: e,
                        });
                        FilterApply({
                          ...search_term_filters,
                          match_type: e,
                        });
                      }}
                    />
                  </div>
                  <div className="position-relative me-2">
                    <Select
                      options={[
                        { label: "Enabled", value: "ENABLED" },
                        { label: "Paused", value: "PAUSED" },
                        { label: "Disabled", value: "DISABLED" },
                      ]}
                      allowClear
                      value={search_term_filters?.keyword_state}
                      onChange={(e) => {
                        set_search_term_filters({
                          ...search_term_filters,
                          keyword_state: e,
                        });
                        FilterApply({
                          ...search_term_filters,
                          keyword_state: e,
                        });
                      }}
                      style={{ width: "250px" }}
                      placeholder="Keyword state"
                      size="large"
                    />
                  </div>
                  <div className="position-relative me-2">
                    <Select
                      size="large"
                      allowClear
                      style={{ width: "250px" }}
                      options={[
                        { label: "Manual", value: "MANUAL" },
                        { label: "Auto", value: "AUTO" },
                      ]}
                      value={search_term_filters?.targeting_type}
                      onChange={(e) => {
                        set_search_term_filters({
                          ...search_term_filters,
                          targeting_type: e,
                        });
                        FilterApply({
                          ...search_term_filters,
                          targeting_type: e,
                        });
                      }}
                    />
                  </div>
                  <div className="position-relative me-2">
                    <Popover
                      placement="bottom"
                      trigger="click"
                      open={isOpenMetricsPopover}
                      content={
                        <div className="">
                          {metrics_filter_obj?.map((d, index) => {
                            return (
                              <div className="mt-3 d-flex">
                                <Select
                                  placeholder="Select Metric"
                                  allowClear
                                  options={Object.entries(
                                    metrics_filter?.attributes_dd_data
                                  )?.map((d) => {
                                    return {
                                      label: d[1],
                                      value: d[0],
                                    };
                                  })}
                                  value={d?.attribute}
                                  onChange={(e) => {
                                    set_metrics_filter_obj((prev) => {
                                      return prev?.map((d, mindex) => {
                                        if (mindex === index) {
                                          return {
                                            ...d,
                                            attribute: e,
                                          };
                                        }
                                        return d;
                                      });
                                    });
                                  }}
                                  style={{ width: "200px" }}
                                />
                                <Select
                                  className="ms-1"
                                  allowClear
                                  options={Object.entries(
                                    metrics_filter?.operators_dd_data
                                  )?.map((d) => {
                                    return {
                                      label: d[1],
                                      value: d[0],
                                    };
                                  })}
                                  value={d?.operator}
                                  onChange={(e) => {
                                    set_metrics_filter_obj((prev) => {
                                      return prev?.map((d, mindex) => {
                                        if (mindex === index) {
                                          return {
                                            ...d,
                                            operator: e,
                                          };
                                        }
                                        return d;
                                      });
                                    });
                                  }}
                                  style={{ width: "100px" }}
                                  placeholder="Select Condition"
                                />
                                <Input
                                  style={{ width: "120px" }}
                                  className="ms-1"
                                  value={d?.value}
                                  onChange={(e) => {
                                    set_metrics_filter_obj((prev) => {
                                      return prev?.map((d, mindex) => {
                                        if (mindex === index) {
                                          return {
                                            ...d,
                                            value: e.target.value,
                                          };
                                        }
                                        return d;
                                      });
                                    });
                                  }}
                                  placeholder="Enter Value"
                                />
                                {metrics_filter_obj?.length > 1 && (
                                  <span
                                    className="btn btn-icon btn-light-primary btn-outline w-30px h-30px position-relative border-0 cursor-pointer ms-1"
                                    onClick={() => {
                                      const deletedData =
                                        metrics_filter_obj?.filter(
                                          (d, i) => i !== index
                                        );
                                      set_metrics_filter_obj(deletedData);
                                    }}
                                  >
                                    <i className="ki-outline ki-cross fs-2" />
                                  </span>
                                )}
                              </div>
                            );
                          })}
                          <div className="text-center">
                            <button
                              className="btn btn-light-primary btn-sm mt-5"
                              onClick={() => {
                                set_metrics_filter_obj([
                                  ...metrics_filter_obj,
                                  {
                                    attribute: null,
                                    operator: null,
                                    value: "",
                                  },
                                ]);
                              }}
                            >
                              <i className="ki-outline ki-plus fs-2" />
                              Add another
                            </button>
                          </div>

                          <div className="separator separator-dashed my-2"></div>
                          <div className="d-flex justify-content-between">
                            <button
                              className="btn btn-light-danger btn-sm"
                              onClick={() => {
                                set_metrics_filter_obj([
                                  {
                                    attribute: null,
                                    operator: null,
                                    value: "",
                                  },
                                ]);
                                setIsOpenMetricsPopover(false);
                              }}
                            >
                              {/* <i className="ki-outline ki-cross fs-2" /> */}
                              Clear all
                            </button>
                            <button
                              disabled={
                                metrics_filter_obj.filter(
                                  (item) =>
                                    !item.attribute ||
                                    !item.operator ||
                                    !item.value
                                )?.length > 0
                              }
                              className="btn btn-primary btn-sm"
                              onClick={() => {
                                const apiObj = {
                                  ...search_term_filters,
                                  marketplace_id: selected_marketplace,
                                  start_date: moment(
                                    search_term_filters?.start_date?.$d
                                  ).format("YYYY-MM-DD"),
                                  end_date: moment(
                                    search_term_filters?.end_date?.$d
                                  ).format("YYYY-MM-DD"),
                                  metric_filter:
                                    JSON.stringify(metrics_filter_obj) || [],
                                };
                                setLoading(true);
                                GetSearchtermlist(apiObj);
                                setIsOpenMetricsPopover(false);
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      }
                    >
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          setIsOpenMetricsPopover(true);
                        }}
                      >
                        <i className="ki-outline ki-plus fs-2" />
                        Metrics{" "}
                      </button>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
        </div>
      </div>

      <div className="row">
        <div className="col-xxl-12">
          <div
            className="card card-shadow card-xxl-stretch mb-5 mb-xl-8"
            // style={{ background: "transparent" }}
          >
            <div
              className="card-body py-2"
              style={{
                background: campaignlist?.length === 0 ? "#fff" : "transparent",
              }}
            >
              <div className="row">
                <div className="col-12 position-relative">
                  {loading ? (
                    <>
                      {[...Array(6)]?.map((d) => {
                        return (
                          <div className="">
                            <div
                              className="row my-3 position-relative"
                              style={{
                                border: "1px solid #7d7d7d59",
                                borderRadius: "9px",
                                padding: "5px 15px",
                                background: "#fff",
                              }}
                            >
                              <div className="col-4 col-xxl-3 my-1">
                                <Skeleton.Button
                                  size="large"
                                  active
                                  block
                                  className="my-2"
                                />
                              </div>
                              <div className="col-4 col-xxl-3 my-1">
                                <Skeleton.Button
                                  size="large"
                                  active
                                  block
                                  className="my-2"
                                />
                              </div>{" "}
                              <div className="col-3 col-xxl-3 my-1">
                                <Skeleton.Button
                                  size="large"
                                  active
                                  block
                                  className="my-2"
                                />
                              </div>
                              <div className="col-1 col-xxl-3 my-1">
                                <Skeleton.Button
                                  size="large"
                                  active
                                  block
                                  className="my-2"
                                />
                              </div>
                            </div>
                            <div className="separator separator-dashed my-6" />
                          </div>
                        );
                      })}
                    </>
                  ) : campaignlist?.length > 0 ? (
                    campaignlist?.map((d, index) => {
                      // Filter ad groups that belong to selected campaigns
                      // and flatten the list of ad groups
                      const filtersAdGroup = adGroupList
                        .filter(({ campaign_id }) => {
                          // Check if the campaign_id is in the selected campaigns list
                          return d?.selected_campaigns?.some(
                            ({ selected_campaign_id }) =>
                              selected_campaign_id === campaign_id
                          );
                        })
                        .flatMap(({ ad_group_list }) => ad_group_list);

                      const selectedCampaingList =
                        search_term_filters?.targeting_type === "MANUAL"
                          ? [campaignlist?.[index]]
                          : autoCampaignList;
                      console.log(
                        selectedCampaingList,
                        "-selectedCampaingList"
                      );
                      return (
                        <div className="">
                          <div
                            className="row my-3 position-relative"
                            style={{
                              border: "1px solid #7d7d7d59",
                              borderRadius: "9px",
                              padding: "20px 15px",
                              background: "#fff",
                            }}
                          >
                            <div
                              style={{
                                background: "#6b6b6b2e",
                                top: "0px",
                                left: "0px",
                                width: "40px",
                                clipPath: "polygon(100% 0, 0 0, 0 100%)",
                                height: "40px",
                                borderRadius: "8px 0px 0px 0px",
                              }}
                              className="position-absolute"
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  left: "7px",
                                  top: "6px",
                                }}
                                className="fw-bold"
                              >
                                {index + 1}
                              </div>
                            </div>
                            {/* ----------------------------------------------------------------- Source Campaign */}
                            <div className="col-12 my-2">
                              <label className="fw-bold  fs-6 ms-1">
                                {d?.campaign_name}
                              </label>
                            </div>
                            {/* ----------------------------------------------------------------- Search Term List */}
                            <div className="col-12 col-xxl-6 my-1">
                              <Select
                                options={d?.search_term_list?.map((d) => {
                                  return {
                                    ...d,
                                    label: (
                                      <div>
                                        <label className="fw-bold">
                                          {d?.search_term}
                                        </label>
                                        <div className="text-muted row mb-4 mt-1">
                                          <div
                                            className="col-3"
                                            style={{
                                              borderRight: "1px solid #f7ecec",
                                            }}
                                          >
                                            <label>
                                              <span>Impression: </span>
                                              <div>
                                                <Tag color="blue">
                                                  {d?.impressions}
                                                </Tag>
                                              </div>
                                            </label>
                                          </div>
                                          <div
                                            className="col-3"
                                            style={{
                                              borderRight: "1px solid #f7ecec",
                                            }}
                                          >
                                            <label>
                                              <span>Clicks: </span>
                                              <div>
                                                <Tag color="orange">
                                                  {d?.clicks}
                                                </Tag>
                                              </div>
                                            </label>
                                          </div>
                                          <div
                                            className="col-3"
                                            style={{
                                              borderRight: "1px solid #f7ecec",
                                            }}
                                          >
                                            <label>
                                              <span>Sales: </span>
                                              <div>
                                                <Tag color="green">
                                                  {d?.sales7d}
                                                </Tag>
                                              </div>
                                            </label>
                                          </div>
                                          <div
                                            className="col-3"
                                            style={{
                                              borderRight: "1px solid #f7ecec",
                                            }}
                                          >
                                            <label>
                                              <span>Spend: </span>
                                              <div>
                                                <Tag color="purple">
                                                  {d?.cost}
                                                </Tag>
                                              </div>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    ),
                                    value: d?.id,
                                  };
                                })}
                                filterOption={(input, option) =>
                                  (option?.search_term ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={(e, selected_data) => {
                                  const newSearchTermData = campaignlist.map(
                                    (searchTerm, i) => {
                                      if (i === index) {
                                        return {
                                          ...searchTerm,
                                          selected_search_term:
                                            selected_data?.map((sd) => {
                                              return {
                                                search_term_id: sd?.value,
                                                search_term_text:
                                                  sd?.search_term,
                                              };
                                            }),
                                        };
                                      }
                                      return searchTerm;
                                    }
                                  );
                                  setCampaignList(newSearchTermData);
                                }}
                                value={
                                  d?.selected_search_term?.map(
                                    (d) => d?.search_term_id
                                  ) || []
                                }
                                size="large"
                                mode="multiple"
                                maxTagCount={"responsive"}
                                style={{ width: "100%" }}
                                placeholder="Select Search Term"
                              />
                            </div>
                            {/* ----------------------------------------------------------------- campaign List */}
                            <div className="col-6 col-xxl-6 my-1">
                              <Select
                                options={
                                  selectedCampaingList?.map((list) => {
                                    return {
                                      label: list?.campaign_name,
                                      value: list?.campaign_id,
                                      ...list,
                                    };
                                  }) || []
                                }
                                filterOption={(input, option) =>
                                  (option?.label ?? "")
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                onChange={(e, selected_data) => {
                                  const newSelectedCampaignData =
                                    campaignlist.map((clist, i) => {
                                      if (i === index) {
                                        return {
                                          ...clist,
                                          selected_campaigns:
                                            selected_data?.map((sd) => {
                                              return {
                                                selected_campaign_id:
                                                  sd?.campaign_id,
                                                selected_campaign_name:
                                                  sd?.campaign_name,
                                              };
                                            }),
                                        };
                                      }
                                      return clist;
                                    });
                                  setCampaignList(newSelectedCampaignData);
                                }}
                                value={
                                  d?.selected_campaigns?.map(
                                    (d) => d?.selected_campaign_id
                                  ) || []
                                }
                                mode="multiple"
                                maxTagCount="responsive"
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="Target Campaign"
                              />
                            </div>
                            {/* ----------------------------------------------------------------- Ad group List */}
                            <div className="col-6 col-xxl-4  my-1">
                              <Select
                                options={
                                  filtersAdGroup?.map((list) => {
                                    return {
                                      label: list?.name,
                                      value: list?.ad_group_id,
                                      ...list,
                                    };
                                  }) || []
                                }
                                onChange={(e, selected_data) => {
                                  const newSelectedAdGroupData =
                                    campaignlist.map((clist, i) => {
                                      if (i === index) {
                                        return {
                                          ...clist,
                                          selected_ad_groups:
                                            selected_data?.map((sd) => {
                                              return {
                                                selected_ad_gorup_id: sd?.value,
                                                selected_ad_gorup_name:
                                                  sd?.label,
                                                ...sd,
                                              };
                                            }),
                                        };
                                      }
                                      return clist;
                                    });
                                  setCampaignList(newSelectedAdGroupData);
                                }}
                                value={
                                  d?.selected_ad_groups?.map(
                                    (d) => d?.selected_ad_gorup_id
                                  ) || []
                                }
                                mode="multiple"
                                maxTagCount="responsive"
                                size="large"
                                style={{ width: "100%" }}
                                placeholder="Select Ad Group"
                              />
                            </div>
                            {/* ----------------------------------------------------------------- Match Type */}
                            <div className="col-6 col-xxl-3  my-1 ">
                              <Select
                                options={[
                                  { label: "Exact", value: "EXACT" },
                                  { label: "Phrase", value: "PHRASE" },
                                  { label: "Broad", value: "BROAD" },
                                ]}
                                onChange={(e) => {
                                  const newMathcType = campaignlist.map(
                                    (clist, i) => {
                                      if (i === index) {
                                        return {
                                          ...clist,
                                          match_type: e,
                                        };
                                      }
                                      return clist;
                                    }
                                  );
                                  setCampaignList(newMathcType);
                                }}
                                value={d?.match_type || null}
                                size="large"
                                placeholder="Select type"
                                style={{ width: "100%" }}
                              />
                            </div>
                            {/* ----------------------------------------------------------------- Keyword Bid */}
                            <div className="col-4 col-xxl-3  my-1 d-flex align-items-center">
                              <Input
                                placeholder="Enter Keyword Bid"
                                prefix="$"
                                size="large"
                                onChange={(e) => {
                                  const newBid = campaignlist.map(
                                    (clist, i) => {
                                      if (i === index) {
                                        return {
                                          ...clist,
                                          bid: e.target.value,
                                        };
                                      }
                                      return clist;
                                    }
                                  );
                                  setCampaignList(newBid);
                                }}
                                value={d?.bid || ""}
                              />
                            </div>
                            {/* ----------------------------------------------------------------- Save Button */}
                            <div className="col-1 col-xxl-1  my-1 px-0 d-flex align-items-center justify-content-around">
                              <button
                                class="btn btn-success btn-sm fw-bold"
                                onClick={() => {
                                  setSavingCampaignId(d?.campaign_id);
                                  const data1 = d?.selected_search_term
                                    ?.map((st) => {
                                      return d?.selected_ad_groups?.map(
                                        (sd) => {
                                          return {
                                            campaign_id: sd?.campaign_id,
                                            match_type: d?.match_type,
                                            state: "ENABLED",
                                            bid: d?.bid,
                                            ad_group_id: sd?.ad_group_id,
                                            keyword_text: st?.search_term_text,
                                            marketplace_id:
                                              selected_marketplace,
                                            advertising_type: d?.campaign_type,
                                          };
                                        }
                                      );
                                    })
                                    ?.flat();
                                  setIsSaving(true);
                                  const finalJson = {
                                    marketplace_id: selected_marketplace,
                                    keywords: data1,
                                  };
                                  console.log(finalJson, "-finalJson");
                                  SaveSearchTermAsKeyword(finalJson);
                                }}
                                disabled={
                                  isSaving ||
                                  !d?.bid ||
                                  d?.selected_search_term?.length === 0 ||
                                  d?.selected_campaigns?.length === 0 ||
                                  d?.selected_ad_groups?.length === 0 ||
                                  !d?.match_type
                                }
                              >
                                {isSaving &&
                                d?.campaign_id === savingCampaignId ? (
                                  <>
                                    <span className="fw-bolder">Save</span>
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                  </>
                                ) : (
                                  <span className="fw-bolder">Save</span>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-6" />
                        </div>
                      );
                    })
                  ) : (
                    <NoData type="new" />
                  )}
                </div>
              </div>
            </div>
          </div>
          {console.log(campaignlist, "campaignlist")}
        </div>
      </div>
      {/* <Drawer
        title="Filters"
        placement={'right'}
        closable={true}
        onClose={() =>{
          setIsOpenDrawer(false)
        }}
        open={isOpenDrawer}
      >
        <div className="row">
        
          <div className="col-6"></div>
        </div>
      </Drawer> */}

      <Drawer
        title="Filters"
        onClose={() => {
          setIsOpenFilterDrawer(false);
        }}
        maskClosable={false}
        open={isOpenFilterDrawer}
        width={"450px"}
        footer={
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                const filteredMetrics = metrics_filter_obj.filter(
                  (item) =>
                    item.attribute !== null &&
                    item.operator !== null &&
                    item.value !== ""
                );
                set_metrics_filter_obj(filteredMetrics);
                setLoading(true);
                GetSearchtermlist({
                  ...search_term_filters,
                  start_date: moment(
                    search_term_filters?.start_date?.$d
                  ).format("YYYY-MM-DD"),
                  end_date: moment(search_term_filters?.end_date?.$d).format(
                    "YYYY-MM-DD"
                  ),
                  metric_filter: filteredMetrics,
                });
                setIsOpenFilterDrawer(false);
              }}
            >
              Save
            </button>
          </div>
        }
      >
        <KeywordSearchtermFilter
          set_selected_currency_sign={set_selected_currency_sign}
          currency_list={currency_list}
          search_term_filters={search_term_filters}
          set_search_term_filters={set_search_term_filters}
          marketplace_list={marketplace_list}
          selected_marketplace={selected_marketplace}
          set_selected_marketplace={set_selected_marketplace}
          setLoading={setLoading}
          GetApiCall={() => {
            set_metrics_filter_obj([
              {
                attribute: null,
                operator: null,
                value: "",
              },
            ]);
            const apiObj = {
              ...search_term_filters,
              campaign_type: "SPONSORED_PRODUCTS", // SPONSORED_PRODUCTS, SPONSORED_BRANDS, SPONSORED_DISPLAY
              targeting_type: "MANUAL", // AUTO, MANUAL
              search: "", // search term, Campaign name, ad group name
              campaign_state: null, // (ENABLED, disable, PAUSED)
              match_type: null, // BROAD, EXACT, PHRASE
              keyword_state: null, // (ENABLED, disable, PAUSED)
              marketplace_id: selected_marketplace,
              metric_filter: [],
            };
            set_search_term_filters(apiObj);
            setLoading(true);
            GetSearchtermlist({
              ...apiObj,
              start_date: moment(search_term_filters?.start_date?.$d).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(search_term_filters?.end_date?.$d).format(
                "YYYY-MM-DD"
              ),
            });
          }}
          setIsOpenFilterDrawer={setIsOpenFilterDrawer}
          metrics_filter_obj={metrics_filter_obj}
          set_metrics_filter_obj={set_metrics_filter_obj}
        />
      </Drawer>
    </div>
  );
};

export default KeywordSearchTerm;
