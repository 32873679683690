import styled from "styled-components";

export const Wrapper = styled.div`

    .ant-card .ant-card-head {
    min-height: 70px;
}
input::placeholder, .ant-select .ant-select-selection-placeholder {
    color: #919191 !important;
}
.ant-segmented .ant-segmented-item-label {
    font-weight: 500;
    font-family: Inter;
    padding: 4px 11px;
}
.ant-segmented {
    background: rgb(243 243 243);
    height: 40px;
}
    button.ant-btn span {
    font-size: 15px;
    line-height: 0;
    display: flex;
}
    .ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th, .ant-table-wrapper .ant-table-tbody>tr>th, .ant-table-wrapper .ant-table-tbody>tr>td, .ant-table-wrapper tfoot>tr>th, .ant-table-wrapper tfoot>tr>td {
    position: relative;
    padding: 20px 16px;
    overflow-wrap: break-word;
}
    
`;
