import { Checkbox, DatePicker, Input, Select } from "antd";
import React from "react";
import { DateFormat } from "../../../../../../config";
import moment from "moment";
import dayjs from "dayjs";
import { CreateCampaignWrapper } from "./style";

const SBCreateCampaign = (props) => {
  const { all_data, set_all_data } = props;
  return (
    <CreateCampaignWrapper>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div className="row gy-5 g-xl-5">
          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Create Campaign</h3>
                </div>

                <div className="card-toolbar"></div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="row mb-0">
                  <div
                    className="col-12 col-lg-8"
                    style={{ borderRight: "1px solid #3699ff" }}
                  >
                    <div className="row">
                      <label className="col-auto col-form-label required fw-semibold fs-6">
                        Campaign Name
                      </label>

                      <div className="col-lg-8">
                        <Input
                          value={all_data?.name}
                          onChange={(e) => {
                            set_all_data({ ...all_data, name: e.target.value });
                          }}
                          size="large"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <label className="col-auto col-form-label required fw-semibold fs-6">
                        Status
                      </label>

                      <div className="col-lg-6">
                        <Select
                          options={[
                            { label: "ENABLED", value: "ENABLED" },
                            { label: "PAUSED", value: "PAUSED" },
                          ]}
                          value={all_data?.state}
                          onChange={(e) => {
                            set_all_data({ ...all_data, name: e });
                          }}
                          size="large"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-12">
            <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
              <div className="card-header border-0 cursor-pointer">
                <div className="card-title m-0">
                  <h3 className="fw-bold m-0">Scheduling &amp; Budget</h3>
                </div>
              </div>

              <div className="card-body border-top border-top-dashed pb-2 pt-6">
                <div className="row mb-0 ">
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="fw-bolder fs-6 mb-1">
                        Start Date
                      </label>
                      <DatePicker
                        format={DateFormat}
                        onChange={(date, dateString) => {
                          set_all_data({
                            ...all_data,
                            start_date: moment(date?.$d)?.format("YYYY-MM-DD"),
                          });
                        }}
                        style={{ width: "100%" }}
                        size="large"
                        className="ant_common_input"
                        value={dayjs(all_data?.start_date)}
                        // disabledDate={disableStartDate}
                      />
                    </div>
                  </div>
                  {!all_data?.run_indefinitely && (
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor className="fw-bolder fs-6 mb-1">
                          End Date
                        </label>
                        <DatePicker
                          format={DateFormat}
                          onChange={(date, dateString) => {
                            set_all_data({
                              ...all_data,
                              end_date: moment(date?.$d)?.format("YYYY-MM-DD"),
                            });
                          }}
                          style={{ width: "100%" }}
                          size="large"
                          className="ant_common_input"
                          value={dayjs(all_data?.end_date)}
                          // disabledDate={disableStartDate}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor className="fw-bolder fs-6 mb-1">
                        Total Budget
                      </label>
                      <div className="d-flex align-items-end">
                        <Input
                          value={all_data?.daily_budget}
                          onChange={(e) => {
                            const newVal = e.target.value.split(".").join("");
                            const isNumeric = /^[0-9]+$/.test(newVal);
                            if (isNumeric || e.target.value === "") {
                              set_all_data({
                                ...all_data,
                                daily_budget: e.target.value,
                              });
                            }
                          }}
                          size="large"
                          style={{ width: "50%" }}
                          prefix="$"
                        />
                        <Select
                          options={[
                            { label: "Daily", value: "daily" },
                            {
                              label: "Lifetime",
                              value: "lifetime",
                              disabled: !all_data?.end_date,
                            },
                          ]}
                          style={{ width: "40%" }}
                          size="large"
                          className="ms-5"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor className="fw-bolder fs-6 mb-1"></label>
                       <Input
                        value={all_data?.daily_limit}
                        onChange={(e) => {
                          const newVal = e.target.value.split(".").join("");
                          const isNumeric = /^[0-9]+$/.test(newVal);
                          if (isNumeric || e.target.value === "") {
                            set_all_data({
                              ...all_data,
                              daily_limit: e.target.value,
                            });
                          }
                        }}
                        size="large"
                      /> 
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="mt-4">
                      <div>
                        <Checkbox
                          onChange={(e) => {
                            if (e.target.checked) {
                              set_all_data({
                                ...all_data,
                                run_indefinitely: e.target.checked,
                                end_date: "",
                              });
                            } else {
                              set_all_data({
                                ...all_data,
                                run_indefinitely: e.target.checked,
                                end_date: dayjs()
                                  .add(30, "d")
                                  ?.format("YYYY-MM-DD"),
                              });
                            }
                          }}
                          checked={all_data?.run_indefinitely}
                          className="me-4"
                        />
                        <label
                          className="form-check-label fs-6 fw-bold text-dark"
                          htmlFor="flexCheckDefault"
                        >
                          Run indefinitely from start date
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CreateCampaignWrapper>
  );
};

export default SBCreateCampaign;
