import { Input, Select, Tag } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const LostInboundModal = (props) => {
  const { show, onHide, data } = props;
  const [sending, setSending] = useState(false);
  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Lost Inbound - <Tag color="geekblue">FNSKU : {data?.fnsku}</Tag>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "300px" }}>
            <div className="row">
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Case Id</label>
                <Input placeholder="Case Id" />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">RMS Id</label>
                <Input placeholder="Case Id" />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">
                  Quantity Reimbursed Total
                </label>
                <Input placeholder="Case Id" />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Credited Amount</label>
                <Input placeholder="Case Id" />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Currency</label>
                <Input placeholder="Case Id" />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Comment</label>
                <Input placeholder="Case Id" />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Manager</label>
                <Select
                  className="w-200px"
                  placeholder="Select Manager"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button type="button" class="btn btn-primary fs-7" onClick={() => {}}>
            Saving
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LostInboundModal;
