import { Checkbox, Form, Input, Radio, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { DisplayNotification, PhotoUrl } from "../../../../../config";
export default function (props) {
  const { show, onHide, data, onSuccess } = props;
  const [form] = Form.useForm();
  const [updating, setUpdating] = useState(false);
  const [managerId, setManagerId] = useState("");

  useEffect(() => {
    form.resetFields(); // Reset form fields when the modal is opened
    if (data?.id) {
      const myData = {
        name: data?.name,
        email: data?.email,
        contact_no: data?.contact_no,
      };
      setManagerId(data?.id);
      form.setFieldsValue(myData);
    }
  }, [show, data]);

  const SaveManagerData = async (body) => {
    setUpdating(true);
    const response = await MakeApiCall(
      data?.id ? `manager/${data?.id}` : "manager",
      data?.id ? "put" : "post",
      body,
      true
    );
    if (response?.status) {
      setUpdating(false);
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      onSuccess();
    } else {
      message.destroy();
      setUpdating(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        setUpdating(true);
        SaveManagerData(values);
        // setTimeout(() => {
        //   setLoading(false);
        //   form.resetFields();
        //   onCancel();
        // }, 2000); // Simulate API call
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
      });
  };
  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{data?.id ? "Update " : "Add "} Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "300px" }}>
            <Form form={form} layout="vertical" name="userForm">
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input placeholder="Enter your email" />
              </Form.Item>
              <Form.Item
                name="contact_no"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: "Please enter your contact number",
                  },
                  {
                    pattern: /^[0-9]{10}$/, // Validates a 10-digit number
                    message: "Contact number must be exactly 10 digits",
                  },
                ]}
              >
                <Input placeholder="Enter your contact number" />
              </Form.Item>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            onClick={() => {
              handleOk();
            }}
          >
            Submit
            {updating && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
