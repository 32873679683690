// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/setting",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-gear  fs-1"
          // style={{ color: "#DC143C" }}
        ></i>
      </span>
    ),
    label: GetLinks("/setting", "Setting"),
  },
  // {
  //   key: "/setting",
  //   icon: (
  //     <span class="menu-icon">
  //       <i class="ki-outline ki-gear  fs-1"></i>
  //     </span>
  //   ),
  //   label: "Setting",
  //   children: [
  //     {
  //       key: "/setting/profile",
  //       icon: (
  //         <span className="menu-bullet">
  //           <span className="bullet bullet-dot" />
  //         </span>
  //       ),
  //       label: GetLinks("/setting/profile", "My Profile"),
  //     },
  //     {
  //       key: "/setting/amazon-sp",
  //       icon: (
  //         <span className="menu-bullet">
  //           <span className="bullet bullet-dot" />
  //         </span>
  //       ),
  //       label: GetLinks("/setting/amazon-sp", "MarketPlace Credentials"),
  //     },
  //   ],
  // },
];
