import styled from "styled-components";

export const Wrapper = styled.div`
  background: #398ce42e;
  padding-bottom: 15px;
  position: relative;
  top: 15px;
  border-radius: 18px 18px 0px 0px;
  border-top: 1px solid #3792f0;
  border-left: 1px solid #3792f0;
  border-right: 1px solid #3792f0;
  padding: 0px 10px 15px 10px;

  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;

  @-webkit-keyframes slideInUp {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  @keyframes slideInUp {
    0% {
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      visibility: visible;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
`;
