import { Card } from 'antd';
import React from 'react';
import Chart from 'react-apexcharts';

function PerformanceChart() {



  const options = {
    series: [{
      name: 'Inbound Shipments',
      type: 'column',
      data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
    }, {
      name: 'Lost Removal',
      type: 'line',
      data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
    }, {
      name: 'PPC Unit Sold',
      type: 'line',
      data: [15, 24, 30, 35, 40, 28, 36, 48, 52, 60, 65, 70]
    }, {
      name: 'Unit Sold',
      type: 'line',
      data: [100, 120, 140, 160, 180, 200, 230, 250, 270, 300, 320, 350]
    }],
    chart: {
      height: 350,
      type: 'line',
      toolbar: { show: false },
    },
    markers: {
      size: 5,
      hover: {
        size: 9
      }
    },
    colors: ["#3792F0", "#333", "#B56263", '#d7cc6c', '#1f4173'],
    plotOptions: {
      bar: { horizontal: false, columnWidth: "30%", borderRadius: 3 },
    },
    stroke: {
      show: true,
      curve: "smooth",
      width: [0, 3.5, 3.5, 3.5],
      dashArray: [0, 4, 0, 0],
      lineCap: "round",
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1, 2, 3]
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: false } },
      yaxis: { lines: { show: true } },
    },
    labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
    xaxis: {
      type: 'datetime'
    },
    yaxis: [{
      title: {
        text: 'Inbound Shipments',
        style: { colors: '#333', fontSize: "14px" }
      },
      labels: {
        formatter: function (value) {
          return value + "k";
        },
        offsetX: -12,
        offsetY: 0,
      },
    }, {
      opposite: true,
      title: {
        text: 'Lost Removal',
        style: { colors: '#333', fontSize: "14px" }
      },
    }, {
      opposite: true,
      title: {
        text: 'PPC Unit Sold & Unit Sold',
        style: { colors: '#333', fontSize: "14px" }
      },
      labels: {
        formatter: function (value) {
          return value;
        },
        offsetX: 12,
        offsetY: 0,
      },
    }]
  };




  return (
    <>
        <Card title="Business Performance Graph">
        <Chart options={options} series={options.series} type="line" height={350} />
        {/* <Chart options={chartOptions} series={chartOptions.series} type="line" height={350} /> */}
        </Card>
    </>
  )
}

export default PerformanceChart