import { Select, Skeleton, Tooltip, message } from "antd";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { NumberWithCommas } from "../../../../config";
import moment from "moment";
import Highcharts from "highcharts";
import NoData from "../../../../component/no-data";
import { FilePdfOutlined, FileExcelOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import writeXlsxFile from "write-excel-file";
import HeaderCustom from "../../../../component/header-custom";
import CardHeader from "../../../../component/card-header";
// import { savePDF } from "@progress/kendo-react-pdf";

export default function (props) {
  const {
    currency_sign,
    apiResAdvDailyTrends,
    selectedFilter,
    setSelectedFilter,
    chartSeries,
    chartXAxis,
    setchartSeries,
    setchartXAxis,
    advDailyTrendData,
    onChangeMatricsFilter,
    dailyPerformanceTrendChartLoading,
    setDailyPerformanceTrendChartLoading,
    exportPerformanceTrendGraph,
  } = props;
  const colorHashCodes = ["#FF9843", "#FFDD95", "#86A7FC", "#3468C0"];
  // const colorHashCodes = ["#8992d4", "#373fb5", "#98cabb", "#097980"];
  const sign = {
    acos: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    tacos: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    roas: {
      valueSuffix: "",
      valuePrefix: "",
    },
    spend: {
      valueSuffix: "",
      valuePrefix: currency_sign,
    },
    sales: {
      valueSuffix: "",
      valuePrefix: currency_sign,
    },
    cpc: {
      valueSuffix: "",
      valuePrefix: currency_sign,
    },
    cvr: {
      valueSuffix: "",
      valuePrefix: "",
    },
    unit_ordered: {
      valueSuffix: "",
      valuePrefix: "",
    },
    clicks: {
      valueSuffix: "",
      valuePrefix: "",
    },
    impressions: {
      valueSuffix: "",
      valuePrefix: "",
    },
    ctr: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    ad_sales_percentage: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    spc: {
      valueSuffix: "",
      valuePrefix: currency_sign,
    },
  };

  useEffect(() => {
    if (chartXAxis?.length === 0 || chartSeries?.length === 0) return;
    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "480px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
      },
      type: "column",
      title: "",
      xAxis: {
        title: {
          text: "Date",
        },
        categories: chartXAxis,
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],
      series: chartSeries?.map((d, i) => ({
        name: d?.name,
        type: i == 3 ? "line" : "column",
        // tooltip: sign?.[d?.name],
        color: colorHashCodes?.[i] || "#A52A2A",
        data: d?.data,
        yAxis: 0,
        yAxis: sign?.[apiResAdvDailyTrends[d?.name]]?.valuePrefix
          ? 1
          : sign?.[apiResAdvDailyTrends[d?.name]]?.valueSuffix
          ? 2
          : 0,
      })),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
          // You can add more properties to modify the tooltip style
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            "<span>" +
            this.series.name +
            "</span>" +
            ": <b>" +
            sign?.[apiResAdvDailyTrends[this.series.name]]?.valuePrefix +
            this.y?.toLocaleString() +
            sign?.[apiResAdvDailyTrends[this.series.name]]?.valueSuffix +
            "</b><br/>"
          );
        },
      },
    });
  }, [chartSeries]);

  const chartRef = useRef(null);
  const handleExportPDF = () => {
    // savePDF(chartRef.current, { paperSize: "auto", fileName: "chart.pdf" });
  };

  const exportHeaderAmazon = [
    { value: "Date", type: String },
    { value: "ACoS", type: String },
    { value: "RoAS", type: String },
    { value: "Spend", type: String },
    { value: "Total Sales", type: String },
    { value: "CPC", type: String },
    { value: "Orders", type: String },
    { value: "Clicks", type: String },
    { value: "Impressions", type: String },
    { value: "CTR", type: String },
    { value: "CVR", type: String },
    // { value: "Ad Sales Percentage", type: String },
    // { value: "TACoS", type: String },
  ];

  const downloadXlAmazon = async () => {
    let dataRow = [];
    await exportPerformanceTrendGraph.map((d) => {
      const newList = [
        d?.report_date,
        d?.acos,
        d?.roas,
        d?.spend,
        d?.sales,
        d?.cpc,
        d?.cvr,
        d?.unit_ordered,
        d?.clicks,
        d?.impressions,
        d?.ctr,
        d?.ad_sales_percentage,
        d?.tacos,
      ];
      dataRow = [
        ...dataRow,
        newList.map((d) => {
          return { value: d };
        }),
      ];
    });
    const data = [exportHeaderAmazon, ...dataRow];
    await writeXlsxFile(data, {
      fileName: "Daily Performance Trend.xlsx",
    });
    message.destroy();
  };

  return (
    <>
      <div className="row">
        <div className="col-12 px-3">
          <CardHeader>
            <div className="d-flex align-items-center justify-content-between py-4 ">
              <label className=" align-items-start flex-column me-3">
                <span className="fw-bold text-dark fs-2 ms-3">
                  Daily Performance Trend
                </span>
              </label>
              <div className="card-toolbar me-3">
                <Select
                  className="w-200px"
                  size="large"
                  placeholder="Select"
                  mode="multiple"
                  onChange={(e) => {
                    message.destroy();
                    if (e?.length <= 4) {
                      setDailyPerformanceTrendChartLoading(true);
                      setchartSeries([]);
                      setchartXAxis([]);
                      setSelectedFilter(e);
                      onChangeMatricsFilter(e);
                    } else {
                      message.warning("Select Only 4 Options");
                    }
                  }}
                  maxTagCount={"responsive"}
                  value={selectedFilter}
                  options={advDailyTrendData?.map((d, i) => {
                    return { label: d?.label, value: d?.value };
                  })}
                />
              </div>
            </div>
          </CardHeader>
        </div>
      </div>
      <div className="card card-shadow card-2 mb-5 mb-xl-8">
        {/* <div className="card-header border-bottom-dashed">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark">
              Daily Performance Trend
            </span>
          </h3>
          <div className="card-toolbar">
            <Select
              className="w-200px"
              size="large"
              placeholder="Select"
              mode="multiple"
              onChange={(e) => {
                message.destroy();
                if (e?.length <= 4) {
                  setDailyPerformanceTrendChartLoading(true);
                  setchartSeries([]);
                  setchartXAxis([]);
                  setSelectedFilter(e);
                  onChangeMatricsFilter(e);
                } else {
                  message.warning("Select Only 4 Options");
                }
              }}
              maxTagCount={"responsive"}
              value={selectedFilter}
              options={advDailyTrendData?.map((d, i) => {
                return { label: d?.label, value: d?.value };
              })}
            />
          </div>
        </div> */}

        <div className="card-body py-2">
          <div className="d-flex justify-content-end mb-10">
            {/* <span
              className="d-flex align-items-center cursor-pointer me-5"
              onClick={() => {
                handleExportPDF();
              }}
            >
              <Tooltip title="Download PDF" placement="bottom">
                <FilePdfOutlined className="fs-1" style={{ color: "red" }} />
              </Tooltip>
            </span> */}
            <span
              className="d-flex align-items-center cursor-pointer"
              onClick={() => {
                // handleExportPDF();
                downloadXlAmazon();
              }}
            >
              <Tooltip title="Download Excel" placement="bottom">
                <FileExcelOutlined className="fs-1" style={{ color: "blue" }} />
              </Tooltip>
            </span>
          </div>

          {dailyPerformanceTrendChartLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Skeleton.Node active />
            </div>
          ) : chartSeries?.length != 0 && chartXAxis?.length != 0 ? (
            <div>
              <div id="line-chart" ref={chartRef}></div>{" "}
            </div>
          ) : (
            <NoData height={"225px"} type="new" />
          )}
        </div>
      </div>
    </>
  );
}
