import styled from "styled-components";

export const SidebarWrapper = styled.div`
  .ant-menu-root.ant-menu-inline {
    border-inline-end: none !important;
  }
  .ant-menu-light {
    background: transparent !important;
  }

  .ant-menu-item-selected {
    background: linear-gradient(
      90deg,
      rgb(54 153 255) 0%,
      rgb(59 126 197) 100%
    ) !important;
    color: #fff !important;
    animation: pulsate-bck 0.5s ease-in-out 1 both;
    box-shadow: rgb(54 153 255 / 65%) 0px 10px 20px 0px;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    background: linear-gradient(
      90deg,
      rgb(54 153 255) 0%,
      rgb(59 126 197) 100%
    ) !important;
    color: #fff !important;
    animation: pulsate-bck 0.5s ease-in-out 1 both;
    box-shadow: rgb(54 153 255 / 65%) 0px 10px 20px 0px;
    .ant-menu-item-icon > i {
      color: #fff !important;
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-item-selected {
      background: #ffffff !important;
      color: #3699ff !important;
      animation: pulsate-bck 0.5s ease-in-out 1 both;
      box-shadow: none !important;
      border: 1px solid #3699ff;
    }
  }
  .ant-menu-item-selected .ant-menu-item-icon i {
    color: #fff !important;
  }
  .ant-menu .ant-menu-item {
    transition: border-color 0.1s, background 0.1s, color 0.1s,
      padding 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-menu-item-icon i {
    // color: #3699ff !important;
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-item-icon i {
    // color: #3699ff !important;
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title {
    // background-color: transparent !important;
  }
  .ant-menu-sub {
    background: transparent !important;
  }
  .ant-menu-title-content {
    font-size: 15px !important;
  }

  .pulsate-bck {
    -webkit-animation: pulsate-bck 0.5s ease-in-out infinite both;
    animation: pulsate-bck 0.5s ease-in-out infinite both;
  }
  @keyframes pulsate-bck {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .fadeInLeftSidebar {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: fadeInLeftSidebar;
  }

  @keyframes fadeInLeftSidebar {
    0% {
      opacity: 0;
      transform: translateX(-200px);
    }
    100% {
      opacity: 1;
    }
  }
  .sm-new-drawer {
    // border-radius: 0px 25px 25px 0px !important;
    box-shadow: 1px 0px 7px -4px !important;
  }
  .ant-menu-light.ant-menu-root.ant-menu-inline {
    border-inline-end: none;
  }
  .ant-menu-inline .ant-menu-item {
    margin-block: 8px !important;
  }
  .custom-sidebar {
    margin: 10px;
    border-radius: 18px !important;
    border: none !important;
    box-shadow: rgb(255 50 13 / 14%) 0px 10px 50px !important;
    .aside-menu {
      // border-bottom: 1px dashed #15b7e0;
    }
  }

  .ant-dropdown-trigger {
    // background: #ffffff00;
    background: linear-gradient(
      90deg,
      rgb(54 153 255) 0%,
      rgb(59 126 197) 100%
    ) !important;
    padding: 12px;
    border-radius: 18px;
    // border: 1px solid #3697fa;
  }
  .custom-dropdown {
    background: #dfeeff;

    position: relative;
    right: 0px;
    padding-right: 5px;
    padding-top: 3px;
    border-radius: 12px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    // border-left: 2px solid #3897fb;
  }
`;
