// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/product-research",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-chart-pie-4 fs-1"
          // style={{ color: "#4B0082" }}
        ></i>
      </span>
    ),
    label: GetLinks("/product-research", "Product Research"),
  },
];
