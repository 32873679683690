import { Input, Select, Tag } from "antd";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { DisplayNotification } from "../../../../../config";

const FBAFeesModal = (props) => {
  const { show, onHide, data, onSuccess } = props;
  const [sending, setSending] = useState(false);
  const [details, setDetails] = useState({});

  const SaveLostWhsDetails = async () => {
    const response = await MakeApiCall(
      `rule-management/get-rules`,
      `get`,
      details,
      true
    );
    if (response?.status) {
      DisplayNotification("success", "Success", response?.message);
      setSending(false);
      onSuccess();
    } else {
      setSending(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            FBA Fees - <Tag color="geekblue">FNSKU : {data?.fnsku}</Tag>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "300px" }}>
            <div className="row">
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Case Id</label>
                <Input placeholder="Case Id" />
              </div>

              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Manager</label>
                <Select
                  className="w-200px"
                  placeholder="Select Manager"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            onClick={() => {
              SaveLostWhsDetails();
            }}
          >
            Saving
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FBAFeesModal;
