import { Button, Input, message, Popconfirm, Tag } from "antd";
import React, { useEffect, useState } from "react";
import EsfTable from "../../../../component/esf-table";
import CouponFormModal from "./add";
import { MakeApiCall } from "../../../../apis/make-api-call";
import moment from "moment";
import dayjs from "dayjs";

const CouponCodes = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [editingData, setEditingData] = useState(null);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search_key, setSearch_key] = useState("");

  const getList = async (data) => {
    const response = await MakeApiCall(
      `coupon-code?isAll=1&coupon_code=${data?.search_key || ""}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data || []);
      setLoading(false);
    } else {
      setLoading(false);
      message.warning(response?.message);
    }
  };

  // Open modal for adding new data
  const handleAddClick = () => {
    setEditingData(null);
    setModalVisible(true);
  };

  // Open modal for editing existing data
  const handleEditClick = (data) => {
    console.log(data, "data");

    setEditingData({
      ...data,
      expiry_date: dayjs(data?.expiry_date, "YYYY-MM-DD"),
    });
    setModalVisible(true);
  };

  const addRow = async (data) => {
    const response = await MakeApiCall(
      `coupon-code`,
      "post",
      { ...data, expiry_date: dayjs(data?.expiry_date)?.format("YYYY-MM-DD") },
      true
    );
    if (response?.status) {
      getList({ search_key });
      setEditingData(null);
      setModalVisible(false);
    } else {
      setEditingData(null);
      setModalVisible(false);
      message.warning(response?.message);
    }
  };
  const editRow = async (data) => {
    const response = await MakeApiCall(
      `coupon-code/${editingData?.id}`,
      "put",
      { ...data, expiry_date: dayjs(data?.expiry_date)?.format("YYYY-MM-DD") },
      true
    );
    if (response?.status) {
      getList({ search_key });
      setEditingData(null);
      setModalVisible(false);
    } else {
      setEditingData(null);
      setModalVisible(false);
      message.warning(response?.message);
    }
  };

  const removeRow = async (id) => {
    const response = await MakeApiCall(
      `coupon-code/${id}`,
      "delete",
      null,
      true
    );

    if (response?.status) {
      setLoading(true);
      getList({
        search_key,
      });
    } else {
      message.error(response?.message);
    }
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    if (editingData) {
      editRow(data);
      // console.log("Updated data:", data);
      // message.success("Coupon data updated successfully!");
    } else {
      addRow(data);
      // console.log("New data:", data);
      // message.success("Coupon data added successfully!");
    }
  };

  useEffect(() => {
    setLoading(true);
    getList({ search_key });
    return () => {};
  }, []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => 1 + index,
    },

    {
      title: "Partner Name",
      dataIndex: "partner_name",
      key: "partner_name",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Coupon Code",
      dataIndex: "coupon_code",
      key: "coupon_code",
    },
    {
      title: "Amount (%)",
      dataIndex: "coupon_amount",
      key: "coupon_amount",
      render: (amount) => (
        <Tag color={amount >= 20 ? "green" : "volcano"}>{amount}%</Tag>
      ),
    },
    {
      title: "Applies To",
      dataIndex: "applies_to",
      key: "applies_to",
    },
    {
      title: "Expires",
      dataIndex: "expiry_date",
      key: "expiry_date",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="d-flex flex-shrink-0 justify-content-center">
          <span
            onClick={() => handleEditClick(record)}
            className="btn btn-icon bg-light btn-active-color-success btn-sm me-2"
          >
            <i className="ki-outline ki-pencil fs-2 text-success" />
          </span>
          <Popconfirm
            title="Delete Coupon Code?"
            placement="left"
            description="Are you sure to delete this coupon code?"
            onConfirm={() => {
              removeRow(record?.id);
            }}
            okText="Yes"
            cancelText="No"
          >
            <span className="btn btn-icon bg-light btn-active-color-primary btn-sm">
              <i className="ki-outline ki-trash fs-2 text-danger" />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const ClearSearch = async () => {
    setLoading(true);
    setSearch_key("");
    getList({ search_key: "" });
  };

  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="card card-xxl-stretch mb-5 mb-xl-8">
        <div className="card-header border-0">
          <div className="d-flex flex-stack flex-wrap gap-4 p-2">
            <div className="position-relative">
              <Input
                prefix={
                  <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                }
                placeholder="Search..."
                size="large"
                onPressEnter={() => {
                  setLoading(true);
                  getList({ search_key });
                }}
                onChange={(e) => {
                  setSearch_key((prev) => {
                    if (!e.target.value && prev) {
                      ClearSearch();
                    }
                    return e.target.value;
                  });
                }}
              />
            </div>
          </div>
          <div className="card-toolbar">
            <div class="d-flex flex-stack flex-wrap gap-4">
              <Button size="large" type="dashed">
                <i
                  className="ki-duotone ki-exit-up fs-2 me-3"
                  style={{ position: "relative", top: "3px" }}
                >
                  <span className="path1" />
                  <span className="path2" />
                </i>
                Export
              </Button>
              <Button onClick={handleAddClick} size="large" type="primary">
                + Add
              </Button>
            </div>
          </div>
        </div>
        <div className="card-body py-2">
          <EsfTable columns={columns} dataSource={list} loading={loading} />
        </div>
      </div>
      {isModalVisible && (
        <CouponFormModal
          visible={isModalVisible}
          onClose={() => setModalVisible(false)}
          onSubmit={handleFormSubmit}
          initialValues={editingData}
        />
      )}
    </div>
  );
};

export default CouponCodes;
