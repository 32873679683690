import { Popover, Select, Tag } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis/make-api-call";
import EsfTable from "../../../../component/esf-table";
import Pagination from "../../../../component/esf-table/lib/pagination";
import NoDataTable from "../../../../component/no-data-table";
import {
  ConvertParams,
  DateFormat,
  DefaultPerPage,
  DisplayNotification,
  NumberWithCommas,
} from "../../../../config";
import { adminCentralLogEventStatus } from "../../../../config/select-dropdown-list";
import { GlobalContext } from "../../../../commonContext";
import RangePickerESF from "../../../../component/range-picker";
import dayjs from "dayjs";

const CentralLog = () => {
  const contextValue = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [list, set_list] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [event_list, set_event_list] = useState({});
  const [all_filters, set_all_filters] = useState({
    page: 1,
    "per-page": DefaultPerPage,
    search_key: "",
    start_date: dayjs().add(-90, "d"),
    end_date: dayjs(),
  });

  useEffect(() => {
    localStorage.setItem("token_type", "");
    if (contextValue?.data?.user_type == 2) {
      if (localStorage.getItem("sub_user_list")) {
        GetCentralLogList({
          ...all_filters,
          start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
          end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
        });
        GetEventNameList();
      } else {
        setLoading(false);
        DisplayNotification("error", "Error", "User not found");
      }
    } else {
      GetCentralLogList({
        ...all_filters,
        start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
        end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
      });
      GetEventNameList();
    }
  }, [contextValue?.data?.selected_sub_user]);

  const GetCentralLogList = async (filters) => {
    setLoading(true);
    const response = await MakeApiCall(
      `user/central-log-system${ConvertParams(filters)}`,
      `get`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      set_list(response?.data?.records);
      setTotalPage(response?.data?.pagination?.totalCount);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetEventNameList = async () => {
    setLoading(true);
    const response = await MakeApiCall(`event-name-list`, `get`, null, true);
    if (response?.status) {
      // const newData = Object.entries(response?.data)?.map((d) => {
      //   return d?.[0];
      // });
      set_event_list(response?.data);
    } else {
      set_event_list([]);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const onPageNo = (e) => {
    set_all_filters({ ...all_filters, page: e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: e,
      "per-page": all_filters?.["per-page"],
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetCentralLogList(apiObj);
  };

  const onPerPage = (e) => {
    set_all_filters({ ...all_filters, page: 1, "per-page": e });
    setLoading(true);
    const apiObj = {
      ...all_filters,
      page: 1,
      "per-page": e,
      start_date: moment(all_filters?.start_date?.$d).format("YYYY-MM-DD"),
      end_date: moment(all_filters?.end_date?.$d).format("YYYY-MM-DD"),
    };
    GetCentralLogList(apiObj);
  };

  const columns = [
    {
      title: "#",
      render: (text) => (
        <span>
          {(all_filters?.page - 1) * all_filters?.["per-page"] + 1 + text?.key}
        </span>
      ),
    },
    {
      title: "Log Created Date",
      render: (record) => (
        <span>
          {moment(new Date(record?.created_at * 1000)).format(
            "MMM DD, YYYY hh:mm A"
          )}
        </span>
      ),
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      render: (event_date) => (
        <span> {moment(new Date(event_date)).format("MMM DD, YYYY")}</span>
      ),
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      render: (event_name, record) => (
        <>
          <span className="fw-bolder">{event_name}</span>
          <br />
          {record.event_type === "Advertising Report" &&
            (event_name === "sponsoredDisplay"
              ? `(${JSON.parse(record.event_details)?.record_type || ""}) ${
                  JSON.parse(record.event_details)?.tactic_name || ""
                }`
              : `(${JSON.parse(record.event_details)?.record_type || ""})`)}
        </>
      ),
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      render: (event_type) => <span>{event_type || "-"}</span>,
    },
    {
      title: "Event Status",
      dataIndex: "event_status",
      render: (event_status) => {
        let color;
        switch (event_status) {
          case "PENDING":
            color = "gold";
            break;
          case "IN_PROGRESS":
            color = "blue";
            break;
          case "DONE":
            color = "green";
            break;
          case "FAILED":
            color = "red";
            break;
          case "FATAL":
            color = "magenta";
            break;
          case "CANCELLED":
            color = "volcano";
            break;
          case "NOT_FOUND":
            color = "gold";
            break;
          case "NO_DATA":
            color = "purple";
            break;
          default:
            color = "";
        }
        return <Tag color={color}>{event_status}</Tag>;
        // return <Tag color={"geekblue"}>{event_status}</Tag>;
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: (priority) => (
        <span>
          {" "}
          {priority == 1 ? (
            <div className="d-flex align-items-center">
              <div
                className="priorityColorDot me-2"
                style={{
                  background: "#ff0000",
                  height: "10px",
                  width: "10px",
                  borderRadius: "5px",
                }}
              ></div>
              High
            </div>
          ) : priority == 2 ? (
            <div className="d-flex align-items-center">
              <div
                className="priorityColorDot me-2"
                style={{
                  background: "#faad14",
                  width: "10px",
                  height: "10px",
                  borderRadius: "5px",
                }}
              ></div>
              Medium
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <div
                className="priorityColorDot me-2"
                style={{
                  background: "#52c41a",
                  width: "10px",
                  height: "10px",
                  borderRadius: "5px",
                }}
              ></div>
              Low
            </div>
          )}
        </span>
      ),
    },
    {
      title: "Request Time",
      dataIndex: "request_time",
      render: (request_time) =>
        request_time ? (
          <span>
            {moment(new Date(request_time * 1000)).format(
              "MMM DD, YYYY hh:mm A"
            )}
            <br />
            <small className="text-gray-500">
              ({moment(request_time * 1000).fromNow()})
            </small>
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "Download Time",
      dataIndex: "download_time",
      render: (download_time) =>
        download_time ? (
          <span>
            {moment(new Date(download_time * 1000)).format(
              "MMM DD, YYYY hh:mm A"
            )}
            <br />
            <small className="text-gray-500">
              ({moment(download_time * 1000).fromNow()})
            </small>
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "Execution Time",
      dataIndex: "execution_time",
      render: (execution_time) => (
        <span>{NumberWithCommas(execution_time || 0)}</span>
      ),
    },
    {
      title: "Retry Count",
      dataIndex: "retry_count",
      render: (retry_count) => (
        <span>{NumberWithCommas(retry_count || 0)}</span>
      ),
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      render: (marketplace) => <span>{marketplace || "-"}</span>,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      render: (updated_at) => (
        <span>
          {moment(new Date(updated_at * 1000)).format("MMM DD, YYYY hh:mm A")}
          <br />
          <small className="text-gray-500">
            ({moment(updated_at * 1000).fromNow()})
          </small>
        </span>
      ),
    },

    {
      title: "Report Requested",
      dataIndex: "status",
      render: (status) => (
        <Tag color={status == 1 ? "green" : "gold"}>
          <span>{status == 1 ? "DONE" : "PENDING"}</span>
        </Tag>
      ),
    },
    {
      title: "Reason",
      dataIndex: "note",
      render: (note) => (
        <Popover
          content={
            <div style={{ height: "max-content", maxWidth: "400px" }}>
              {note}
            </div>
          }
          placement="bottomLeft"
        >
          <div
            className="mb-1 text-one fs-6 popoverActionIconSingleLine"
            style={{ minWidth: "100px" }}
          >
            {note}
          </div>
        </Popover>
      ),
    },
  ];

  return (
    <>
      <div className="container-fluid" id="kt_content_container">
        <div className="row gy-5 g-xl-5 mb-5  px-0 mx-0">
          <div className="col-xxl-12 px-0 mx-0">
            <div
              className="card"
              style={{ background: " #e9f1fa", border: "1px dashed #3699ff69" }}
            >
              <div className="card-header border-0 d-flex align-items-center p-5">
                <div>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="position-relative">
                      <RangePickerESF
                        className="ant_common_input"
                        id="aalacart_date_picker"
                        size="large"
                        allowClear={false}
                        style={{ width: "290px" }}
                        format={DateFormat}
                        value={[all_filters?.start_date, all_filters?.end_date]}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(e) => {
                          const filters_ = all_filters;
                          filters_.start_date = e?.[0];
                          filters_.end_date = e?.[1];
                          set_all_filters({ ...filters_ });
                        }}
                      />
                    </div>

                    <div className="position-relative ">
                      {" "}
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Event Name"
                        allowClear
                        value={all_filters?.event_name || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,

                            event_name: e,
                          };
                          set_all_filters(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_name || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative ">
                      {" "}
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Event Type"
                        allowClear
                        value={all_filters?.event_type || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,

                            event_type: e,
                          };
                          set_all_filters(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={Object.entries(
                          event_list?.event_type || {}
                        )?.map((d) => {
                          return {
                            label: d?.[0],
                            value: d?.[0],
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative ">
                      {" "}
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Event Status"
                        allowClear
                        value={all_filters?.event_status || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,

                            event_status: e,
                          };
                          set_all_filters(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={adminCentralLogEventStatus.map((d) => {
                          return {
                            label: d.label,
                            value: d.value,
                          };
                        })}
                      />
                    </div>
                    <div className="position-relative ">
                      {" "}
                      <Select
                        className="w-200px"
                        size="large"
                        placeholder="Priority"
                        allowClear
                        value={all_filters?.priority || null}
                        onChange={(e) => {
                          const apiObj = {
                            ...all_filters,

                            priority: e,
                          };
                          set_all_filters(apiObj);
                        }}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        options={[
                          {
                            value: "1",
                            label: "High",
                          },
                          {
                            value: "2",
                            label: "Medium",
                          },
                          {
                            value: "3",
                            label: "Low",
                          },
                        ]}
                      />
                    </div>
                    <div className="position-relative ">
                      <button
                        onClick={() => {
                          const apiObj = {
                            ...all_filters,
                            page: 1,
                            "per-page": DefaultPerPage,
                          };
                          setLoading(true);
                          GetCentralLogList({
                            ...all_filters,
                            page: 1,
                            start_date: moment(
                              all_filters?.start_date?.$d
                            ).format("YYYY-MM-DD"),
                            end_date: moment(all_filters?.end_date?.$d).format(
                              "YYYY-MM-DD"
                            ),
                          });
                        }}
                        className="btn btn-primary  btn-sm"
                      >
                        {" "}
                        <i class="ki-outline ki-magnifier fs-2"></i>
                        {/* Search */}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*begin::Row*/}
        <div className="row gy-5 g-xl-5">
          {/*begin::Col*/}
          <div className="col-xxl-12">
            {/*begin::Tables Widget 9*/}
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              {/*begin::Header*/}
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  {" "}
                  <h3 class="card-title align-items-start flex-column">
                    <span class="card-label fw-bold fs-3 mb-1">
                      Central Log
                    </span>
                  </h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              {/*end::Header*/}
              {/*begin::Body*/}
              <div className="card-body py-2">
                {/*begin::Table container*/}
                <div className="table-responsive">
                  {loading ? (
                    <NoDataTable columns={columns} type="loading" />
                  ) : list?.length > 0 ? (
                    <EsfTable
                      columns={columns?.map((d) => ({
                        ...d,
                        width: d?.title?.length * 13,
                      }))}
                      sticky={{
                        offsetHeader: "10px",
                      }}
                      scroll={{
                        x: "max-content",
                        y: "calc(100vh - 320px)",
                      }}
                      fixed="top"
                      dataSource={list}
                      rowKey="key"
                      loading={loading}
                      pagination={false}
                    />
                  ) : (
                    <>
                      <NoDataTable columns={columns} type="no-data" />
                    </>
                  )}
                </div>
                <Pagination
                  loading={loading || list?.length === 0}
                  pageSize={all_filters?.["per-page"]}
                  page={all_filters?.page}
                  totalPage={totalPage}
                  onPerPage={onPerPage}
                  onPageNo={onPageNo}
                />
              </div>
              {/*begin::Body*/}
            </div>
            {/*end::Tables Widget 9*/}
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>
    </>
  );
};

export default CentralLog;
