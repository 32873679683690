import { Input, Select, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { MakeApiCall } from "../../../../../apis/make-api-call";
import { DisplayNotification } from "../../../../../config";

const DamagedWhsModal = (props) => {
  const { show, onHide, data, onSuccess, case_status_list, manager_list } =
    props;
  const [saving, setSaving] = useState(false);
  const [details, setDetails] = useState({});

  useEffect(() => {
    setDetails({
      id: data?.id,
      case_id: data?.case_id,
      rms_id: data?.rms_id,
      quantity_reimbursed_total: data?.quantity_reimbursed_total,
      credited_amount: data?.credited_amount,
      currency: data?.currency,
      status_of_the_case: data?.status_of_the_case,
      manager_id: data?.manager_id,
      comment: data?.comment,
    });
  }, [data]);

  const SaveDetails = async () => {
    const response = await MakeApiCall(
      `rms/update-detail`,
      `put`,
      details,
      true
    );
    if (response?.status) {
      DisplayNotification("success", "Success", response?.message);
      setSaving(false);
      onSuccess();
    } else {
      setSaving(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="lg" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            Damaged WHS - <Tag color="geekblue">FNSKU : {data?.fnsku}</Tag>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ minHeight: "300px" }}>
            <div className="row">
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Case Id</label>
                <Input
                  className="w-100"
                  value={details?.case_id}
                  onChange={(e) => {
                    setDetails({ ...details, case_id: e.target.value });
                  }}
                />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">RMS Id</label>
                <Input
                  className="w-100"
                  value={details?.rms_id}
                  onChange={(e) => {
                    setDetails({ ...details, rms_id: e.target.value });
                  }}
                />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">
                  Quantity Reimbursed Total
                </label>
                <Input
                  className="w-100"
                  value={details?.quantity_reimbursed_total}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      quantity_reimbursed_total: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Credited Amount</label>
                <Input
                  className="w-100"
                  value={details?.credited_amount}
                  onChange={(e) => {
                    setDetails({ ...details, credited_amount: e.target.value });
                  }}
                />
              </div>
              {/* <div className="col-4 mb-8">
              <label className="fw-bold mb-1">Currency</label>
              <Input
                className="w-100"
                value={details?.currency}
                onChange={(e) => {
                  setDetails({ ...details, currency: e.target.value });
                }}
              />
            </div> */}
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Comment</label>
                <Input
                  className="w-100"
                  value={details?.comment}
                  onChange={(e) => {
                    setDetails({ ...details, comment: e.target.value });
                  }}
                />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Case Status</label>
                <Select
                  options={case_status_list?.map((d) => {
                    return {
                      label: d?.status,
                      value: d?.id,
                    };
                  })}
                  allowClear
                  placeholder="Case Status"
                  className="w-100"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={details?.status_of_the_case}
                  onChange={(e) => {
                    setDetails({ ...details, status_of_the_case: e });
                  }}
                />
              </div>
              <div className="col-4 mb-8">
                <label className="fw-bold mb-1">Manager</label>
                <Select
                  placeholder="Select Manager"
                  value={details?.manager_id}
                  className="w-100"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  options={manager_list?.map((d) => {
                    return {
                      label: d?.name,
                      value: d?.id,
                    };
                  })}
                  onChange={(e) => {
                    setDetails({ ...details, manager_id: e });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary fs-7"
            onClick={() => {
              SaveDetails();
            }}
            disabled={saving}
          >
            Save
            {saving && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DamagedWhsModal;
