import React from "react";

function ListingOptimizer() {
  return (
    <div className="container-fluid" id="kt_content_container">
      <div className="row mb-8 px-0 mx-0">
        <div className="col-md-12 "></div>
      </div>
    </div>
  );
}

export default ListingOptimizer;
