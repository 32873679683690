import { Divider, Dropdown, Menu, theme } from "antd";
import React, { useContext } from "react";
import payload from "./lib";
import { SidebarWrapper } from "./style";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { PhotoUrl } from "../../../config";
import { Icon } from "@iconify/react/dist/iconify.js";
const { useToken } = theme;
const Sidebar = (props) => {
  const { sidebarToggle, setSidebarToggle } = props;
  const contextValue = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const token = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };
  const getUerTypeMenus = (menuItems, userType) => {
    if (userType != 2) {
      return menuItems.filter((item) => item.key !== "/user-accounts");
    }
    return menuItems;
  };
  const getMenuItems = () =>
    contextValue?.data?.user_type == 1
      ? payload.admin
      : [...getUerTypeMenus(payload.users, contextValue?.data?.user_type)];
  const selectedMenu = () => {
    const selectedPathName =
      location?.pathname === "/callback-sp" ||
      location?.pathname === "/callback-ads"
        ? "/setting"
        : location?.pathname || "/";
    console.log(selectedPathName, "-selectedPathName");
    return selectedPathName || "/";
    // return selectedSidebarMenu(location?.pathname);
  };
  return (
    <SidebarWrapper>
      {sidebarToggle && (
        <span
          onClick={() => {
            setSidebarToggle(false);
          }}
          style={{
            position: "absolute",
            zIndex: "1000",
            left: "280px",
            top: " 6%",
          }}
          className="btn btn-icon btn-primary btn-outline w-40px h-40px border-0 fadeInLeftSidebar"
        >
          <i className="ki-outline ki-cross-square fs-1" />
        </span>
      )}
      <div
        id="kt_aside"
        className={`custom-sidebar aside py-9 fadeInLeftSidebar ${
          sidebarToggle ? "drawer sm-new-drawer " : ""
        } `}
        data-kt-drawer="true"
        data-kt-drawer-name="aside"
        // data-kt-drawer-activate="{default: true, lg: false}"
        data-kt-drawer-overlay="true"
        data-kt-drawer-width="{default:'200px', '300px': '250px'}"
        data-kt-drawer-direction="start"
        data-kt-drawer-toggle="#kt_aside_toggle"
        style={{
          backgroundColor: "#fff",
          position: "fixed",
          top: "0px",
          bottom: "0px",
          left: "0px",
          zIndex: 100,
          overflow: "hidden",
          width: "300px",
          borderRadius: "0rem 20px 20px 0px",
          borderWidth: " 5px 1px 1px 0px",
          borderStyle: "solid",
          borderColor: " #6eb5ff",
        }}
      >
        <div
          className="aside-logo flex-column-auto px-9 mb-5 text-center justify-content-center d-block"
          id="kt_aside_logo"
        >
          <span>
            <img
              alt="Logo"
              src="/assets/media/logo.png"
              className=" logo theme-light-show jello-horizontal"
              style={{ height: "55px" }}
            />
            {/* <img alt="Logo" src="assets/media/esf-logo22_white_1.png" class="h-35px logo theme-dark-show" /> */}
          </span>
        </div>
        <Divider className="my-3" style={{ borderColor: "#d7d7d7" }} />
        <div
          className="aside-menu flex-column-fluid px-3 mb-9"
          id="kt_aside_menu"
          style={{ overflowY: "auto", height: "calc(100vh - 200px)" }}
        >
          <div
            // className="w-100 hover-scroll-overlay-y d-flex"
            className="w-100 d-flex"
            id="kt_aside_menu_wrapper"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-height="auto"
            data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
            data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu, #kt_aside_menu_wrapper"
            data-kt-scroll-offset={100}
            style={{ overflowY: "auto" }}
          >
            <div
              className="menu menu-column menu-rounded menu-sub-indention menu-active-bg fw-semibold"
              id="#kt_aside_menu"
              data-kt-menu="true"
            >
              {/* <div className="menu-item mb-2 mt-10">
                <div className="menu-heading text-uppercase fs-7 fw-bold"></div>
                <div className="app-sidebar-separator separator" />
              </div> */}

              <Menu
                selectedKeys={selectedMenu()}
                mode="inline"
                items={getMenuItems()}
                onClick={() => {
                  setSidebarToggle(false);
                }}
                // inlineCollapsed={collapsed}
                // onClick={() => {
                //   setCollapsed(false);
                // }}
              />
            </div>
          </div>
        </div>

        <div
          className="aside-footer flex-column-auto px-4"
          id="kt_aside_footer"
        >
          <Dropdown
            trigger={["click"]}
            placement="bottom"
            dropdownRender={() => (
              <div style={contentStyle}>
                <div>
                  {/* <div className="separator border-gray-200" /> */}
                  {/* <Divider
                    className="my-3"
                    style={{ borderColor: "#15b7e0" }}
                  /> */}
                  <div
                    style={{
                      minHeight: "230px",
                      minWidth: "150px",
                      border: "1px solid #fff",
                      borderRadius: "7px",
                      overflow: "auto",
                      background: "#fff",
                      boxShadow: "#cccccc 0px 0px 12px 2px",
                    }}
                  >
                    <div
                      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                      data-kt-menu="true"
                      style={{ display: "block" }}
                    >
                      <div className="menu-item px-3 ">
                        <div className="menu-content d-flex align-items-center px-3">
                          <div className="symbol symbol-circle symbol-50px">
                            {contextValue?.data?.user_type == 1 ? (
                              <div
                                style={{
                                  height: "50px",
                                  width: "50px",
                                  background: "#3699ff",
                                  borderRadius: "100%",
                                  color: "rgb(255, 255, 255)",
                                  boxShadow:
                                    "#a39797 0px -50px 36px -28px inset",
                                }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <img
                                  src="/assets/media/user.png"
                                  style={{ width: "38px" }}
                                />
                              </div>
                            ) : (
                              <img
                                src={`${PhotoUrl}${
                                  JSON.parse(contextValue?.data?.user_data)
                                    ?.photo
                                }`}
                                alt="Profile"
                              />
                            )}
                          </div>

                          <div className="d-flex flex-column ms-2">
                            <div className="fw-bold d-flex align-items-center fs-5">
                              {JSON.parse(contextValue?.data?.user_data)?.name}
                            </div>
                            <span className="fw-semibold text-muted text-hover-primary fs-7">
                              {JSON.parse(contextValue?.data?.user_data)?.email}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="separator my-2" />
                      <div
                        className="menu-item px-5 my-1"
                        onClick={() => {
                          navigate("/setting");
                        }}
                      >
                        <span className="menu-link px-5">My Profile</span>
                      </div>
                      <div className="separator my-2" />
                      <div
                        className="menu-item px-5 my-1"
                        onClick={() => {
                          navigate("/callback-sp");
                        }}
                      >
                        <span className="menu-link px-5">Account Settings</span>
                      </div>
                      <div className="separator my-2" />
                      <div className="menu-item px-5">
                        <span
                          onClick={() => {
                            localStorage.clear();
                            navigate("/login", { replace: "true" });
                          }}
                          className="menu-link px-5 bg-primary text-light justify-content-center"
                        >
                          Sign Out
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          >
            <div className="d-flex flex-stack cursor-pointer">
              <div className="d-flex align-items-center">
                <div className="symbol symbol-circle symbol-50px">
                  {contextValue?.data?.user_type == 1 ||
                  contextValue?.data?.user_type == 4 ? (
                    <div
                      style={{
                        height: "40px",
                        width: "40px",
                        background: "rgb(59 126 197)",
                        borderRadius: "100%",
                        color: "rgb(255, 255, 255)",
                        // boxShadow: "#a39797 0px -50px 36px -28px",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="/assets/media/user.png"
                        style={{ width: "22px" }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        background: "rgb(59 126 197)",
                        borderRadius: "100%",
                        color: "rgb(255, 255, 255)",
                        // boxShadow: "#a39797 0px -50px 36px -28px inset",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="/assets/media/user.png"
                        style={{ width: "26px" }}
                      />
                    </div>
                    // <img
                    //   src={`${PhotoUrl}${
                    //     JSON.parse(contextValue?.data?.user_data)?.photo
                    //   }`}
                    //   alt="Profile"
                    // />
                  )}
                </div>

                <div className="ms-2">
                  <span className="text-white text-hover-primary fs-6 fw-bold lh-1">
                    {JSON.parse(contextValue?.data?.user_data)?.name}
                  </span>

                  <span className="text-white-50 fw-semibold d-block fs-7 lh-1 mt-1">
                    {JSON.parse(contextValue?.data?.user_data)?.email}
                  </span>
                </div>
              </div>

              <div className="ms-1 custom-dropdown">
                <div
                  className="btn btn-sm btn-icon btn-active-color-primary position-relative me-n2"
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-overflow="true"
                  data-kt-menu-placement="top-end"
                >
                  {/* <i className="ki-outline ki-setting-2 fs-1 text-gray-800" /> */}
                  <Icon width={24} icon="hugeicons:account-setting-01" />
                </div>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
