// import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/product-details",
    icon: (
      <span class="menu-icon">
        <i
          class="ki-outline ki-cube-2  fs-1"
          // style={{ color: "#FFA500" }}
        ></i>
      </span>
    ),
    label: GetLinks("/product-details", "Product Details"),
    admin: true,
  },
];
