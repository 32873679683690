import { lazy } from "react";
import ManageUser from "../modules/pages/admin/manage-user/manage-user";
import EmailDefination from "../modules/pages/admin/email-definations";
import CouponCodes from "../modules/pages/admin/coupon-codes";

import SellerActivity from "../modules/pages/admin/seller-activity";
import AdDefinitions from "../modules/pages/admin/ad-defination";
export default [
  {
    path: "/manage-user",
    component: <ManageUser />,
    exact: true,
  },
  {
    path: "/email-definations",
    component: <EmailDefination />,
    exact: true,
  },
  {
    path: "/coupon-codes",
    component: <CouponCodes />,
    exact: true,
  },
  {
    path: "/ad-defination",
    component: <AdDefinitions />,
    exact: true,
  },
  {
    path: "/seller-activity",
    component: <SellerActivity />,
    exact: true,
  },
];
