import { Checkbox, Input, Radio, Select, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { DisplayNotification } from "../../../../config";
import { MakeApiCall } from "../../../../apis/make-api-call";
export default function ({ show, onHide, cred, contextValue }) {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);
  const [selectedSelllerName, setSelectedSelllerName] = useState(null);

  useEffect(() => {
    GetMarketplaceIds();
  }, []);

  const GetMarketplaceIds = async () => {
    const response = await MakeApiCall(
      `marketplaces/get-marketplace-by-region`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data);
      setLoading(false);
      DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Login with amazon</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {" "}
            <div className="col-12 mb-5">
              <label className="fw-bold fs-5 mb-1">Seller Name</label>
              <Input
                style={{ width: "100%" }}
                size="large"
                placeholder="Enter Seller Name"
                value={selectedSelllerName}
                onChange={(e) => {
                  setSelectedSelllerName(e.target.value);
                }}
              />
            </div>
            <div className="col-12 ">
              <label className="fw-bold fs-5 mb-1">Marketplace</label>
              <Select
                style={{ width: "100%" }}
                size="large"
                placeholder="Select Marketplace"
                allowClear
                options={list?.map((ml, i) => {
                  return {
                    label: <span>{ml?.name}</span>,
                    title: `${ml?.name}`,
                    options: Object.entries(ml?.marketplace_list)?.map((d) => {
                      return {
                        label: (
                          <>
                            <img
                              src={`/assets/media/domainImage/${d?.[0]}.png`}
                              style={{ height: "20px" }}
                              loading="lazy"
                              className="me-3"
                              onError={(e) => {
                                e.target.src =
                                  "/assets/media/domainImage/red-flag.png";
                              }}
                            />
                            {d?.[1]}
                          </>
                        ),
                        value: d?.[0],
                      };
                    }),
                  };
                })}
                value={selectedMarketplace}
                onChange={(e) => {
                  setSelectedMarketplace(e);
                }}
                loading={loading}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            class="btn btn-light-danger fs-7"
            onClick={onHide}
          >
            Close
          </button>
          {!selectedMarketplace ? (
            <button
              className="btn btn-warning fs-7 fw-bolder text-dark"
              disabled={!selectedMarketplace}
            >
              <i className="fab fa-amazon text-dark fs-2" /> Login with Amazon
            </button>
          ) : (
            <button
              onClick={() => {
                window.onAmazonLoginReady = function () {
                  window.amazon.Login.setClientId(cred?.advertising_client_id);
                };
                let a = document.createElement("script");
                a.type = "text/javascript";
                a.async = true;
                a.id = "amazon-login-sdk";
                a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
                const getRoot = document.getElementById("amazon-root");
                getRoot.appendChild(a);
                setTimeout(() => {
                  let options = {};
                  options.scope = "advertising::campaign_management";
                  options.response_type = "code";
                  window.amazon.Login.authorize(
                    options,
                    `${
                      cred?.advertising_return_url
                    }?marketplace_id=${selectedMarketplace}&seller_name=${
                      selectedSelllerName || ""
                    }`
                  );
                }, 2000);
              }}
              disabled={!selectedMarketplace || !selectedSelllerName}
              className="btn btn-warning fs-7 fw-bolder text-dark"
            >
              <i className="fab fa-amazon text-dark fs-2" /> Login with Amazon
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
