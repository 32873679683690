import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../../../commonContext";
import {
  DisplayNotification,
  PhotoUrl,
  configMarketplaceObj,
} from "../../../../config";
import { Select, notification } from "antd";
import { MakeApiCall } from "../../../../apis/make-api-call";

const Profile = () => {
  const contextValue = useContext(GlobalContext);
  const [isUpdatePassword, setIsUpdatePassword] = useState(false);
  const [isMarketplaceLoading, setIsMarketplaceLoading] = useState(false);
  const [isUpdateMarketplace, setIsUpdateMarketplace] = useState(false);
  const [selectedMarketplace, setSelectedMarketplace] = useState(
    contextValue?.data?.primary_marketplace || null
  );

  const UpdatePrimaryMarketplace = async (data) => {
    const response = await MakeApiCall(
      `user/default-marketplace`,
      "put",
      data,
      true
    );
    if (response?.status) {
      notification.destroy();
      DisplayNotification("success", "Success", response?.message);
      contextValue?.updateCommonGlobalVal({
        primary_marketplace: response?.data?.default_marketplace || null,
      });
      localStorage.setItem(
        "primary_marketplace",
        response?.data?.default_marketplace || null
      );
      setSelectedMarketplace(response?.data?.default_marketplace || null);
      setIsMarketplaceLoading(false);
      setIsUpdateMarketplace(false);
    } else {
      notification.destroy();
      DisplayNotification("error", "Error", response?.message);
      setSelectedMarketplace(contextValue?.data?.primary_marketplace || null);
      setIsMarketplaceLoading(false);
      setIsUpdateMarketplace(false);
    }
  };

  return (
    <>
      <div className="row gy-7 g-xl-7">
        {/*begin::Col*/}
        <div className="col-xl-6 ">
          {/*begin::Basic info*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/*begin::Card header*/}
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_profile_details"
              aria-expanded="true"
              aria-controls="kt_account_profile_details"
            >
              {/*begin::Card title*/}
              <div className="card-title m-0">
                <h3 className="fw-bold m-0 gotham_black">Profile Details</h3>
              </div>
              {/*end::Card title*/}
            </div>
            {/*begin::Card header*/}
            {/*begin::Content*/}
            <div
              id="kt_account_settings_profile_details"
              className="collapse show"
            >
              {/*begin::Form*/}
              <div>
                {/*begin::Card body*/}
                <div className="card-body border-top border-top-dashed p-9">
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-bold fs-6 got">
                      Avatar
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                      {/*begin::Image input*/}

                      <div
                        className="cursor-pointer symbol symbol-75px symbol-md-90px"
                        style={{ border: "1px solid #3699ff", padding: 5 }}
                      >
                        {JSON.parse(contextValue?.data?.user_data)?.photo ? (
                          <img
                            src={`${PhotoUrl}${
                              JSON.parse(contextValue?.data?.user_data)?.photo
                            }`}
                            alt="Profile"
                          />
                        ) : (
                          <div
                            style={{
                              height: "50px",
                              width: "50px",
                              background: "#3699ff",
                              borderRadius: "30px",
                              color: "rgb(255, 255, 255)",
                              boxShadow: "#a39797 0px -50px 36px -28px inset",
                            }}
                            className="d-flex justify-content-center align-items-center"
                          >
                            <img
                              src="/assets/media/user.png"
                              style={{ width: "38px" }}
                            />
                          </div>
                        )}
                      </div>
                      {/*end::Image input*/}
                      {/*begin::Hint*/}
                      <div className="form-text mt-5">
                        Allowed file types: png, jpg, jpeg.
                      </div>
                      {/*end::Hint*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Full Name
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8">
                      {/*begin::Row*/}
                      <div className="row">
                        {/*begin::Col*/}
                        <div className="col-lg-12 fv-row">
                          <input
                            type="text"
                            name="fname"
                            className="form-control  mb-3 mb-lg-0"
                            placeholder="First name"
                            value={
                              JSON.parse(contextValue?.data?.user_data)?.name
                            }
                          />
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}

                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      Email Address
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="text"
                        name="company"
                        className="form-control "
                        placeholder="Email"
                        value={JSON.parse(contextValue?.data?.user_data)?.email}
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                  {/*begin::Input group*/}
                  <div className="row mb-6">
                    {/*begin::Label*/}
                    <label className="col-lg-4 col-form-label fw-bold fs-6">
                      <span className="required">Contact Phone</span>
                      {/* <i
                        className="fas fa-exclamation-circle ms-1 fs-7"
                        data-bs-toggle="tooltip"
                        title="Phone number must be active"
                      /> */}
                    </label>
                    {/*end::Label*/}
                    {/*begin::Col*/}
                    <div className="col-lg-8 fv-row">
                      <input
                        type="tel"
                        name="phone"
                        className="form-control "
                        placeholder="Phone number"
                        value={
                          JSON.parse(contextValue?.data?.user_data)?.contact_no
                        }
                      />
                    </div>
                    {/*end::Col*/}
                  </div>
                  {/*end::Input group*/}
                </div>
                {/*end::Card body*/}
                {/*begin::Actions*/}
                <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                  {/* <button
                    type="reset"
                    className="btn btn-light btn-active-light-primary me-2 fs-7"
                  >
                    Discard
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary  fs-7"
                    id="kt_account_profile_details_submit"
                  >
                    Save Changes
                  </button> */}
                </div>
                {/*end::Actions*/}
              </div>
              {/*end::Form*/}
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Basic info*/}
        </div>
        {/*end::Col*/}
        <div className="col-xl-6 ">
          {/*begin::Sign-in Method*/}
          <div className="card card-flush h-lg-100 mb-5 mb-xl-5">
            {/* <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_signin_method"
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0 gotham_black">Sign-in Method</h3>
              </div>
            </div> */}
            <div className="card-body">
              <div className="d-flex flex-center flex-column pb-5">
                <div
                  className="symbol symbol-50px symbol-md-70px"
                  style={{ border: "1px solid #3699ff", padding: 5 }}
                >
                  {JSON.parse(contextValue?.data?.user_data)?.photo ? (
                    <img
                      src={`${PhotoUrl}${
                        JSON.parse(contextValue?.data?.user_data)?.photo
                      }`}
                      alt="Profile"
                    />
                  ) : (
                    <div
                      style={{
                        height: "50px",
                        width: "50px",
                        background: "#3699ff",
                        borderRadius: "30px",
                        color: "rgb(255, 255, 255)",
                        boxShadow: "#a39797 0px -50px 36px -28px inset",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        src="/assets/media/user.png"
                        style={{ width: "38px" }}
                      />
                    </div>
                  )}
                </div>
                <span className="fs-3 text-gray-800 text-hover-primary fw-bold mb-3 mt-4">
                  {JSON.parse(contextValue?.data?.user_data)?.name}
                </span>
              </div>
              <div
                id="kt_account_settings_signin_method"
                className="collapse show"
              >
                <div className="card-body border-top border-top-dashed p-9">
                  <div className="d-flex flex-wrap align-items-center">
                    {/*begin::Label*/}
                    <div id="kt_signin_password" className>
                      <div className="fs-7 text-primary fw-bold mb-1">
                        Primary Marketplace
                      </div>
                      <div>
                        {isUpdateMarketplace && (
                          <Select
                            style={{ width: "200px" }}
                            options={Object.entries(configMarketplaceObj)?.map(
                              (d) => {
                                return {
                                  label: (
                                    <>
                                      <img
                                        src={`/assets/media/domainImage/${d?.[0]}.png`}
                                        style={{ height: "20px" }}
                                        loading="lazy"
                                        className="me-3"
                                        onError={(e) => {
                                          e.target.src =
                                            "/assets/media/domainImage/red-flag.png";
                                        }}
                                      />
                                      {d?.[1]}
                                    </>
                                  ),
                                  value: d?.[0],
                                };
                              }
                            )}
                            value={selectedMarketplace}
                            onChange={(e) => {
                              setIsMarketplaceLoading(true);
                              UpdatePrimaryMarketplace({
                                marketplace_id: e,
                              });
                            }}
                            loading={isMarketplaceLoading}
                            disabled={isMarketplaceLoading}
                          />
                        )}
                        {!isUpdateMarketplace && (
                          <div className="d-flex align-items-center">
                            {" "}
                            <img
                              src={`/assets/media/domainImage/${contextValue?.data?.primary_marketplace}.png`}
                              style={{ height: "25px" }}
                              loading="lazy"
                              className="me-3"
                              onError={(e) => {
                                e.target.src =
                                  "/assets/media/domainImage/red-flag.png";
                              }}
                            />{" "}
                            <div className="fw-semibold fs-6 text-gray-800">
                              <label className="fw-bold">
                                {
                                  configMarketplaceObj[
                                    contextValue?.data?.primary_marketplace
                                  ]
                                }
                              </label>
                              &nbsp;-&nbsp;
                              <label className="text-muted">
                                {contextValue?.data?.primary_marketplace}
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Edit*/}

                    {/*end::Edit*/}
                    {/*begin::Action*/}
                    {!isUpdateMarketplace ? (
                      <div className="ms-auto">
                        <button
                          className="btn btn-light btn-active-light-primary fs-7"
                          onClick={() => {
                            setIsUpdateMarketplace(true);
                          }}
                        >
                          Update Marketplace
                        </button>
                      </div>
                    ) : (
                      <div className="ms-auto">
                        <button
                          className="btn btn-light btn-active-light-danger fs-7"
                          onClick={() => {
                            setIsUpdateMarketplace(false);
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    )}

                    {/*end::Action*/}
                  </div>

                  <div className="separator separator-dashed my-6" />
                  <div className="d-flex flex-wrap align-items-end">
                    <div id="kt_signin_email">
                      <div className="fs-7 text-primary fw-bold mb-1">
                        Email Address
                      </div>
                      <div className="fw-semibold fs-6 text-gray-800">
                        {JSON.parse(contextValue?.data?.user_data)?.email}
                      </div>
                    </div>
                  </div>

                  <div className="separator separator-dashed my-6" />

                  <div className="d-flex flex-wrap align-items-end mb-10">
                    {/*begin::Label*/}
                    <div id="kt_signin_password" className>
                      <div className="fs-7 text-primary fw-bold mb-1">
                        Password
                      </div>
                      <div className="fw-semibold fs-6 text-gray-800">
                        ************
                      </div>
                    </div>
                    {/*end::Label*/}
                    {/*begin::Edit*/}
                    {isUpdatePassword && (
                      <div className="flex-row-fluid d-block">
                        {/*begin::Form*/}
                        <div className="">
                          <div className="row mb-1">
                            <div className="col-lg-4">
                              <div className="fv-row mb-0 fv-plugins-icon-container">
                                <label
                                  htmlFor="currentpassword"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  Current Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg "
                                  name="currentpassword"
                                  id="currentpassword"
                                />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="fv-row mb-0 fv-plugins-icon-container">
                                <label
                                  htmlFor="newpassword"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  New Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg "
                                  name="newpassword"
                                  id="newpassword"
                                />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="fv-row mb-0 fv-plugins-icon-container">
                                <label
                                  htmlFor="confirmpassword"
                                  className="form-label fs-6 fw-bold mb-3"
                                >
                                  Confirm New Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control form-control-lg "
                                  name="confirmpassword"
                                  id="confirmpassword"
                                />
                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" />
                              </div>
                            </div>
                          </div>
                          <div className="form-text mb-5">
                            Password must be at least 8 character and contain
                            symbols
                          </div>
                          <div className="d-flex">
                            <button
                              type="button"
                              className="btn btn-primary me-2 px-6 fs-7"
                              onClick={() => {
                                setIsUpdatePassword(false);
                              }}
                            >
                              Update Password
                            </button>
                            <button
                              type="button"
                              className="btn btn-light btn-active-light-primary px-6 fs-7"
                              onClick={() => {
                                setIsUpdatePassword(false);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                        {/*end::Form*/}
                      </div>
                    )}

                    {/*end::Edit*/}
                    {/*begin::Action*/}
                    {!isUpdatePassword && (
                      <div className="ms-auto">
                        <button
                          className="btn btn-light btn-active-light-primary fs-7"
                          onClick={() => {
                            setIsUpdatePassword(true);
                          }}
                        >
                          Update Password
                        </button>
                      </div>
                    )}

                    {/*end::Action*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
