import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Tabs, message } from "antd";
import Profile from "./lib/profile";
import SpApi from "./lib/sp-api";
import AdsApi from "./lib/ads-api";
import { useLocation, useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis/make-api-call";
import { DisplayNotification } from "../../../config";

const Setting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [appConfig, setAppConfig] = useState({});
  const [selectedTabs, setSelectedTabs] = useState(
    location?.pathname?.includes("callback-sp")
      ? "2"
      : location?.pathname?.includes("callback-ads")
      ? "3"
      : "1"
  );

  useEffect(() => {
    setSelectedTabs(
      location?.pathname?.includes("callback-sp")
        ? "2"
        : location?.pathname?.includes("callback-ads")
        ? "3"
        : // : location?.pathname?.includes("seller-profile")
          // ? "3"
          // : location?.pathname?.includes("reset-password")
          // ? "2"
          "1"
    );
  }, [location]);

  // useEffect(() => {
  //   setLoading(true);
  //   const type = selectedTabs === "2" ? "SP-API" : "Advertising-API";
  //   getList(type);
  // }, []);

  const getList = async (type) => {
    const response = await MakeApiCall(
      `user-credentials?credential_type=${type}`,
      "get",
      null,
      true
    );
    if (response?.status) {
      setList(response?.data?.list);
      setAppConfig(response?.data?.app_config);
      setLoading(false);
      // response?.data?.list?.length > 0 &&
      //   DisplayNotification("success", "Success", response?.message);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const modules = {
    Profile: <Profile />,
    // "Reset Password": <ResetPassword />,
    // "Seller Profile": <SellerProfile />,
    "Amazon SP API Credentials": (
      <SpApi
        loading={loading}
        list={list}
        getList={(type) => {
          setList([]);
          setLoading(true);
          getList(type);
        }}
        setLoading={setLoading}
        cred={appConfig}
      />
    ),
    "Amazon Advertising Credentials": (
      <AdsApi
        loading={loading}
        list={list}
        getList={(type) => {
          setList([]);
          setLoading(true);
          getList(type);
        }}
        setLoading={setLoading}
        cred={appConfig}
      />
    ),
  };

  const menus = {
    1: "setting",
    // 2: "reset-password",
    // 3: "seller-profile",
    2: "callback-sp",
    3: "callback-ads",
  };

  return (
    <Wrapper id="kt_content_container" className="container-fluid">
      <Tabs
        type="card"
        onChange={(e) => {
          setSelectedTabs(e);
          navigate(`/${menus?.[e]}`, { replace: true });
        }}
        activeKey={selectedTabs}
        items={[
          "Profile",
          // "Reset Password",
          // "Seller Profile",
          "Amazon SP API Credentials",
          "Amazon Advertising Credentials",
        ].map((_, i) => {
          const id = String(i + 1);
          return {
            label: _,
            key: id,
            children: modules?.[_],
          };
        })}
      />
    </Wrapper>
  );
};

export default Setting;
