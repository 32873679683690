import React, { useState } from "react";
import { Wrapper, StyledCard } from "./style";
import { Col, Row, Segmented, Statistic, Table, Typography } from "antd";
import { Icon } from "@iconify/react";
import CountUp from "react-countup";
import HeaderCustom from "../../../../component/header-custom";
import dayjs from "dayjs";
import RangePickerESF from "../../../../component/range-picker";
import { DateFormat } from "../../../../config";
const { Text } = Typography;
const ReimbursementDashobard = () => {
  const [all_filter, set_all_filters] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs().add(-2, "d"),
  });

  const data = [
    {
      title: "Total Reimbursed",
      icon: "fluent-mdl2:product",
      price: "1540.00",
      cases: "200",
      quantity: "2000",
      bgColor: "#b1d1f5",
      iconColor: "#629cdd",
    },
    {
      title: "In Progress Cases",
      icon: "lets-icons:progress-light",
      price: "6777.40",
      cases: "330",
      quantity: "20",
      bgColor: "#c6c4ff",
      iconColor: "#5e5c90",
    },
    {
      title: "Eligible Claims",
      icon: "lsicon:submit-outline",
      price: "100.40",
      cases: "34",
      quantity: "500",
      bgColor: "#cbe9f1",
      iconColor: "#7bcadf",
    },
  ];

  const columns = [
    { title: "Type", dataIndex: "type", key: "type" },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${amount}`,
    },
    { title: "Quantity", dataIndex: "quantity", key: "quantity" },
    { title: "No. of Cases", dataIndex: "cases", key: "cases" },
  ];
  const tableData = [
    {
      key: "1",
      type: "Damaged Warehouse",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
    {
      key: "2",
      type: "Lost Warehouse",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
    {
      key: "3",
      type: "Disposed Of",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
    {
      key: "4",
      type: "Lost Inbound",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
    {
      key: "5",
      type: "Lost Outbound",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
    {
      key: "6",
      type: "Order Discrepancy",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
    {
      key: "7",
      type: "Damaged Returns",
      amount: 0.0,
      quantity: 0,
      no_of_cases: 0,
    },
  ];

  const segmentOptions = [
    "Total Reimbursed",
    "In Progress Cases",
    "To Be Submitted",
    "Reconciled Events",
  ];

  const [selectedSegment, setSelectedSegment] = useState(segmentOptions[0]);

  const handleSegmentChange = (value) => {
    setSelectedSegment(value);
  };

  return (
    <div className="container-fluid" id="kt_content_container">
      {" "}
      <Wrapper>
        <div className="row px-0 mx-0">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <h3 class="card-title align-items-start flex-column"></h3>
                </div>
                <div className="card-toolbar">
                  {" "}
                  <div className="position-relative">
                    <RangePickerESF
                      className="ant_common_input"
                      id="ivcsr_date_picker"
                      size="large"
                      allowClear={false}
                      style={{ width: "290px" }}
                      format={DateFormat}
                      value={[all_filter?.start_date, all_filter?.end_date]}
                      // getPopupContainer={(triggerNode) => triggerNode.parentNode}
                      onChange={(e) => {
                        const filters_ = all_filter;
                        filters_.start_date = e?.[0];
                        filters_.end_date = e?.[1];
                        set_all_filters({ ...filters_ });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed"></div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <Row gutter={[16, 16]}>
                      {data.map((item, i) => (
                        <Col key={i} xs={24} sm={12} md={12} lg={8}>
                          <StyledCard
                            className="dashboard-card"
                            bgColor={item.bgColor}
                          >
                            <div className="card-content">
                              <div className="icon-container">
                                <Icon
                                  icon={item.icon}
                                  className="card-icon"
                                  color={item?.iconColor}
                                />
                                <span className="fs-2">{item.title}</span>
                              </div>
                              <div>
                                <Statistic
                                  className="card-price"
                                  valueRender={() => (
                                    <CountUp
                                      start={0}
                                      end={item.price}
                                      duration={4}
                                      prefix="$"
                                    />
                                  )}
                                />
                              </div>
                              <div className="card-info">
                                <Text>No. of Cases: {item.cases}</Text>
                                <Text>Quantity: {item.quantity}</Text>
                              </div>
                            </div>
                          </StyledCard>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="row px-0 mx-0">
          <div className="col-6">
            {/* <div className="row">
              <div className="col-12 px-3">
                <HeaderCustom
                  style={{ width: "100%" }}
                  className={"w-100 mx-0"}
                >
                  <div className="d-flex align-items-center justify-content-between ">
                    <div className=" align-items-start flex-column me-3 p-4">
                      <Segmented
                        options={segmentOptions}
                        onChange={handleSegmentChange}
                        size="middle"
                      />
                    </div>
                    <div className="d-flex flex-wrap justify-content-end align-items-center gap-4 p-4"></div>
                  </div>
                </HeaderCustom>
              </div>
            </div> */}
            <div className="card card-flush h-xl-100 mb-5 mb-xl-8">
              <div className="card-header p-4 border-0">
                <Segmented
                  options={segmentOptions}
                  onChange={handleSegmentChange}
                  size="middle"
                />

                <div className="card-toolbar"> </div>
              </div>
              <div className="separator separator-dashed my-5"></div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <Table
                      columns={columns}
                      dataSource={tableData}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card card-flush h-xl-100 mb-5 mb-xl-8">
              <div className="card-header p-4 border-0">
                <div className="d-flex flex-stack flex-wrap gap-4">
                  <h3 class="card-title align-items-start">
                    {" "}
                    <span className="fw-bold me-2">URGENT:</span> Action
                    Required
                  </h3>
                </div>
                <div className="card-toolbar"> </div>
              </div>
              <div className="separator separator-dashed my-5"></div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <Table
                      columns={columns}
                      dataSource={[]}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default ReimbursementDashobard;
