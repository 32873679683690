import React, { useState, useEffect, useLayoutEffect } from "react";
import PageRouter from "../../router";
import Sidebar from "../layout/sidebar";
import Header from "../layout/header";
import Footer from "../layout/footer";
import { PageJsWrapper } from "./style";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const PageIndex = () => {
  const [isTokenChecking, setIsTokenChecking] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarToggle, setSidebarToggle] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setTimeout(() => {
        setIsTokenChecking(false);
        const path =
          location?.pathname == "/"
            ? localStorage.getItem("user_type") == 1
              ? `/manage-user`
              : "/dashboard"
            : `${location?.pathname}${location?.search}`;
        navigate(`${path}`, { replace: true });
      }, 3500);
    } else {
      navigate("/login");
    }
  }, []); // Empty dependency array ensures this effect runs only once

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 992) {
        setSidebarToggle(true);
      } else {
        setSidebarToggle(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (sidebarToggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [sidebarToggle]);

  return (
    <>
      {isTokenChecking ? (
        <PageJsWrapper
          style={{
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              // margin: "auto",
              // position: "absolute",
              // inset: 0,
              // width: "fit-content",
              // height: "fit-content",
              // display: "grid",
              // justifyItems: "center",
            }}
          >
            <svg>
              <text
                x="50%"
                y="50%"
                dy=".35em"
                text-anchor="middle"
                style={{
                  fontSize: window.innerWidth >= 992 ? "100px" : "70px",
                }}
              >
                AalaCart
              </text>
            </svg>
          </div>
        </PageJsWrapper>
      ) : (
        <>
          {/* {sidebarToggle && (
            <div
              className="toggle_sidebar_div"
              onClick={() => setSidebarToggle(false)}
            />
          )} */}
          <div className="page d-flex flex-row flex-column-fluid">
            <Sidebar
              sidebarToggle={sidebarToggle}
              setSidebarToggle={setSidebarToggle}
            />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <Header
                sidebarToggle={sidebarToggle}
                setSidebarToggle={setSidebarToggle}
              />{" "}
              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <PageRouter />
              </div>
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PageIndex;
