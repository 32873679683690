import { Spin, message } from "antd";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MakeApiCall } from "../../../../apis/make-api-call";

export const UserVerify = () => {
  const location = useLocation();

  const CheckToken = async (token) => {
    const [token_, email] = token?.split("token=")?.[1]?.split("&email=");

    message.destroy();

    const response = await MakeApiCall(
      `user-verify?token=${token_}&email=${encodeURIComponent(email)}`,
      "get",
      null
    );

    if (response?.status) {
      message.destroy();

      if (response?.message?.page_redirect === "register-step2") {
        window.location.replace(`sign-up?email=${email}`);
      } else {
        window.location.replace("/login");
      }
    } else {
      message.destroy();
    }
  };

  useEffect(() => {
    if (location?.pathname === "/user-verify" && location.search) {
      CheckToken(location.search);
    }
  }, [location?.pathname]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spin tip="Loading..." size="small">
        <div className="w-100px" />
      </Spin>
    </div>
  );
};
