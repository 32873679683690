import ReimbursementManager from "../modules/pages/reimbursement/manager/manager";
import ReimbursementDashobard from "../modules/pages/reimbursement/reimbursement-dashboard/reimbursement-dashobard";
import ReimbursementDetails from "../modules/pages/reimbursement/reimbursement-details/reimbursement-details";

export default [
  {
    path: "/reimbursement-dashboard",
    component: <ReimbursementDashobard />,
    exact: true,
  },
  {
    path: "/details",
    component: <ReimbursementDetails />,
    exact: true,
  },
  {
    path: "/manager",
    component: <ReimbursementManager />,
    exact: true,
  },
];
