import React, {useState} from 'react';
import { Button, Card, Col, Row, Switch, Select, Table, Drawer, Collapse, Avatar, Typography} from 'antd';
import RangePickerESF from '../../../../component/range-picker';
import { Link } from 'react-router-dom';
import PerformanceChart from './lib/performance-chart';
import moment from 'moment';


const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

function Dashboard() {

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };

      const onSearch = (value) => {
        console.log('search:', value);
      };


  
  // Define columns for the table
  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (text) => <img src={text} alt="Product" style={{ width: 50, height: 50 }} />, // Renders image
    },
    {
      title: 'ASIN',
      dataIndex: 'asin',
      key: 'asin',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
    },
    {
      title: 'Sales',
      dataIndex: 'sales',
      key: 'sales',
    },
    {
      title: 'Est. Profit',
      dataIndex: 'estProfit',
      key: 'estProfit',
    },
    {
      title: 'Units Sold',
      dataIndex: 'unitsSold',
      key: 'unitsSold',
    },
    {
      title: 'PPC Units Sold',
      dataIndex: 'ppcUnitsSold',
      key: 'ppcUnitsSold',
    },
    {
      title: 'Organic Units Sold',
      dataIndex: 'organicUnitsSold',
      key: 'organicUnitsSold',
    },
    {
      title: 'Organic Sales',
      dataIndex: 'organicSales',
      key: 'organicSales',
    },
    {
      title: 'PPC Sales',
      dataIndex: 'ppcSales',
      key: 'ppcSales',
    },
    {
      title: 'Ads Spent',
      dataIndex: 'adsSpent',
      key: 'adsSpent',
    },
    {
      title: 'Amazon Fees',
      dataIndex: 'amazonFees',
      key: 'amazonFees',
    },
    {
      title: 'COGS',
      dataIndex: 'cogs',
      key: 'cogs',
    },
    {
      title: 'General Costs',
      dataIndex: 'generalCosts',
      key: 'generalCosts',
    },
    {
      title: 'Refund Cost',
      dataIndex: 'refundCost',
      key: 'refundCost',
    },
    {
      title: 'Refund Unit',
      dataIndex: 'refundUnit',
      key: 'refundUnit',
    },
  ];

  const data = [
    {
      key: '1',
      product:'https://m.media-amazon.com/images/I/41X75DlvJnL._SL75_.jpg',
      asin: 'B0CFG2K7YY',
      sku: 'A1-TRPD-US',
      sales: 4926.02,
      estProfit: 1546.87,
      unitsSold: 55,
      ppcUnitsSold: 26,
      organicUnitsSold: 29,
      organicSales: 2627.68,
      ppcSales: 2298.34,
      adsSpent: -841.37,
      amazonFees: -1418.04,
      cogs: 0.00,
      generalCosts: 0.00,
      refundCost: -830.47,
      refundUnit: 12,
    },
    {
      key: '2',
      product:'https://m.media-amazon.com/images/I/41X75DlvJnL._SL75_.jpg',
      asin: 'B0838L98WD',
      sku: 'G1-73IV-2QM9',
      sales: 1432.60,
      estProfit: 72.28,
      unitsSold: 42,
      ppcUnitsSold: 42,
      organicUnitsSold: 0,
      organicSales: -1087.90,
      ppcSales: 2520.50,
      adsSpent: -762.11,
      amazonFees: -509.34,
      cogs: 0.00,
      generalCosts: 0.00,
      refundCost: -47.44,
      refundUnit: 1,
    },
  ];


  // Sample data
const products = [
  {
    id: '1',
    name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    sku: 'SKU00001',
    image: 'https://via.placeholder.com/40', // replace with actual image URLs
  },
  {
    id: '2',
    name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    sku: 'SKU00002',
    image: 'https://via.placeholder.com/40',
  },
  {
    id: '3',
    name: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    sku: 'SKU00003',
    image: 'https://via.placeholder.com/40',
  },
];

  return (
    <>
        {/*  */}
        <div className="container-fluid fadeInLeftAllPage" id="kt_content_container" >
            <div className="row gy-5 g-xl-5 mb-5 px-0 mx-0">
                <div className='col-md-12'>
                    <Card title="Overview" extra={<div>Compare <Switch className='ms-3' defaultChecked onChange={onChange} /></div>}>
                        <Row gutter={16}>
                            <Col span={6} className=''>
                                <Card title="Today" extra={
                                  <div className='position-relative'>
                                    <Button className='btn btn-icon d-flex w-auto'><i className='ki-outline ki-calendar-add fs-1 text-dark'></i></Button>
                                  </div>
                                  }>
                                    <Row  gutter={[16, 16]}>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Sales</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Units Sold</p>
                                            <h4>10</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Costs</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Est. Profit</p>
                                            <h4>10</h4>
                                        </Col>
                                    </Row>
                                    <Button type="primary" onClick={showDrawer} size='large' className='mt-4 fs-5 d-flex justify-content-center' block>Breakdown <i className='ki-outline ki-arrow-right fs-1 text-light'></i></Button>
                                </Card>
                            </Col>
                            <Col span={6} className=''>
                                <Card title="Yesterday" extra={<Link to="" className='d-flex'><i className='ki-outline ki-calendar-add fs-1 text-dark'></i></Link>}>
                                    <Row  gutter={[16, 16]}>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Sales</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Units Sold</p>
                                            <h4>10</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Costs</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Est. Profit</p>
                                            <h4>10</h4>
                                        </Col>
                                    </Row>
                                    <Button type="primary" onClick={showDrawer} size='large' className='mt-4 fs-5 d-flex justify-content-center' block>Breakdown <i className='ki-outline ki-arrow-right fs-1 text-light'></i></Button>
                                </Card>
                            </Col>
                            <Col span={6} className=''>
                                <Card title="Last 7 days" extra={<Link to="" className='d-flex'><i className='ki-outline ki-calendar-add fs-1 text-dark'></i></Link>}>
                                    <Row  gutter={[16, 16]}>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Sales</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Units Sold</p>
                                            <h4>10</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Costs</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Est. Profit</p>
                                            <h4>10</h4>
                                        </Col>
                                    </Row>
                                    <Button type="primary" onClick={showDrawer} size='large' className='mt-4 fs-5 d-flex justify-content-center' block>Breakdown <i className='ki-outline ki-arrow-right fs-1 text-light'></i></Button>
                                </Card>
                            </Col>
                            <Col span={6} className=''>
                                <Card title="Last 30 days" extra={<Link to="" className='d-flex'><i className='ki-outline ki-calendar-add fs-1 text-dark'></i></Link>}>
                                    <Row  gutter={[16, 16]}>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Sales</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Units Sold</p>
                                            <h4>10</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Costs</p>
                                            <h4>$839.00</h4>
                                        </Col>
                                        <Col span={12}>
                                            <p className='mb-1 text-gray-500'>Est. Profit</p>
                                            <h4>10</h4>
                                        </Col>
                                    </Row>
                                    <Button type="primary" onClick={showDrawer} size='large' className='mt-4 fs-5 d-flex justify-content-center' block>Breakdown <i className='ki-outline ki-arrow-right fs-1 text-light'></i></Button>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </div>


                <div className='col-md-12'>
                    <PerformanceChart />
                </div>


                <div className='col-md-12'>
                <Card title="Top products" extra={
                    <div className='d-flex flex-row gap-4'>
                        {/* <Select className='bdr' {...sharedProps} options={Options}/> */}
                        <Select
                            showSearch
                            placeholder="Custom KPI"
                            optionFilterProp="label"
                            variant='filled'
                            size='large'
                            onChange={onChange}
                            onSearch={onSearch}
                            defaultValue={['ppc sales','products','unit sold']}
                            className='min-w-200px'
                            mode='multiple'
                            maxTagCount='responsive'
                            options={[
                            {
                                value: 'ppc sales',
                                label: 'PPC Sales',
                            },
                            {
                                value: 'products',
                                label: 'Products',
                            },
                            {
                                value: 'unit sold',
                                label: 'Unit Sold',
                            },
                            ]}
                        />
                        <Select
                        placeholder="Select a product"
                        style={{ width: '100%' }}
                        variant='filled'
                        size='large'
                        optionLabelProp="label"
                        className='min-w-200px'
                         mode='multiple'
                            maxTagCount='responsive'
                      >
                        {products.map((product) => (
                          <Option 
                            key={product.id} 
                            value={product.id} 
                            label={product.name} 
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Avatar src={product.image} size={40} style={{ marginRight: 8 }} />
                              <div>
                                <Text className='text-one' strong>{product.name}</Text>
                                <Text type="secondary">SKU: {product.sku}</Text>
                              </div>
                            </div>
                          </Option>
                        ))}
                      </Select>
                        <RangePickerESF variant='filled' className="w-225px"/>
                    </div>
                    }>

                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{
                            x: 'max-content',
                          }}
                        />
                </Card>
                </div>
            </div>
        </div>


        <Drawer width='450px' title="Breakdown" onClose={onClose} open={open}>
          <div className=' breakdown-drawer'>
          <Row>
        <Col span={12}>
          <Title level={4}>Start Date: 2024-11-07</Title>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Title level={4}>End Date: 2024-11-07</Title>
        </Col>
      </Row>
      
      <Collapse defaultActiveKey={[]}>
        <Panel header="Sales" extra={<span className='fw-bolder'>$319.60</span>} key="1">
          <Row className='sub'>
            <Col span={12}><Text>Organic</Text></Col>
            <Col span={12} style={{ textAlign: 'right' }}><Text>$319.60</Text></Col>
          </Row>
        </Panel>
        <Panel header="Units Sold" extra={<span className='fw-bolder'>3</span>} key="2">
          <Row className='sub'>
            <Col span={12}><Text>Organic unit</Text></Col>
            <Col span={12} style={{ textAlign: 'right' }}><Text>3</Text></Col>
          </Row>
        </Panel>
        <Panel header="FBA Fees" extra={<span className='fw-bolder'>-$113.53</span>} key="3">
          <Row className='sub mb-2'>
            <Col span={12}><Text>Fba Per Unit Fulfillment Fee</Text></Col>
            <Col span={12} style={{ textAlign: 'right' }}><Text>-$65.56</Text></Col>
          </Row>
          <Row className='sub'>
            <Col span={12}><Text>Commission</Text></Col>
            <Col span={12} style={{ textAlign: 'right' }}><Text>-$47.97</Text></Col>
          </Row>
        </Panel>
        <Panel header="PPC Costs" extra={<span className='fw-bolder'>-$1.19</span>} key="4">
          <Row className='sub mb-0'>
            <Col span={16}><Text>Sponsored Brands Video PPC</Text></Col>
            <Col span={8} style={{ textAlign: 'right' }}><Text>-$65.56</Text></Col>
          </Row>
        </Panel>

        <Panel showArrow={false} collapsible='icon'   className='main-item mt-3' header="Total Profit" extra={<span className='fw-bolder'>$204.98</span>} key="5"></Panel>
        {/* <div className='separator separator-dashed my-2'></div> */}
        <Panel className='main-item' header="Profit Margin" extra={<span className='fw-bolder'>64.12%</span>} key="6"></Panel>
      </Collapse>
    
      
          </div>
      </Drawer>
    </>
  )
}

export default Dashboard