import React, { useState, useEffect } from "react";
import { Checkbox, Input, Radio, Select, Skeleton } from "antd";
import { DisplayNotification } from "../../../../../../config";
import NoData from "../../../../../../component/no-data";
import { MakeApiCall } from "../../../../../../apis/make-api-call";
import {
  dummyKeywordTSuggestion,
  dummyProductTSuggestion,
  dummyCategoriesTSuggestion,
} from "../../../../../../config/select-dropdown-list";

const SBManualTargeting = (props) => {
  const {
    all_data,
    negative_keyword_list,
    set_negative_keyword_list,
    all_common_hide_show,
    set_all_common_hide_show,
    selected_product_list,
    manual_sub_targeting_type,
    set_manual_sub_targeting_type,
    selected_keyword_list,
    set_selected_keyword_list,
    selected_individual_product_list,
    set_selected_individual_product_list,
    selected_categroy_list,
    set_selected_categroy_list,
  } = props;

  const [negativeKeyword, setNegativeKeyword] = useState("");

  const [sugg_keyword_list, set_sugg_keyword_list] = useState([]);
  const [keyword_loading, set_keyword_loading] = useState(true);
  const [sugg_product_targeting, set_sugg_product_targeting] = useState([]);
  const [product_targeting_loading, set_product_targeting_loading] =
    useState(true);
  const [sugg_category_targeting, set_sugg_category_targeting] = useState([]);

  const [category_targeting_loading, set_category_targeting_loading] =
    useState(true);
  const [
    selected_individual_product_filter_by,
    set_selected_individual_product_filter_by,
  ] = useState(["Exact"]);
  const [selected_keyword_filterby, set_selected_keyword_filterby] = useState([
    "BROAD",
    "PHRASE",
    "EXACT",
  ]);
  useEffect(() => {
    const asinsList = selected_product_list?.map((d) => {
      return d?.asin1;
    });
    const data = {
      marketplace_id: "ATVPDKIKX0DER",
      bidding_strategy: all_data?.campaign_goal,
      asins: asinsList,
      sort_dimension: "DEFAULT",
    };
    set_keyword_loading(true);
    GetSuggestedKeyword(data);

    const productData = {
      marketplace_id: "ATVPDKIKX0DER",
      asins: asinsList,
    };
    set_product_targeting_loading(true);
    GetProductTargeting(productData);

    set_category_targeting_loading(true);
    GetCategoryTargeting(productData);
  }, []);

  const GetSuggestedKeyword = async (data) => {
    if (dummyKeywordTSuggestion?.status) {
      set_keyword_loading(false);
      const transformedData = dummyKeywordTSuggestion?.data.map((item) => {
        const { bid_info, ...rest } = item;
        return { ...rest, ...bid_info };
      });
      set_sugg_keyword_list(transformedData);
    } else {
      set_keyword_loading(false);
      DisplayNotification("error", "Error", dummyKeywordTSuggestion?.message);
    }
  };
  const GetProductTargeting = async (data) => {
    if (dummyProductTSuggestion?.status) {
      set_product_targeting_loading(false);
      set_sugg_product_targeting(dummyProductTSuggestion?.data);
    } else {
      set_product_targeting_loading(false);
      DisplayNotification("error", "Error", dummyProductTSuggestion?.message);
    }
  };

  const GetCategoryTargeting = async (data) => {
    if (dummyCategoriesTSuggestion?.status) {
      set_category_targeting_loading(false);
      set_sugg_category_targeting(dummyCategoriesTSuggestion?.data);
    } else {
      set_category_targeting_loading(false);
      DisplayNotification(
        "error",
        "Error",
        dummyCategoriesTSuggestion?.message
      );
    }
  };

  return (
    <>
      <div
        className="flex-column current"
        data-kt-stepper-element="content"
        style={{ minHeight: 500 }}
      >
        <div>
          <div className>
            <div className="row g-5 align-items-center">
              <div className="col-md-3">
                <h5 className="mb-0">Manual Targeting:</h5>
              </div>
              <div className="col-md-9">
                <div className="d-flex gap-4 align-items-center">
                  <Radio.Group
                    name="radiogroup"
                    onChange={(e) => {
                      set_manual_sub_targeting_type(e.target.value);
                    }}
                    value={manual_sub_targeting_type}
                  >
                    <Radio value={"keyword_targeting"}>
                      <span className="d-flex flex-column">
                        <span className="fw-bold fs-6">Keyword Targeting</span>
                      </span>
                    </Radio>
                    <Radio value={"product_targeting"}>
                      <span className="d-flex flex-column">
                        <span className="fw-bold fs-6">Product Targeting</span>
                      </span>
                    </Radio>
                  </Radio.Group>
                </div>
              </div>
            </div>
            <div className="row g-5 align-items-center mt-0">
              <div className="col-md-9 ms-auto ">
                <div className="automaticDiv1" style={{ display: "block" }}>
                  <p className="fs-6">
                    Choose keywords to help your products appear in shopper
                    searches.
                  </p>
                </div>
                <div className="manualDiv1" style={{ display: "none" }}>
                  <p className="fs-6">
                    Choose specific products, categories, brands, or other
                    product features to target your ads.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-dashed border-gray-300" />
          {manual_sub_targeting_type === "keyword_targeting" && (
            <div className="automaticDiv1">
              <div className="row gy-5 g-xl-5 mt-0  ">
                <div className="col-xxl-6">
                  <div className="card">
                    <div className="card-header card-header-stretch">
                      <div className="card-title fs-5 m-0">
                        <h3 className="fw-bold m-0">Keywords Targeting</h3>
                      </div>
                      <div className="card-toolbar"></div>
                    </div>
                    <div className="card-body pt-2">
                      <ul className="nav nav-tabs mb-5 nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                        <li
                          className="nav-item"
                          onClick={() => {
                            set_all_common_hide_show({
                              ...all_common_hide_show,
                              manual_keyword_targeting: "suggested",
                            });
                          }}
                        >
                          <span
                            className={`nav-link fw-bold text-gray-800 ${
                              all_common_hide_show?.manual_keyword_targeting ===
                              "suggested"
                                ? "active"
                                : ""
                            }`}
                          >
                            Suggested
                          </span>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            set_all_common_hide_show({
                              ...all_common_hide_show,
                              manual_keyword_targeting: "enter_list",
                            });
                          }}
                        >
                          <span className="nav-link fw-bold text-gray-800">
                            Enter List
                          </span>
                        </li>
                        <li
                          className="nav-item"
                          onClick={() => {
                            set_all_common_hide_show({
                              ...all_common_hide_show,
                              manual_keyword_targeting: "upload",
                            });
                          }}
                        >
                          <span className="nav-link fw-bold text-gray-800">
                            Upload
                          </span>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div
                          className={`tab-pane fade cursor-pointer ${
                            all_common_hide_show?.manual_keyword_targeting ===
                            "suggested"
                              ? "show active"
                              : ""
                          }`}
                        >
                          <div className="mt-0 ">
                            <div className="row align-items-center">
                              <div className="col-md-3">
                                <span className="fw-bolder fs-6">Bid</span>
                              </div>
                              <div className="col-md-9">
                                <div className="d-flex w-100">
                                  <div className="border rounded w-100">
                                    <select
                                      id
                                      className="kt_docs_select2_rich_content form-select form-select-transparent"
                                      name="..."
                                      data-placeholder="..."
                                    >
                                      <option />
                                      <option
                                        value={0}
                                        data-kt-rich-content-subcontent="Use past bidding activity to predict bids that are more likely to win"
                                        selected
                                      >
                                        Suggested Bid
                                      </option>
                                      <option
                                        value={1}
                                        data-kt-rich-content-subcontent="Can customize bids for each keyword"
                                      >
                                        Custom Bid
                                      </option>
                                      <option
                                        value={1}
                                        data-kt-rich-content-subcontent="Links multiple keywords to the same bid value"
                                      >
                                        Default Bid
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center my-7">
                            <div className="col-md-3">
                              <span className="fw-bolder fs-6">Filter by</span>
                            </div>
                            <div className="col-md-9">
                              <div className=" d-flex gap-5 mt-0">
                                <Checkbox.Group
                                  disabled={keyword_loading}
                                  options={[
                                    {
                                      label: "Broad",
                                      value: "BROAD",
                                    },
                                    {
                                      label: "Phrase",
                                      value: "PHRASE",
                                    },
                                    {
                                      label: "Exact",
                                      value: "EXACT",
                                    },
                                  ]}
                                  defaultValue={["Broad"]}
                                  value={selected_keyword_filterby}
                                  onChange={(e) => {
                                    console.log(e, "-e");
                                    if (e?.length > 0) {
                                      set_selected_keyword_filterby(e);
                                    } else {
                                      DisplayNotification(
                                        "info",
                                        "Info",
                                        "Please select at least one filter"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="separator separator-dashed border-gray-300" />
                          <div className="mt-5">
                            <div
                              className="table-responsive"
                              style={{ height: "400px" }}
                            >
                              {keyword_loading ? (
                                <Skeleton />
                              ) : sugg_keyword_list?.length > 0 ? (
                                sugg_keyword_list?.map((d) => {
                                  const isAdd = selected_keyword_list?.filter(
                                    (f) => f.keyword_name === d.keyword
                                  );
                                  // console.log(isAdd, "-isAdd");
                                  return (
                                    <div className="bg-light my-2 p-3">
                                      <div>
                                        <div className=" d-flex align-items-center justify-content-between ">
                                          <div className="fs-7">
                                            Keyword: <b>{d?.keyword}</b>
                                          </div>
                                        </div>
                                        <div className="separator separator-dashed border-gray-300 mt-3" />
                                        <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                                          <div className="min-w-100px ">
                                            Match Type:
                                          </div>
                                          <div className="min-w-70px ">
                                            <b>Broad</b>
                                          </div>
                                          <div className="min-w-70px ">
                                            <b>Phrase</b>
                                          </div>
                                          <div className="min-w-70px ">
                                            <b>Exact</b>
                                          </div>
                                        </div>
                                        <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                                          <div className="min-w-100px ">
                                            Sugg. Bid:
                                          </div>
                                          <div className="min-w-70px d-flex">
                                            {selected_keyword_filterby?.includes(
                                              "BROAD"
                                            ) ? (
                                              <>
                                                <b>
                                                  ${d?.BROAD?.suggested_bid}
                                                </b>{" "}
                                                {!isAdd?.[0]?.broad?.name && (
                                                  <span
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                      const newObj = {
                                                        keyword_name:
                                                          d?.keyword,
                                                        broad: {
                                                          keyword_name:
                                                            d?.keyword,
                                                          name: "BROAD",
                                                          sugg_bid:
                                                            d?.BROAD
                                                              ?.suggested_bid,
                                                          state: "ENABLED",
                                                        },
                                                      };

                                                      set_selected_keyword_list(
                                                        (prev) => {
                                                          const existingKeyword =
                                                            prev?.find(
                                                              (sk) =>
                                                                sk?.keyword_name ===
                                                                d?.keyword
                                                            );
                                                          if (existingKeyword) {
                                                            // Update existing keyword
                                                            return prev.map(
                                                              (sk) =>
                                                                sk?.keyword_name ===
                                                                d?.keyword
                                                                  ? {
                                                                      ...sk,
                                                                      ...newObj,
                                                                    }
                                                                  : sk
                                                            );
                                                          } else {
                                                            // Add new keyword
                                                            return [
                                                              ...prev,
                                                              newObj,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="ki-outline ki-plus text-primary fs-4" />
                                                  </span>
                                                )}
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                          <div className="min-w-70px d-flex">
                                            {selected_keyword_filterby?.includes(
                                              "PHRASE"
                                            ) ? (
                                              <>
                                                {" "}
                                                <b>
                                                  ${d?.PHRASE?.suggested_bid}
                                                </b>{" "}
                                                {!isAdd?.[0]?.phrase?.name && (
                                                  <span
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                      const newObj = {
                                                        keyword_name:
                                                          d?.keyword,
                                                        phrase: {
                                                          keyword_name:
                                                            d?.keyword,
                                                          name: "PHRASE",
                                                          sugg_bid:
                                                            d?.PHRASE
                                                              ?.suggested_bid,
                                                          state: "ENABLED",
                                                        },
                                                      };

                                                      set_selected_keyword_list(
                                                        (prev) => {
                                                          const existingKeyword =
                                                            prev?.find(
                                                              (sk) =>
                                                                sk?.keyword_name ===
                                                                d?.keyword
                                                            );
                                                          if (existingKeyword) {
                                                            // Update existing keyword
                                                            return prev.map(
                                                              (sk) =>
                                                                sk?.keyword_name ===
                                                                d?.keyword
                                                                  ? {
                                                                      ...sk,
                                                                      ...newObj,
                                                                    }
                                                                  : sk
                                                            );
                                                          } else {
                                                            // Add new keyword
                                                            return [
                                                              ...prev,
                                                              newObj,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="ki-outline ki-plus text-primary fs-4" />
                                                  </span>
                                                )}
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                          <div className="min-w-70px d-flex">
                                            {selected_keyword_filterby?.includes(
                                              "EXACT"
                                            ) ? (
                                              <>
                                                <b>
                                                  ${d?.EXACT?.suggested_bid}
                                                </b>{" "}
                                                {!isAdd?.[0]?.exact?.name && (
                                                  <span
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                      const newObj = {
                                                        keyword_name:
                                                          d?.keyword,
                                                        exact: {
                                                          keyword_name:
                                                            d?.keyword,
                                                          name: "EXACT",
                                                          sugg_bid:
                                                            d?.EXACT
                                                              ?.suggested_bid,
                                                          state: "ENABLED",
                                                        },
                                                      };

                                                      set_selected_keyword_list(
                                                        (prev) => {
                                                          const existingKeyword =
                                                            prev?.find(
                                                              (sk) =>
                                                                sk?.keyword_name ===
                                                                d?.keyword
                                                            );
                                                          if (existingKeyword) {
                                                            // Update existing keyword
                                                            return prev.map(
                                                              (sk) =>
                                                                sk?.keyword_name ===
                                                                d?.keyword
                                                                  ? {
                                                                      ...sk,
                                                                      ...newObj,
                                                                    }
                                                                  : sk
                                                            );
                                                          } else {
                                                            // Add new keyword
                                                            return [
                                                              ...prev,
                                                              newObj,
                                                            ];
                                                          }
                                                        }
                                                      );
                                                    }}
                                                  >
                                                    <i className="ki-outline ki-plus text-primary fs-4" />
                                                  </span>
                                                )}
                                              </>
                                            ) : (
                                              "-"
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <NoData type="new" />
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade cursor-pointer ${
                            all_common_hide_show?.manual_keyword_targeting ===
                            "enter_list"
                              ? "show active"
                              : ""
                          }`}
                        >
                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <span className="fw-bolder fs-6">Bid</span>
                            </div>
                            <div className="col-md-9">
                              <div className="d-flex w-100">
                                <div className="border rounded w-100">
                                  <select
                                    id
                                    className="kt_docs_select2_rich_content form-select form-select-transparent"
                                    name="..."
                                    data-placeholder="..."
                                  >
                                    <option />
                                    <option
                                      value={0}
                                      data-kt-rich-content-subcontent="Use past bidding activity to predict bids that are more likely to win"
                                      selected
                                    >
                                      Suggested Bid
                                    </option>
                                    <option
                                      value={1}
                                      data-kt-rich-content-subcontent="Can customize bids for each keyword"
                                    >
                                      Custom Bid
                                    </option>
                                    <option
                                      value={1}
                                      data-kt-rich-content-subcontent="Links multiple keywords to the same bid value"
                                    >
                                      Default Bid
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center my-7">
                            <div className="col-md-3">
                              <span className="fw-bolder fs-6">Filter by</span>
                            </div>
                            <div className="col-md-9">
                              <div className=" d-flex gap-5 mt-0">
                                <div className="form-check ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="flexCheckDefault"
                                    defaultChecked
                                  />
                                  <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                    Broad
                                  </label>
                                </div>
                                <div className="form-check ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="flexCheckDefault1"
                                  />
                                  <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                    Phrase
                                  </label>
                                </div>
                                <div className="form-check ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    defaultValue
                                    id="flexCheckDefault2"
                                  />
                                  <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                    Exact
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className>
                            <textarea
                              className="form-control  fs-7"
                              name
                              id
                              cols={30}
                              rows={10}
                              placeholder="Enter ASINs seprated by comma, space or new line."
                              defaultValue={""}
                            />
                            <div className="mt-3 text-end">
                              <button className="btn btn-dark fs-7">Add</button>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`tab-pane fade cursor-pointer ${
                            all_common_hide_show?.manual_keyword_targeting ===
                            "upload"
                              ? "show active"
                              : ""
                          }`}
                        >
                          <p className="mb-3 mt-2 text-muted">
                            Use the template to make sure your information
                            uploads accurately.
                          </p>
                          <div className="mb-5">
                            <span className="fw-bold text-primary">
                              <i className="bi bi-download text-primary" />
                              Download the csv template
                            </span>
                          </div>

                          <div className="form">
                            <div className="fv-row">
                              <div
                                className="dropzone"
                                id="kt_dropzonejs_example_1"
                              >
                                <div className="dz-message needsclick">
                                  <i className="ki-duotone ki-file-up fs-3x text-primary">
                                    <span className="path1" />
                                    <span className="path2" />
                                  </i>

                                  <div className="ms-4">
                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                      Drop files here or click to upload.
                                    </h3>
                                    <span className="fs-7 fw-semibold text-gray-600">
                                      Accepted formats: CSV, TSV, XLSX
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xxl-6">
                  <div className="card" style={{ minHeight: "686px" }}>
                    <div className="card-header ">
                      <div className="card-title m-0">
                        <h3 className="fw-bold m-0">Keywords Targeting List</h3>
                      </div>
                      <div className="card-toolbar">
                        <span
                          onClick={() => set_selected_keyword_list([])}
                          className="btn fs-7 btn-light-primary fw-bold btn-sm"
                        >
                          Remove All
                        </span>
                      </div>
                    </div>
                    <div className="card-body p-5">
                      <div className="mt-5">
                        <div
                          className="table-responsive"
                          style={{ height: "400px" }}
                        >
                          {selected_keyword_list?.length == 0 ? (
                            <NoData type="new" />
                          ) : (
                            selected_keyword_list?.map((d) => {
                              return (
                                <div className="bg-light my-2 p-3">
                                  <div>
                                    <div className=" d-flex align-items-center justify-content-between ">
                                      <div className="fs-7">
                                        Keyword: <b>{d?.keyword_name}</b>
                                      </div>
                                    </div>
                                    <div className="separator separator-dashed border-gray-300 mt-3" />
                                    <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                                      <div className="min-w-100px ">
                                        Match Type:
                                      </div>
                                      <div className="min-w-70px ">
                                        <b>Broad</b>
                                      </div>
                                      <div className="min-w-70px ">
                                        <b>Phrase</b>
                                      </div>
                                      <div className="min-w-70px ">
                                        <b>Exact</b>
                                      </div>
                                    </div>
                                    <div className=" mt-2 d-flex fs-7 align-items-center justify-content-between">
                                      <div className="min-w-100px ">
                                        Sugg. Bid:
                                      </div>
                                      <div className="min-w-70px d-flex">
                                        <span>
                                          {d?.broad?.sugg_bid ? (
                                            <>
                                              {" "}
                                              <b>${d?.broad?.sugg_bid || 0}</b>
                                              <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  const after_deleted_list =
                                                    selected_keyword_list.map(
                                                      (item) => {
                                                        if (
                                                          item.keyword_name ===
                                                          d?.keyword_name
                                                        ) {
                                                          const {
                                                            broad,
                                                            ...rest
                                                          } = item;
                                                          return rest;
                                                        }
                                                        return item;
                                                      }
                                                    );
                                                  const filteredArray =
                                                    after_deleted_list.filter(
                                                      (d) =>
                                                        d?.hasOwnProperty(
                                                          "broad"
                                                        ) ||
                                                        d?.hasOwnProperty(
                                                          "phrase"
                                                        ) ||
                                                        d?.hasOwnProperty(
                                                          "exact"
                                                        )
                                                    );

                                                  set_selected_keyword_list(
                                                    filteredArray
                                                  );
                                                }}
                                              >
                                                <i className="ki-outline ki-cross text-primary fs-4" />
                                              </span>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </span>
                                      </div>
                                      <div className="min-w-70px d-flex">
                                        <span>
                                          {d?.phrase?.sugg_bid ? (
                                            <>
                                              {" "}
                                              <b>
                                                ${d?.phrase?.sugg_bid || 0}
                                              </b>{" "}
                                              <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  const after_deleted_list =
                                                    selected_keyword_list.map(
                                                      (item) => {
                                                        if (
                                                          item.keyword_name ===
                                                          d?.keyword_name
                                                        ) {
                                                          const {
                                                            phrase,
                                                            ...rest
                                                          } = item;
                                                          return rest;
                                                        }
                                                        return item;
                                                      }
                                                    );

                                                  const filteredArray =
                                                    after_deleted_list.filter(
                                                      (d) =>
                                                        d?.hasOwnProperty(
                                                          "broad"
                                                        ) ||
                                                        d?.hasOwnProperty(
                                                          "phrase"
                                                        ) ||
                                                        d?.hasOwnProperty(
                                                          "exact"
                                                        )
                                                    );

                                                  set_selected_keyword_list(
                                                    filteredArray
                                                  );
                                                }}
                                              >
                                                <i className="ki-outline ki-cross text-primary fs-4" />
                                              </span>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </span>
                                      </div>
                                      <div className="min-w-70px d-flex">
                                        <span>
                                          {d?.exact?.sugg_bid ? (
                                            <>
                                              <b>${d?.exact?.sugg_bid || 0}</b>
                                              <span
                                                className="cursor-pointer"
                                                onClick={() => {
                                                  const after_deleted_list =
                                                    selected_keyword_list.map(
                                                      (item) => {
                                                        if (
                                                          item.keyword_name ===
                                                          d?.keyword_name
                                                        ) {
                                                          const {
                                                            exact,
                                                            ...rest
                                                          } = item;
                                                          return rest;
                                                        }
                                                        return item;
                                                      }
                                                    );

                                                  const filteredArray =
                                                    after_deleted_list.filter(
                                                      (d) =>
                                                        d?.hasOwnProperty(
                                                          "broad"
                                                        ) ||
                                                        d?.hasOwnProperty(
                                                          "phrase"
                                                        ) ||
                                                        d?.hasOwnProperty(
                                                          "exact"
                                                        )
                                                    );

                                                  set_selected_keyword_list(
                                                    filteredArray
                                                  );
                                                }}
                                              >
                                                <i className="ki-outline ki-cross text-primary fs-4" />
                                              </span>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {manual_sub_targeting_type === "product_targeting" && (
            <div className="manualDiv1">
              <div className="row gy-5 g-xl-5">
                <div className="col-md-12">
                  <ul className="nav nav-pills mt-5  fs-6 border-0">
                    <li
                      className="nav-item"
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          manual_product_targeting: "category",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold btn-sm btn bg-white btn-active-primary btn-active-color-light fs-7 ${
                          all_common_hide_show?.manual_product_targeting ===
                          "category"
                            ? "active"
                            : ""
                        }`}
                      >
                        Categories
                      </span>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        set_all_common_hide_show({
                          ...all_common_hide_show,
                          manual_product_targeting: "individual_products",
                        });
                      }}
                    >
                      <span
                        className={`nav-link fw-bold btn-sm btn bg-white btn-active-primary btn-active-color-light fs-7 ${
                          all_common_hide_show?.manual_product_targeting ===
                          "individual_products"
                            ? "active"
                            : ""
                        }`}
                      >
                        Individual Products
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className={`tab-pane fade ${
                        all_common_hide_show?.manual_product_targeting ===
                        "category"
                          ? " show active"
                          : ""
                      }`}
                    >
                      <div className="row gy-5 g-xl-5">
                        <div className="col-xxl-6">
                          <div className="card min-h-450px">
                            <div className="card-header card-header-stretch">
                              <div className="card-title fs-5 m-0">
                                <h3 className="fw-bold m-0">
                                  Product Targeting 2
                                </h3>
                              </div>
                              <div className="card-toolbar"></div>
                            </div>
                            <div className="card-body pt-2">
                              <ul className="nav nav-tabs mb-5 nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                                <li
                                  className={`nav-item cursor-pointer `}
                                  onClick={() => {
                                    set_all_common_hide_show({
                                      ...all_common_hide_show,
                                      manual_product_category: "suggested",
                                    });
                                  }}
                                >
                                  <span
                                    className={`nav-link fw-bold text-gray-800 ${
                                      all_common_hide_show?.manual_product_category ===
                                      "suggested"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Suggested
                                  </span>
                                </li>
                                <li
                                  className={`nav-item cursor-pointer`}
                                  onClick={() => {
                                    set_all_common_hide_show({
                                      ...all_common_hide_show,
                                      manual_product_category: "search",
                                    });
                                  }}
                                >
                                  <span
                                    className={`nav-link fw-bold text-gray-800 ${
                                      all_common_hide_show?.manual_product_category ===
                                      "search"
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    Search
                                  </span>
                                </li>
                              </ul>
                              <div className="tab-content" id="myTabContent">
                                <div
                                  className={`tab-pane fade ${
                                    all_common_hide_show?.manual_product_category ===
                                    "suggested"
                                      ? "show active"
                                      : ""
                                  }`}
                                >
                                  <div className="mt-0 ">
                                    <div className="row align-items-center">
                                      <div className="col-md-3">
                                        <span className="fw-bolder fs-6">
                                          Bid
                                        </span>
                                      </div>
                                      <div className="col-md-9">
                                        <div className="d-flex w-100">
                                          <div className="border rounded w-100">
                                            <select
                                              id
                                              className="kt_docs_select2_rich_content form-select form-select-transparent"
                                              name="..."
                                              data-placeholder="..."
                                            >
                                              <option />
                                              <option
                                                value={0}
                                                data-kt-rich-content-subcontent="Use past bidding activity to predict bids that are more likely to win"
                                                selected
                                              >
                                                Suggested Bid
                                              </option>
                                              <option
                                                value={1}
                                                data-kt-rich-content-subcontent="Can customize bids for each keyword"
                                              >
                                                Custom Bid
                                              </option>
                                              <option
                                                value={1}
                                                data-kt-rich-content-subcontent="Links multiple keywords to the same bid value"
                                              >
                                                Default Bid
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-5 ">
                                    <div className="table-responsive ">
                                      {category_targeting_loading ? (
                                        <Skeleton block />
                                      ) : sugg_category_targeting?.length >
                                        0 ? (
                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                          <thead>
                                            <tr className="fw-bolder fs-7">
                                              <th>Suggestion</th>
                                              <th>Sugg. Bid</th>
                                              <th className="text-end">
                                                <span>Add All</span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {sugg_category_targeting?.map(
                                              (d) => {
                                                const isAddCategory =
                                                  selected_categroy_list?.filter(
                                                    (f) =>
                                                      f?.category_id ===
                                                      d?.category_id
                                                  );
                                                return (
                                                  <tr className="bg-light fs-7">
                                                    <td>
                                                      <div>
                                                        {/* <b>Category:</b>{" "} */}
                                                        {d?.category_name}
                                                      </div>
                                                    </td>
                                                    <td>${d?.suggested_bid}</td>
                                                    <td className="text-end">
                                                      {isAddCategory?.length ==
                                                        0 && (
                                                        <span
                                                          className="fw-bold text-primary"
                                                          onClick={() => {
                                                            const newObj = {
                                                              category_id:
                                                                d?.category_id,
                                                              suggested_bid:
                                                                d?.suggested_bid,
                                                              new_suggested_bid:
                                                                d?.suggested_bid,
                                                              category_name:
                                                                d?.category_name,
                                                            };

                                                            set_selected_categroy_list(
                                                              (prev) => {
                                                                return [
                                                                  ...prev,
                                                                  newObj,
                                                                ];
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          Add
                                                        </span>
                                                      )}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      ) : (
                                        <NoData type="new" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${
                                    all_common_hide_show?.manual_product_category ===
                                    "search"
                                      ? "show active"
                                      : ""
                                  }`}
                                >
                                  <div className="row align-items-center">
                                    <div className="col-md-12">
                                      <div className="w-100 position-relative mb-2 mb-lg-0">
                                        <Input
                                          prefix={
                                            <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                                          }
                                          placeholder="Search by product name or categories"
                                          size="large"
                                          // value={all_filters?.search}
                                          onChange={(e) => {}}
                                          onPressEnter={(e) => {}}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-6">
                          <div className="card min-h-450px">
                            <div className="card-header ">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0">
                                  Product Targeting List 3
                                </h3>
                              </div>
                              <div className="card-toolbar">
                                <span className="btn fs-7 btn-light-primary fw-bold btn-sm">
                                  Remove All
                                </span>
                              </div>
                            </div>
                            <div className="card-body p-5">
                              <div className="table-responsive ">
                                {category_targeting_loading ? (
                                  <Skeleton block />
                                ) : sugg_category_targeting?.length > 0 ? (
                                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                    <thead>
                                      <tr className="fw-bolder fs-7">
                                        <th>Suggestion</th>
                                        <th>Sugg. Bid</th>
                                        <th className="text-end">
                                          <span></span>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selected_categroy_list?.map((d) => {
                                        return (
                                          <tr className="bg-light fs-7">
                                            <td>
                                              <div>
                                                {/* <b>Category:</b>{" "} */}
                                                {d?.category_name}
                                              </div>
                                            </td>
                                            <td>${d?.suggested_bid}</td>
                                            <td className="justify-content-end d-flex">
                                              <Input
                                                size="small"
                                                style={{ width: "100px" }}
                                                value={d?.new_suggested_bid}
                                                onChange={(e) => {
                                                  set_selected_categroy_list(
                                                    (prev) => {
                                                      const existing_cat =
                                                        prev?.find(
                                                          (es) =>
                                                            es?.category_id ===
                                                            d?.category_id
                                                        );
                                                      if (existing_cat) {
                                                        return prev.map((sc) =>
                                                          sc?.category_id ===
                                                          d?.category_id
                                                            ? {
                                                                ...sc,
                                                                new_suggested_bid:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            : sc
                                                        );
                                                      } else {
                                                        return [...prev];
                                                      }
                                                    }
                                                  );
                                                }}
                                              />
                                              <span
                                                className="ms-2 btn btn-icon h-35px w-35px btn-light"
                                                onClick={() => {
                                                  const afterDeletingCat =
                                                    selected_categroy_list?.filter(
                                                      (cl) =>
                                                        cl?.category_id !==
                                                        d?.category_id
                                                    );
                                                  set_selected_categroy_list(
                                                    afterDeletingCat
                                                  );
                                                }}
                                              >
                                                <i className="ki-outline ki-trash fs-4 text-danger" />
                                              </span>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                ) : (
                                  <NoData type="new" />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane fade ${
                        all_common_hide_show?.manual_product_targeting ===
                        "individual_products"
                          ? " show active"
                          : ""
                      }`}
                    >
                      <div className="row gy-5 g-xl-5">
                        <div className="col-xxl-6">
                          <div className="card min-h-450px">
                            <div className="card-header card-header-stretch">
                              <div className="card-title fs-5 m-0">
                                <h3 className="fw-bold m-0">
                                  Product Targeting 4
                                </h3>
                              </div>
                              <div className="card-toolbar"></div>
                            </div>
                            <div className="card-body pt-2">
                              <ul className="nav nav-tabs mb-5 nav-line-tabs nav-stretch fs-6 border-0 nav-line-tabs-2x">
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    set_all_common_hide_show({
                                      ...all_common_hide_show,
                                      manual_product_individual: "suggested",
                                    });
                                  }}
                                >
                                  <span
                                    className={`nav-link fw-bold text-gray-800 ${
                                      all_common_hide_show?.manual_product_individual ===
                                      "suggested"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    Suggested
                                  </span>
                                </li>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    set_all_common_hide_show({
                                      ...all_common_hide_show,
                                      manual_product_individual: "search",
                                    });
                                  }}
                                >
                                  <span
                                    className={`nav-link fw-bold text-gray-800 ${
                                      all_common_hide_show?.manual_product_individual ===
                                      "search"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    Search
                                  </span>
                                </li>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    set_all_common_hide_show({
                                      ...all_common_hide_show,
                                      manual_product_individual: "enter_list",
                                    });
                                  }}
                                >
                                  <span
                                    className={`nav-link fw-bold text-gray-800 ${
                                      all_common_hide_show?.manual_product_individual ===
                                      "enter_list"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    Enter List
                                  </span>
                                </li>
                                <li
                                  className="nav-item"
                                  onClick={() => {
                                    set_all_common_hide_show({
                                      ...all_common_hide_show,
                                      manual_product_individual: "upload",
                                    });
                                  }}
                                >
                                  <span
                                    className={`nav-link fw-bold text-gray-800 ${
                                      all_common_hide_show?.manual_product_individual ===
                                      "upload"
                                        ? "active"
                                        : ""
                                    } `}
                                  >
                                    Upload
                                  </span>
                                </li>
                              </ul>
                              <div className="tab-content" id="myTabContent">
                                <div
                                  className={`tab-pane fade ${
                                    all_common_hide_show?.manual_product_individual ===
                                    "suggested"
                                      ? "show active "
                                      : ""
                                  }`}
                                >
                                  <div className="mt-0 ">
                                    <div className="row align-items-center">
                                      <div className="col-md-3">
                                        <span className="fw-bolder fs-6">
                                          Bid
                                        </span>
                                      </div>
                                      <div className="col-md-9">
                                        <div className="d-flex w-100">
                                          <div className="border rounded w-100">
                                            <Select
                                              style={{ width: "100%" }}
                                              size="medium"
                                              options={[
                                                {
                                                  label: "Suggested Bid",
                                                  value: "Suggested Bid",
                                                },
                                                {
                                                  label: "Custom Bid",
                                                  value: "Custom Bid",
                                                },
                                                {
                                                  label: "Default Bid",
                                                  value: "Default Bid",
                                                },
                                              ]}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center my-7">
                                    <div className="col-md-3">
                                      <span className="fw-bolder fs-6">
                                        Filter by
                                      </span>
                                    </div>
                                    <div className="col-md-9">
                                      <div className=" d-flex gap-5 mt-0">
                                        <Checkbox.Group
                                          options={["Exact", "Expanded"]}
                                          value={
                                            selected_individual_product_filter_by
                                          }
                                          onChange={(e) => {
                                            e?.length !== 0 &&
                                              set_selected_individual_product_filter_by(
                                                e
                                              );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center my-7">
                                    <div
                                      className="table-responsive "
                                      style={{
                                        height: "400px",
                                        overflow: "scroll",
                                      }}
                                    >
                                      {product_targeting_loading ? (
                                        <Skeleton block />
                                      ) : sugg_product_targeting?.length > 0 ? (
                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                          <thead>
                                            <tr className="fw-bolder fs-7">
                                              <th>ASIN</th>
                                              <th>Sugg. Bid</th>
                                              <th>Type</th>
                                              <th className="text-end">
                                                <span>Action</span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {sugg_product_targeting?.map(
                                              (d) => {
                                                const isAddProduct =
                                                  selected_individual_product_list?.filter(
                                                    (f) =>
                                                      f.recommendedAsin ===
                                                      d.recommendedAsin
                                                  );
                                                return (
                                                  <tr className="bg-light fs-7">
                                                    <td>
                                                      <div>
                                                        {/* <b>Category:</b>{" "} */}
                                                        {d?.recommendedAsin}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div>
                                                        {/* <b>Category:</b>{" "} */}
                                                        {d?.suggested_bid}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="fw-bold">
                                                        {" "}
                                                        {selected_individual_product_filter_by?.includes(
                                                          "Exact"
                                                        ) && "Exact"}
                                                      </div>
                                                      <div className="fw-bold">
                                                        {" "}
                                                        {selected_individual_product_filter_by?.includes(
                                                          "Expanded"
                                                        ) && "Expanded"}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <div className="fw-bold mb-1">
                                                        {" "}
                                                        {selected_individual_product_filter_by?.includes(
                                                          "Exact"
                                                        ) &&
                                                        !isAddProduct?.[0]
                                                          ?.exact ? (
                                                          <div className="justify-content-end d-flex align-items-center">
                                                            {" "}
                                                            <span
                                                              className="fw-bold text-primary ms-2 cursor-pointer"
                                                              onClick={() => {
                                                                const newObj = {
                                                                  recommendedAsin:
                                                                    d?.recommendedAsin,
                                                                  suggested_bid:
                                                                    d?.suggested_bid,
                                                                  exact: {
                                                                    suggested_bid:
                                                                      d?.suggested_bid,
                                                                    state:
                                                                      "ENABLED",
                                                                    expression_type:
                                                                      "ASIN_SAME_AS",
                                                                    expression_value:
                                                                      d?.recommendedAsin,
                                                                  },
                                                                };

                                                                set_selected_individual_product_list(
                                                                  (prev) => {
                                                                    const existingASIN =
                                                                      prev?.find(
                                                                        (es) =>
                                                                          es?.recommendedAsin ===
                                                                          d?.recommendedAsin
                                                                      );
                                                                    if (
                                                                      existingASIN
                                                                    ) {
                                                                      return prev.map(
                                                                        (sk) =>
                                                                          sk?.recommendedAsin ===
                                                                          d?.recommendedAsin
                                                                            ? {
                                                                                ...sk,
                                                                                ...newObj,
                                                                              }
                                                                            : sk
                                                                      );
                                                                    } else {
                                                                      return [
                                                                        ...prev,
                                                                        newObj,
                                                                      ];
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                      <div className="fw-bold">
                                                        {selected_individual_product_filter_by?.includes(
                                                          "Expanded"
                                                        ) &&
                                                        !isAddProduct?.[0]
                                                          ?.expanded ? (
                                                          <div className="justify-content-end d-flex align-items-center">
                                                            {" "}
                                                            <span
                                                              className="fw-bold text-primary ms-2 cursor-pointer"
                                                              onClick={() => {
                                                                const newObj = {
                                                                  recommendedAsin:
                                                                    d?.recommendedAsin,
                                                                  suggested_bid:
                                                                    d?.suggested_bid,
                                                                  expanded: {
                                                                    suggested_bid:
                                                                      d?.suggested_bid,
                                                                    state:
                                                                      "ENABLED",
                                                                    expression_type:
                                                                      "ASIN_EXPANDED_FROM",
                                                                    expression_value:
                                                                      d?.recommendedAsin,
                                                                  },
                                                                };

                                                                set_selected_individual_product_list(
                                                                  (prev) => {
                                                                    const existingASIN =
                                                                      prev?.find(
                                                                        (es) =>
                                                                          es?.recommendedAsin ===
                                                                          d?.recommendedAsin
                                                                      );
                                                                    if (
                                                                      existingASIN
                                                                    ) {
                                                                      return prev.map(
                                                                        (sk) =>
                                                                          sk?.recommendedAsin ===
                                                                          d?.recommendedAsin
                                                                            ? {
                                                                                ...sk,
                                                                                ...newObj,
                                                                              }
                                                                            : sk
                                                                      );
                                                                    } else {
                                                                      return [
                                                                        ...prev,
                                                                        newObj,
                                                                      ];
                                                                    }
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              Add
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          ""
                                                        )}
                                                      </div>
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                          </tbody>
                                        </table>
                                      ) : (
                                        <NoData type="new" />
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${
                                    all_common_hide_show?.manual_product_individual ===
                                    "search"
                                      ? "show active "
                                      : ""
                                  }`}
                                >
                                  <div className="mt-0 ">
                                    <div className="row align-items-center">
                                      <div className="col-md-3">
                                        <span className="fw-bolder fs-6">
                                          Bid
                                        </span>
                                      </div>
                                      <div className="col-md-9">
                                        <div className="d-flex w-100">
                                          <div className="border rounded w-100">
                                            <select
                                              id
                                              className="kt_docs_select2_rich_content form-select form-select-transparent"
                                              name="..."
                                              data-placeholder="..."
                                            >
                                              <option />
                                              <option
                                                value={0}
                                                data-kt-rich-content-subcontent="Use past bidding activity to predict bids that are more likely to win"
                                                selected
                                              >
                                                Suggested Bid
                                              </option>
                                              <option
                                                value={1}
                                                data-kt-rich-content-subcontent="Can customize bids for each keyword"
                                              >
                                                Custom Bid
                                              </option>
                                              <option
                                                value={1}
                                                data-kt-rich-content-subcontent="Links multiple keywords to the same bid value"
                                              >
                                                Default Bid
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center my-7">
                                    <div className="col-md-3">
                                      <span className="fw-bolder fs-6">
                                        Filter by
                                      </span>
                                    </div>
                                    <div className="col-md-9">
                                      <div className=" d-flex gap-5 mt-0">
                                        <div className="form-check ">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue
                                            id="flexCheckDefault"
                                            defaultChecked
                                          />
                                          <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                            Exact
                                          </label>
                                        </div>
                                        <div className="form-check ">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue
                                            id="flexCheckDefault1"
                                          />
                                          <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                            Expanded
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center">
                                    <div className="col-md-12">
                                      <div
                                        className="w-100 position-relative mb-2 mb-lg-0"
                                        autoComplete="off"
                                      >
                                        <Input
                                          prefix={
                                            <i class="ki-outline ki-magnifier text-gray-800 fs-1"></i>
                                          }
                                          placeholder="Search by product name or categories"
                                          size="large"
                                          // value={all_filters?.search}
                                          onChange={(e) => {}}
                                          onPressEnter={(e) => {}}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${
                                    all_common_hide_show?.manual_product_individual ===
                                    "enter_list"
                                      ? "show active "
                                      : ""
                                  }`}
                                >
                                  <div className="mt-0 ">
                                    <div className="row align-items-center">
                                      <div className="col-md-3">
                                        <span className="fw-bolder fs-6">
                                          Bid
                                        </span>
                                      </div>
                                      <div className="col-md-9">
                                        <div className="d-flex w-100">
                                          <div className="border rounded w-100">
                                            <select
                                              id
                                              className="kt_docs_select2_rich_content form-select form-select-transparent"
                                              name="..."
                                              data-placeholder="..."
                                            >
                                              <option />
                                              <option
                                                value={0}
                                                data-kt-rich-content-subcontent="Use past bidding activity to predict bids that are more likely to win"
                                                selected
                                              >
                                                Suggested Bid
                                              </option>
                                              <option
                                                value={1}
                                                data-kt-rich-content-subcontent="Can customize bids for each keyword"
                                              >
                                                Custom Bid
                                              </option>
                                              <option
                                                value={1}
                                                data-kt-rich-content-subcontent="Links multiple keywords to the same bid value"
                                              >
                                                Default Bid
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row align-items-center my-7">
                                    <div className="col-md-3">
                                      <span className="fw-bolder fs-6">
                                        Filter by
                                      </span>
                                    </div>
                                    <div className="col-md-9">
                                      <div className=" d-flex gap-5 mt-0">
                                        <div className="form-check ">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue
                                            id="flexCheckDefault"
                                            defaultChecked
                                          />
                                          <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                            Exact
                                          </label>
                                        </div>
                                        <div className="form-check ">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultValue
                                            id="flexCheckDefault1"
                                          />
                                          <label className="form-check-label fw-bold text-dark d-flex align-items-center fs-7">
                                            Expanded
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-5">
                                    <textarea
                                      className="form-control  fs-7"
                                      name
                                      id
                                      cols={30}
                                      rows={10}
                                      placeholder="Enter ASINs seprated by comma, space or new line."
                                      defaultValue={""}
                                    />
                                    <div className="mt-3 text-end">
                                      <button className="btn btn-dark fs-7">
                                        Target
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane fade ${
                                    all_common_hide_show?.manual_product_individual ===
                                    "upload"
                                      ? "show active "
                                      : ""
                                  }`}
                                >
                                  <p className="mb-3 mt-2 text-muted">
                                    Use the template to make sure your
                                    information uploads accurately.
                                  </p>
                                  <div className="mb-5">
                                    <span className="fw-bold text-primary">
                                      <i className="bi bi-download text-primary" />
                                      Download the csv template
                                    </span>
                                  </div>

                                  <div className="form">
                                    <div className="fv-row">
                                      <div
                                        className="dropzone"
                                        id="kt_dropzonejs_example_1"
                                      >
                                        <div className="dz-message needsclick">
                                          <i className="ki-duotone ki-file-up fs-3x text-primary">
                                            <span className="path1" />
                                            <span className="path2" />
                                          </i>

                                          <div className="ms-4">
                                            <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                              Drop files here or click to
                                              upload.
                                            </h3>
                                            <span className="fs-7 fw-semibold text-gray-600">
                                              Accepted formats: CSV, TSV, XLSX
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-xxl-6">
                          <div className="card min-h-450px">
                            <div className="card-header ">
                              <div className="card-title m-0">
                                <h3 className="fw-bold m-0"></h3>
                              </div>
                              <div className="card-toolbar">
                                <span
                                  className="btn fs-7 btn-light-primary fw-bold btn-sm"
                                  onClick={() => {
                                    set_selected_individual_product_list([]);
                                  }}
                                >
                                  Remove All
                                </span>
                              </div>
                            </div>
                            <div className="card-body p-5">
                              <div className="row align-items-center my-7">
                                <div
                                  className="table-responsive "
                                  style={{
                                    height: "400px",
                                    overflow: "scroll",
                                  }}
                                >
                                  {product_targeting_loading ? (
                                    <Skeleton block />
                                  ) : selected_individual_product_list?.length >
                                    0 ? (
                                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-4 gy-4 cu-tbl">
                                      <thead>
                                        <tr className="fw-bolder fs-7">
                                          <th>ASIN</th>
                                          <th>Sugg. Bid</th>
                                          <th>Type</th>
                                          <th className="text-end">
                                            <span>Action</span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {selected_individual_product_list?.map(
                                          (d) => {
                                            return (
                                              <tr className="bg-light fs-7">
                                                <td>
                                                  <div>
                                                    {/* <b>Category:</b>{" "} */}
                                                    {d?.recommendedAsin}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div>
                                                    {/* <b>Category:</b>{" "} */}
                                                    {d?.suggested_bid}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="fw-bold">
                                                    {Object.entries(
                                                      d?.exact || {}
                                                    )?.length > 0 && "Exact"}
                                                  </div>
                                                  <div className="fw-bold">
                                                    {Object.entries(
                                                      d?.expanded || {}
                                                    )?.length > 0 && "Expanded"}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="fw-bold mb-1">
                                                    {" "}
                                                    {Object.entries(
                                                      d?.exact || {}
                                                    )?.length > 0 && (
                                                      <div className="justify-content-end d-flex align-items-center">
                                                        {" "}
                                                        <Input
                                                          size="medium"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          value={
                                                            d?.exact
                                                              ?.suggested_bid
                                                          }
                                                          onChange={(e) => {
                                                            const value =
                                                              e.target.value;
                                                            // Input Value

                                                            set_selected_individual_product_list(
                                                              (prev) => {
                                                                return prev?.map(
                                                                  (a) => {
                                                                    if (
                                                                      a?.recommendedAsin ===
                                                                      d?.recommendedAsin
                                                                    ) {
                                                                      return {
                                                                        ...d,
                                                                        exact: {
                                                                          ...d?.exact,
                                                                          suggested_bid:
                                                                            value,
                                                                        },
                                                                      };
                                                                    } else {
                                                                      return a;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <span
                                                          className="ms-2 btn btn-icon h-35px w-35px btn-light"
                                                          onClick={() => {
                                                            const after_deleted_list =
                                                              selected_individual_product_list.map(
                                                                (item) => {
                                                                  if (
                                                                    item.recommendedAsin ===
                                                                    d?.recommendedAsin
                                                                  ) {
                                                                    const {
                                                                      exact,
                                                                      ...rest
                                                                    } = item;

                                                                    return rest;
                                                                  }
                                                                  return item;
                                                                }
                                                              );

                                                            const filteredArray =
                                                              after_deleted_list.filter(
                                                                (d) =>
                                                                  d?.hasOwnProperty(
                                                                    "exact"
                                                                  ) ||
                                                                  d?.hasOwnProperty(
                                                                    "expanded"
                                                                  )
                                                              );

                                                            set_selected_individual_product_list(
                                                              filteredArray
                                                            );
                                                          }}
                                                        >
                                                          <i className="ki-outline ki-trash fs-4 text-danger" />
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="fw-bold">
                                                    {" "}
                                                    {Object.entries(
                                                      d?.expanded || {}
                                                    )?.length > 0 && (
                                                      <div className="justify-content-end d-flex align-items-center">
                                                        {" "}
                                                        <Input
                                                          size="medium"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          value={
                                                            d?.expanded
                                                              ?.suggested_bid
                                                          }
                                                          onChange={(e) => {
                                                            const value =
                                                              e.target.value;
                                                            // Input Value

                                                            set_selected_individual_product_list(
                                                              (prev) => {
                                                                return prev?.map(
                                                                  (a) => {
                                                                    if (
                                                                      a?.recommendedAsin ===
                                                                      d?.recommendedAsin
                                                                    ) {
                                                                      return {
                                                                        ...d,
                                                                        expanded:
                                                                          {
                                                                            ...d?.expanded,
                                                                            suggested_bid:
                                                                              value,
                                                                          },
                                                                      };
                                                                    } else {
                                                                      return a;
                                                                    }
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }}
                                                        />
                                                        <span
                                                          className="ms-2 btn btn-icon h-35px w-35px btn-light"
                                                          onClick={() => {
                                                            const after_deleted_list =
                                                              selected_individual_product_list.map(
                                                                (item) => {
                                                                  if (
                                                                    item.recommendedAsin ===
                                                                    d?.recommendedAsin
                                                                  ) {
                                                                    const {
                                                                      expanded,
                                                                      ...rest
                                                                    } = item;
                                                                    return rest;
                                                                  }
                                                                  return item;
                                                                }
                                                              );

                                                            const filteredArray =
                                                              after_deleted_list.filter(
                                                                (d) =>
                                                                  d?.hasOwnProperty(
                                                                    "exact"
                                                                  ) ||
                                                                  d?.hasOwnProperty(
                                                                    "expanded"
                                                                  )
                                                              );

                                                            set_selected_individual_product_list(
                                                              filteredArray
                                                            );
                                                          }}
                                                        >
                                                          <i className="ki-outline ki-trash fs-4 text-danger" />
                                                        </span>
                                                      </div>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <NoData type="new" />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row gy-5 g-xl-5 mt-5">
            <div className="col-xxl-6">
              <div className="card min-h-450px">
                <div className="card-header card-header-stretch">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      Negative keyword targeting{" "}
                      <small className="fw-semibold">Optional</small>
                    </h3>
                  </div>
                  <div className="card-toolbar"></div>
                </div>
                <div className="card-body">
                  <div className="row align-items-center mb-7">
                    <div className="col-md-3">
                      <span className="fw-bolder fs-6">Match Type</span>
                    </div>
                    <div className="col-md-9">
                      <div className=" d-flex gap-5 mt-0">
                        <Radio.Group
                          name="radiogroup"
                          onChange={(e) => {
                            set_all_common_hide_show({
                              ...all_common_hide_show,
                              negative_targeting: e.target.value,
                            });
                          }}
                          value={all_common_hide_show?.negative_targeting}
                        >
                          <Radio value={"negative_exact"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Negative Exact
                              </span>
                            </span>
                          </Radio>
                          <Radio value={"negative_phrase"}>
                            <span className="d-flex flex-column">
                              <span className="fw-bold fs-6">
                                Negative Phrase
                              </span>
                            </span>
                          </Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  </div>
                  <div className="mt-0">
                    <textarea
                      className="form-control  fs-7"
                      cols={30}
                      rows={5}
                      placeholder="Enter keywords seprated by new line."
                      value={negativeKeyword}
                      onChange={(e) => {
                        setNegativeKeyword(e.target.value);
                      }}
                    />
                    {negative_keyword_list.length !== 0 && (
                      <div id className="mt-2 text-success">
                        {negative_keyword_list.length} keywords were added
                        successfully.
                      </div>
                    )}
                  </div>
                  <div className="mt-3 text-start">
                    <button
                      className="btn btn-primary fs-7"
                      disabled={!negativeKeyword}
                      onClick={(e) => {
                        e.preventDefault();
                        if (negative_keyword_list.length !== 0) {
                          if (
                            negative_keyword_list.some(
                              (i) => i.negative_keyword === negativeKeyword
                            )
                          ) {
                            DisplayNotification(
                              "error",
                              "Error",
                              `${negativeKeyword} is already declared`
                            );
                          } else {
                            set_negative_keyword_list([
                              ...negative_keyword_list,
                              {
                                negative_keyword: negativeKeyword,
                                state: "ENABLED",
                                negative_keyword_type:
                                  all_common_hide_show?.negative_targeting,
                              },
                            ]);
                            setNegativeKeyword("");
                          }
                        } else {
                          set_negative_keyword_list([
                            {
                              negative_keyword: negativeKeyword,
                              state: "ENABLED",
                              negative_keyword_type:
                                all_common_hide_show?.negative_targeting,
                            },
                          ]);
                          setNegativeKeyword("");
                        }
                      }}
                    >
                      Add Keyword
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-6">
              <div className="card min-h-450px">
                <div className="card-header ">
                  <div className="card-title m-0">
                    <h3 className="fw-bold m-0">
                      {negative_keyword_list.length} Added
                    </h3>
                  </div>
                  <div className="card-toolbar">
                    <span className="btn fs-7 btn-light-primary fw-bold btn-sm">
                      Remove All
                    </span>
                  </div>
                </div>
                <div className="card-body">
                  {negative_keyword_list.length !== 0 && (
                    <div className="mt-0">
                      {negative_keyword_list.map((item) => (
                        <div className="rounded bg-light p-3 mb-4">
                          <div className="d-flex align-items-center flex-column">
                            <div className="d-flex align-items-center justify-content-between w-100">
                              <div>
                                <span className="fs-8">Keyword:</span> <br />
                                <span className="text-gray-900 mb-1 fw-bolder fs-7">
                                  {item?.negative_keyword}
                                </span>
                              </div>
                              <div className="d-flex flex-column ">
                                <div className>
                                  <span className="fs-8">Match Type:</span>{" "}
                                  <br />
                                  <b className="text-gray-800 fs-7">
                                    {item?.negative_keyword_type}
                                  </b>{" "}
                                </div>
                              </div>
                              <div>
                                <button
                                  className="btn btn-light bg-white btn-sm btn-icon"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const isDeleted =
                                      negative_keyword_list?.filter(
                                        (f) =>
                                          f?.negative_keyword !==
                                          item?.negative_keyword
                                      );
                                    set_negative_keyword_list(isDeleted);
                                  }}
                                >
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SBManualTargeting;
