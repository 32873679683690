import AdminDataSchedulerLog from "../modules/pages/admin/admin-application-logs/data-scheduler";
import CentralLog from "../modules/pages/application-logs/central-log";

import SystemErrorLog from "../modules/pages/application-logs/system-error-log";
// export default [
//   // {
//   //   path: "/application-logs/central-log",
//   //   component: <CentralLog />,
//   //   exact: true,
//   // },
//   // {
//   //   path: "/application-logs/system-error-log",
//   //   component: <SystemErrorLog />,
//   //   exact: true,
//   // },
//   {
//     path: "/admin-application-logs/data-scheduler",
//     component: <AdminDataSchedulerLog />,
//     exact: true,
//   },
// ];

export default [
  // {
  //   path: "/application-logs/central-log",
  //   component: <CentralLog />,
  //   exact: true,
  // },
  // {
  //   path: "/application-logs/system-error-log",
  //   component: <SystemErrorLog />,
  //   exact: true,
  // },
  {
    path: "/admin-data-scheduler",
    component: <AdminDataSchedulerLog />,
    exact: true,
  },
];
