import { Input, InputNumber, Select, Skeleton, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { MakeApiCall } from "../../../../apis/make-api-call";
import EsfTable from "../../../../component/esf-table";
import { DisplayNotification, NumberWithCommas } from "../../../../config";

const DaypartingConfiguration = (props) => {
  const {
    setIsOpenDaypartingConfig,
    flag,
    setFlag,
    updateData,
    setUpdateData,
    onSuccess,
    // allCampaign,
    selected_marketplace,
  } = props;
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [profileName, setProfileName] = useState("");
  const [allCampaign, setAllCampaign] = useState([]);
  const [selectedCampaignType, setSelectedCampaignType] = useState(null);
  const [allCampaignListDetails, setAllCampaignListDetails] = useState([]);
  const [ad_group_list, set_ad_group_list] = useState([]);
  const [selected_ad_group_list, set_selected_ad_group_list] = useState([]);
  const [keyword_type_list, set_keyword_type_list] = useState([]);
  const [selected_keyword_type_list, set_selected_keyword_type_list] = useState(
    []
  );
  const [keyword_list, set_keyword_list] = useState([]);
  const [selected_keyword_list, set_selected_keyword_list] = useState([]);

  const hours = [
    "12am",
    "1am",
    "2am",
    "3am",
    "4am",
    "5am",
    "6am",
    "7am",
    "8am",
    "9am",
    "10am",
    "11am",
    "12pm",
    "1pm",
    "2pm",
    "3pm",
    "4pm",
    "5pm",
    "6pm",
    "7pm",
    "8pm",
    "9pm",
    "10pm",
    "11pm",
  ];

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const initialBidMultipliers = {
    Mon: Array(24).fill(1),
    Tue: Array(24).fill(1),
    Wed: Array(24).fill(1),
    Thu: Array(24).fill(1),
    Fri: Array(24).fill(1),
    Sat: Array(24).fill(1),
    Sun: Array(24).fill(1),
  };

  // Initialize state with the bid multipliers
  const [dataSource, setDataSource] = useState(() => {
    return hours.map((hour, index) => {
      const row = {
        key: index,
        time: hour,
      };

      days.forEach((day) => {
        row[day] = initialBidMultipliers[day][index];
      });
      console.log(row, "--row");
      return row;
    });
  });
  const [nextStepLoading, setNextStepLoading] = useState({
    ad_group_loading: false,
    keyword_type_loading: false,
    keyword_loading: false,
  });
  useEffect(() => {
    if (flag === "update" || flag === "view" || flag === "clone") {
      setLoading(true);
      GetDayPartingConfig(updateData?.id);
    } else {
      setLoading(false);
      setNextStepLoading({
        ad_group_loading: false,
        keyword_type_loading: false,
        keyword_loading: false,
      });
      // setSelectedCampaign(response?.data);
    }
    // GetAllCampaign(selected_marketplace);
  }, []);

  const GetAllCampaign = async (marketplace_id, campaign_type) => {
    const response = await MakeApiCall(
      `advertising/get-all-campaigns?campaign_state=ENABLED&marketplace_id=${marketplace_id}&campaign_type=${campaign_type}`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      setAllCampaign(response?.data);
      // if (flag === "update" || flag === "view" || flag === "clone") {
      //   setLoading(true);
      //   GetDayPartingConfig(updateData?.id);
      // } else {
      //   setLoading(false);
      //   // setSelectedCampaign(response?.data);
      // }
      // // const campaignIds = response?.data?.map((d) => {
      // //   return d?.campaign_id;
      // // });
      // // GetAllCampaignListDetails("ad_group", campaign_type, campaignIds);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllCampaignListDetails = async (
    dropdown_type,
    campaign_type,
    campaignIds = null,
    adGroupList = null,
    keywordType = null
  ) => {
    const response = await MakeApiCall(
      `day-parting/get-keyword-list?type=${dropdown_type}&campaign_type=${campaign_type}&campaign_ids=${JSON.stringify(
        campaignIds
      )}&ad_group_ids=${JSON.stringify(
        adGroupList
      )}&keyword_type=${JSON.stringify(keywordType)}&keyword`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      if (dropdown_type === "ad_group") {
        setNextStepLoading({ ...nextStepLoading, ad_group_loading: false });
        set_ad_group_list(response?.data);
      } else if (dropdown_type === "keyword_type") {
        setNextStepLoading({ ...nextStepLoading, keyword_type_loading: false });
        set_keyword_type_list(response?.data);
      } else if (dropdown_type === "keyword") {
        setNextStepLoading({ ...nextStepLoading, keyword: false });
        set_keyword_list(response?.data);
      }
      setAllCampaignListDetails(response?.data);
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetDayPartingConfig = async (id) => {
    const response = await MakeApiCall(
      `day-parting/get-day-parting-configuration/${id}`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      setLoading(false);
      const newProfileName =
        flag === "clone"
          ? `${response?.data?.profile_name} - Clone`
          : response?.data?.profile_name;
      setDataSource(response?.data?.configuration_data);
      setProfileName(newProfileName);
      setSelectedCampaign(response?.data?.campaign_list);
      setHeaderInputs(response?.data?.days_data);
      setRowInputs(response?.data?.timing_data);

      setSelectedCampaignType(response?.data?.campaign_type);
      GetAllDropdownData(response?.data);
    } else {
      setLoading(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const GetAllDropdownData = (data) => {
    GetAllCampaign(selected_marketplace, data?.campaign_type);
    setSelectedCampaign(data?.campaign_list);

    setNextStepLoading({ ...nextStepLoading, ad_group_loading: true });
    const campaignList = data?.campaign_list?.map((d) => d?.campaign_id);
    GetAdGroupListOnEdit(campaignList, data?.campaign_type);
    set_selected_ad_group_list(data?.ad_group_list);

    setNextStepLoading({ ...nextStepLoading, keyword_type_loading: true });
    const adGroupList = data?.ad_group_list?.map((d) => d?.ad_group_id);
    GetKeywordTypeListOnEdit(campaignList, adGroupList, data?.campaign_type);
    set_selected_keyword_type_list(data?.keyword_type_list);

    setNextStepLoading({ ...nextStepLoading, keyword_loading: true });
    const keywordTypeList = data?.keyword_type_list?.map(
      (d) => d?.keyword_type
    );
    GetKeywordListOnEdit(
      campaignList,
      adGroupList,
      keywordTypeList,
      data?.campaign_type
    );
    set_selected_keyword_list(data?.keyword_list);
  };

  const GetAdGroupListOnEdit = async (campaignIds, campaign_type) => {
    const response = await MakeApiCall(
      `day-parting/get-keyword-list?type=ad_group&campaign_type=${campaign_type}&campaign_ids=${JSON.stringify(
        campaignIds
      )}&ad_group_ids=&keyword_type=&keyword`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      set_ad_group_list(response?.data);
      setNextStepLoading({ ...nextStepLoading, ad_group_loading: false });
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetKeywordTypeListOnEdit = async (
    campaignIds,
    adGroupList,
    campaign_type
  ) => {
    const response = await MakeApiCall(
      `day-parting/get-keyword-list?type=keyword_type&campaign_type=${campaign_type}&campaign_ids=${JSON.stringify(
        campaignIds
      )}&ad_group_ids=${JSON.stringify(adGroupList)}&keyword_type=&keyword`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      set_keyword_type_list(response?.data);
      setNextStepLoading({ ...nextStepLoading, keyword_type_loading: false });
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const GetKeywordListOnEdit = async (
    campaignIds,
    adGroupList,
    keywordTypeList,
    campaign_type
  ) => {
    const response = await MakeApiCall(
      `day-parting/get-keyword-list?type=keyword&campaign_type=${campaign_type}&campaign_ids=${JSON.stringify(
        campaignIds
      )}&ad_group_ids=${JSON.stringify(
        adGroupList
      )}&keyword_type=${JSON.stringify(keywordTypeList)}&keyword`,
      `GET`,
      null,
      true
    );
    if (response?.status) {
      set_keyword_list(response?.data);
      setNextStepLoading({ ...nextStepLoading, keyword: false });
    } else {
      DisplayNotification("error", "Error", response?.message);
    }
  };

  // Handle input changes
  const handleInputChange = (value, day, hourIndex) => {
    // Update the specific value in the data source state
    const newDataSource = [...dataSource];
    newDataSource[hourIndex][day] = value;
    setDataSource(newDataSource);
  };

  // const handleInputChange = (value, day, hourIndex) => {
  //   const newDataSource = [...dataSource];

  //   // Multiply the value by the time row input and the day column input
  //   const timeMultiplier = rowInputs[hours[hourIndex]];
  //   const dayMultiplier = headerInputs[day];

  //   const multipliedValue = value * timeMultiplier * dayMultiplier;

  //   newDataSource[hourIndex][day] = multipliedValue;
  //   setDataSource(newDataSource);
  // };

  const [headerInputs, setHeaderInputs] = useState(() => {
    const initialHeaderInputs = {};
    days.forEach((day) => {
      initialHeaderInputs[day] = 1;
    });
    return initialHeaderInputs;
  });

  const [rowInputs, setRowInputs] = useState(() => {
    const initialRowInputs = {};
    hours.forEach((hour) => {
      initialRowInputs[hour] = 1;
    });
    return initialRowInputs;
  });

  // const handleHeaderInputChange = (value, day) => {
  //   setHeaderInputs((prevState) => ({
  //     ...prevState,
  //     [day]: value,
  //   }));

  //   const newDataSource = dataSource.map((row) => {
  //     row[day] = value;
  //     return row;
  //   });

  //   setDataSource(newDataSource);
  // };

  console.log(headerInputs, rowInputs, "-------");
  const handleHeaderInputChange = (value, day) => {
    setHeaderInputs((prevState) => ({
      ...prevState,
      [day]: value,
    }));

    const newDataSource = dataSource.map((row, hourIndex) => {
      const timeMultiplier = rowInputs[hours[hourIndex]];
      const multipliedValue = value * timeMultiplier;
      return {
        ...row,
        [day]: multipliedValue,
      };
    });

    setDataSource(newDataSource);
  };

  const handleTimeInputChange = (value, time, index) => {
    setRowInputs((prevState) => ({
      ...prevState,
      [time]: value,
    }));

    const newDataSource = dataSource.map((row, rowIndex) => {
      if (rowIndex === index) {
        days.forEach((day) => {
          const dayMultiplier = headerInputs[day];
          row[day] = value * dayMultiplier;
        });
      }
      return row;
    });

    setDataSource(newDataSource);
  };

  const resetAll = () => {
    const resetDataSource = hours.map((hour, index) => {
      const row = { key: index, time: hour };

      days.forEach((day) => {
        row[day] = initialBidMultipliers[day][index];
      });

      return row;
    });
    setDataSource(resetDataSource);

    const initialHeaderInputs = {};
    days.forEach((day) => {
      initialHeaderInputs[day] = 1;
    });
    setHeaderInputs(initialHeaderInputs);

    const initialRowInputs = {};
    hours.forEach((hour) => {
      initialRowInputs[hour] = 1;
    });
    setRowInputs(initialRowInputs);
  };

  const columns = [
    {
      title: (
        <>
          {flag !== "view" && (
            <button
              className="btn btn-primary btn-md"
              onClick={() => {
                resetAll();
              }}
            >
              Reset All
            </button>
          )}
        </>
      ),
      dataIndex: "time",
      key: "time",
      render: (time, _, index) => {
        return (
          <div className="d-flex align-items-center">
            <InputNumber
              disabled={flag === "view"}
              step={0.1}
              min={0}
              max={5}
              value={rowInputs[time]}
              formatter={(value) => `${value}`}
              suffix={"x"}
              onBlur={(e) => {
                console.log(index, "-index");
                if (!e.target.value) {
                  setRowInputs((prevState) => ({
                    ...prevState,
                    [time]: 0,
                  }));
                  days?.map((d) => {
                    handleInputChange(0, d, index);
                  });
                }
              }}
              // onChange={(newValue) => {
              //   setRowInputs((prevState) => ({
              //     ...prevState,
              //     [time]: newValue,
              //   }));
              //   days?.map((d) => {
              //     handleInputChange(newValue, d, index);
              //   });
              // }}
              onChange={(newValue) =>
                handleTimeInputChange(newValue, time, index)
              }
              // const getIndex = hours[i?.key]
            />
            <label className="ms-2 fw-bold fs-5">{time}</label>
          </div>
        );
      },
    },
    ...days.map((day) => ({
      title: (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <InputNumber
            disabled={flag === "view"}
            value={headerInputs[day]}
            step={0.1}
            min={0}
            max={5}
            formatter={(value) => `${value}`}
            suffix={"x"}
            onChange={(newValue) => handleHeaderInputChange(newValue, day)}
          />
          <label className="mt-3">{day}</label>
        </div>
      ),
      align: "center",
      dataIndex: day,
      key: day,
      render: (value, _, hourIndex) => (
        <>
          <div
            className="d-flex justify-content-between"
            style={{
              padding: "4px 8px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              background:
                NumberWithCommas(value) == 0
                  ? "#fff1c9"
                  : NumberWithCommas(value) > 1 && "#e4f2e4",
            }}
          >
            <label>{NumberWithCommas(value)}</label>
            <label>x</label>
          </div>
          {/* <InputNumber
            // disabled={flag === "view"}
            value={parseFloat(value)?.toFixed(2)}
            // step={0.1}
            // min={0.1}
            // max={5}
            formatter={(value) => `${value}`}
            suffix={"x"}
            disabled
            onChange={(newValue) => handleInputChange(newValue, day, hourIndex)}
            onBlur={(e) => {
              if (!e.target.value) {
                handleInputChange(0, day, hourIndex);
              }
            }}
          /> */}
        </>
      ),
    })),
  ];

  const getTime = (data) => {
    const timeObj = {
      "12am": "00:00",
      "1am": "01:00",
      "2am": "02:00",
      "3am": "03:00",
      "4am": "04:00",
      "5am": "05:00",
      "6am": "06:00",
      "7am": "07:00",
      "8am": "08:00",
      "9am": "09:00",
      "10am": "10:00",
      "11am": "11:00",
      "12pm": "12:00",
      "1pm": "13:00",
      "2pm": "14:00",
      "3pm": "15:00",
      "4pm": "16:00",
      "5pm": "17:00",
      "6pm": "18:00",
      "7pm": "19:00",
      "8pm": "20:00",
      "9pm": "21:00",
      "10pm": "22:00",
      "11pm": "23:00",
    };
    return timeObj[data];
  };

  const getDay = (data) => {
    const dayObj = {
      Mon: "monday",
      Tue: "tuesday",
      Wed: "wednesday",
      Thu: "thursday",
      Fri: "friday",
      Sat: "saturday",
      Sun: "sunday",
    };
    return dayObj[data];
  };

  const saveChanges = () => {
    setSaving(true);

    const getCampaignList = selectedCampaign?.map((sc) => {
      const ad_group_filter = selected_ad_group_list?.filter(
        (ag) => ag.campaign_id === sc.campaign_id
      );
      const keyword_type_filter = selected_keyword_type_list?.filter(
        (kt) => kt.campaign_id === sc.campaign_id
      );
      const keyword_filter = selected_keyword_list?.filter(
        (k) => k.campaign_id === sc.campaign_id
      );
      return {
        campaign_type: sc?.campaign_type,
        campaign_name: sc?.campaign_name,
        campaign_id: sc?.campaign_id,
        marketplace_id: selected_marketplace,
        ad_group: ad_group_filter?.map((af) => {
          return {
            campaign_id: sc?.campaign_id,
            ad_group_id: af?.ad_group_id,
            ad_group_name: af?.ad_group_name,
          };
        }),
        keyword_type: keyword_type_filter?.map((ktf) => {
          return {
            campaign_id: sc?.campaign_id,
            ad_group_id: ktf?.ad_group_id,
            keyword_type: ktf?.keyword_type,
            id: ktf?.id,
          };
        }),
        keyword: keyword_filter?.map((kf) => {
          return {
            ...kf,
            campaign_id: sc?.campaign_id,
            keyword_id: kf?.keyword_id,
            keyword: kf?.keyword,
          };
        }),
      };
    });

    // Implement the logic to save the changes, e.g., sending data to a server
    console.log("Saved Data:", dataSource);

    const daysArr = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    const myJson = daysArr?.map((d) => {
      return dataSource?.map((ds) => {
        return {
          day_name: getDay(d),
          time_value: getTime(ds?.time),
          cell_value: ds?.[d],
        };
      });
    });

    const jsonData = myJson?.flat();
    const finalJson = {
      profile_name: profileName,
      campaign_type: selectedCampaignType,
      marketplace_id: selected_marketplace,
      campaign_list: getCampaignList,
      dayparting_configuration: jsonData,
      days_data: headerInputs,
      timing_data: rowInputs,
    };
    console?.log(finalJson, "-myJson");

    if (flag === "create" || flag === "clone") {
      SaveDayPartingData("day-parting/create-configuration", "post", finalJson);
      console.log("create");
    } else {
      SaveDayPartingData(
        `day-parting/update-configuration/${updateData?.id}`,
        "put",
        finalJson
      );
      console.log("edit");
    }
  };

  const SaveDayPartingData = async (url, method, data) => {
    const response = await MakeApiCall(url, method, data, true);
    if (response?.status) {
      setSaving(false);
      DisplayNotification("success", "Success", response?.message);
      onSuccess();
    } else {
      setSaving(false);
      DisplayNotification("error", "Error", response?.message);
    }
  };
  const clearDropDownVal = (type) => {
    if (type === "campaign_type") {
      setAllCampaign([]);
      setSelectedCampaign([]);
      set_ad_group_list([]);
      set_selected_ad_group_list([]);
      set_keyword_type_list([]);
      set_selected_keyword_type_list([]);
      set_keyword_list([]);
      set_selected_keyword_list([]);
    } else if (type === "campaign_list") {
      set_ad_group_list([]);
      set_selected_ad_group_list([]);
      set_keyword_type_list([]);
      set_selected_keyword_type_list([]);
      set_keyword_list([]);
      set_selected_keyword_list([]);
    } else if (type === "ad_group_list") {
      set_ad_group_list([]);
      set_selected_ad_group_list([]);
      set_keyword_type_list([]);
      set_selected_keyword_type_list([]);
      set_keyword_list([]);
      set_selected_keyword_list([]);
    } else if (type === "keyword_type") {
      set_keyword_type_list([]);
      set_selected_keyword_type_list([]);
      set_keyword_list([]);
      set_selected_keyword_list([]);
    } else if (type === "keyword") {
      set_keyword_list([]);
      set_selected_keyword_list([]);
    }
  };

  // const SaveAllHeaderData = () => {
  //   // const selectedCampaign = [
  //   //   {
  //   //     id: "4774",
  //   //     campaign_type: "SPONSORED_PRODUCTS",
  //   //     state: "ENABLED",
  //   //     campaign_id: "54558086562307",
  //   //     campaign_name: "Noni Fruit Capsules - Auto - ESF",
  //   //     label: "Noni Fruit Capsules - Auto - ESF",
  //   //     value: "54558086562307",
  //   //   },
  //   //   {
  //   //     id: "4778",
  //   //     campaign_type: "SPONSORED_PRODUCTS",
  //   //     state: "ENABLED",
  //   //     campaign_id: "88295702029735",
  //   //     campaign_name: "Noni Fruit Capsule - Manual - ESF",
  //   //     label: "Noni Fruit Capsule - Manual - ESF",
  //   //     value: "88295702029735",
  //   //   },
  //   // ];
  //   // const selectedKeyword = [
  //   //   {
  //   //     id: "68",
  //   //     keyword_id: "159689509546570",
  //   //     keyword: "complements",
  //   //     campaign_name: "Noni Fruit Capsules - Auto - ESF",
  //   //     campaign_id: "54558086562307",
  //   //     ad_group_name: "Auto",
  //   //     ad_group_id: "128312851337149",
  //   //     keyword_type: "TARGETING_EXPRESSION_PREDEFINED",
  //   //     label: "complements",
  //   //     value: "159689509546570",
  //   //   },
  //   //   {
  //   //     id: "69",
  //   //     keyword_id: "163044050526796",
  //   //     keyword: "substitutes",
  //   //     campaign_name: "Noni Fruit Capsules - Auto - ESF",
  //   //     campaign_id: "54558086562307",
  //   //     ad_group_name: "Auto",
  //   //     ad_group_id: "128312851337149",
  //   //     keyword_type: "TARGETING_EXPRESSION_PREDEFINED",
  //   //     label: "substitutes",
  //   //     value: "163044050526796",
  //   //   },
  //   //   {
  //   //     id: "73",
  //   //     keyword_id: "80955653345215",
  //   //     keyword: "noni extract",
  //   //     campaign_name: "Noni Fruit Capsule - Manual - ESF",
  //   //     campaign_id: "88295702029735",
  //   //     ad_group_name: "Broad",
  //   //     ad_group_id: "224109219679712",
  //   //     keyword_type: "BROAD",
  //   //     label: "noni extract",
  //   //     value: "80955653345215",
  //   //   },
  //   //   {
  //   //     id: "96",
  //   //     keyword_id: "413089995019072",
  //   //     keyword: "noni pills",
  //   //     campaign_name: "Noni Fruit Capsule - Manual - ESF",
  //   //     campaign_id: "88295702029735",
  //   //     ad_group_name: "Broad",
  //   //     ad_group_id: "224109219679712",
  //   //     keyword_type: "BROAD",
  //   //     label: "noni pills",
  //   //     value: "413089995019072",
  //   //   },
  //   // ];
  //   // const ad_group_list = [
  //   //   {
  //   //     id: "68",
  //   //     keyword_id: "159689509546570",
  //   //     keyword: "complements",
  //   //     campaign_name: "Noni Fruit Capsules - Auto - ESF",
  //   //     campaign_id: "54558086562307",
  //   //     ad_group_name: "Auto",
  //   //     ad_group_id: "128312851337149",
  //   //     keyword_type: "TARGETING_EXPRESSION_PREDEFINED",
  //   //     label: "Auto",
  //   //     value: "128312851337149",
  //   //   },
  //   //   {
  //   //     id: "73",
  //   //     keyword_id: "80955653345215",
  //   //     keyword: "noni extract",
  //   //     campaign_name: "Noni Fruit Capsule - Manual - ESF",
  //   //     campaign_id: "88295702029735",
  //   //     ad_group_name: "Broad",
  //   //     ad_group_id: "224109219679712",
  //   //     keyword_type: "BROAD",
  //   //     label: "Broad",
  //   //     value: "224109219679712",
  //   //   },
  //   //   {
  //   //     id: "104088",
  //   //     keyword_id: "226183635389795",
  //   //     keyword: "noni powder organic",
  //   //     campaign_name: "Noni Fruit Capsule - Manual - ESF",
  //   //     campaign_id: "88295702029735",
  //   //     ad_group_name: "Phrase",
  //   //     ad_group_id: "212846902815535",
  //   //     keyword_type: "PHRASE",
  //   //     label: "Phrase",
  //   //     value: "212846902815535",
  //   //   },
  //   // ];
  //   // const keyword_type_list = [
  //   //   {
  //   //     id: "68",
  //   //     keyword_id: "159689509546570",
  //   //     keyword: "complements",
  //   //     campaign_name: "Noni Fruit Capsules - Auto - ESF",
  //   //     campaign_id: "54558086562307",
  //   //     ad_group_name: "Auto",
  //   //     ad_group_id: "128312851337149",
  //   //     keyword_type: "TARGETING_EXPRESSION_PREDEFINED",
  //   //     label: "TARGETING_EXPRESSION_PREDEFINED",
  //   //     value: "TARGETING_EXPRESSION_PREDEFINED",
  //   //   },
  //   //   {
  //   //     id: "73",
  //   //     keyword_id: "80955653345215",
  //   //     keyword: "noni extract",
  //   //     campaign_name: "Noni Fruit Capsule - Manual - ESF",
  //   //     campaign_id: "88295702029735",
  //   //     ad_group_name: "Broad",
  //   //     ad_group_id: "224109219679712",
  //   //     keyword_type: "BROAD",
  //   //     label: "BROAD",
  //   //     value: "BROAD",
  //   //   },
  //   // ];
  //   console.log(selected_keyword_type_list, "---");
  //   const getCampaignList = selectedCampaign?.map((sc) => {
  //     const ad_group_filter = selected_ad_group_list?.filter(
  //       (ag) => ag.campaign_id === sc.campaign_id
  //     );
  //     const keyword_type_filter = selected_keyword_type_list?.filter(
  //       (kt) => kt.campaign_id === sc.campaign_id
  //     );
  //     const keyword_filter = selected_keyword_list?.filter(
  //       (k) => k.campaign_id === sc.campaign_id
  //     );
  //     return {
  //       campaign_type: sc?.campaign_type,
  //       campaign_name: sc?.campaign_name,
  //       campaign_id: sc?.campaign_id,
  //       marketplace_id: selected_marketplace,
  //       ad_group: ad_group_filter?.map((af) => {
  //         return {
  //           campaign_id: sc?.campaign_id,
  //           ad_group_id: af?.ad_group_id,
  //           ad_group_name: af?.ad_group_name,
  //         };
  //       }),
  //       keyword_type: keyword_type_filter?.map((ktf) => {
  //         return {
  //           campaign_id: sc?.campaign_id,
  //           keyword_type: ktf?.keyword_type,
  //         };
  //       }),
  //       keyword: keyword_filter?.map((kf) => {
  //         return {
  //           ...kf,
  //           campaign_id: sc?.campaign_id,
  //           keyword_id: kf?.keyword_id,
  //           keyword: kf?.keyword,
  //         };
  //       }),
  //     };
  //   });

  //   console.clear();
  //   console.log(getCampaignList, "-getCampaignList");
  // };
  return (
    <>
      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12">
          <div className="card card-shadow card-xxl-stretch mb-5 mb-xl-8">
            {/* <div className="card-header border-0">
              <div className="d-flex flex-stack flex-wrap">
                <div class="card-title align-items-start"></div>
              </div>
              <div className="card-toolbar"></div>
            </div>
           */}
            <div className="card-body py-2 main-box">
              <div className="row mb-4 mt-3">
                <div className="col-12 d-flex justify-content-start align-items-center flex-wrap ">
                  <div
                    className="position-relative me-4"
                    style={{ width: "300px" }}
                  >
                    <label className="fw-bold fs-6">Profile Name</label>
                    <Input
                      style={{ width: "100%" }}
                      value={profileName}
                      onChange={(e) => {
                        setProfileName(e.target.value);
                      }}
                      size="large"
                      placeholder="Enter Profile Name"
                    />
                  </div>
                  <div className="position-relative" style={{ width: "250px" }}>
                    <label className="fw-bold fs-6">Campaign Type</label>
                    <Select
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Select Campaign Type"
                      showSearch
                      options={[
                        {
                          label: "Sponsored Product",
                          value: "SPONSORED_PRODUCTS",
                        },
                        {
                          label: "Sponsored Display",
                          value: "SPONSORED_DISPLAY",
                        },
                        {
                          label: "Sponsored Brand",
                          value: "SPONSORED_BRAND",
                        },
                        { label: "Sponsored TV", value: "SPONSORED_TV" },
                      ]}
                      value={selectedCampaignType}
                      allowClear
                      onChange={(e) => {
                        setSelectedCampaignType(e);
                        clearDropDownVal("campaign_type");
                        GetAllCampaign(selected_marketplace, e);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex justify-content-start align-items-center flex-wrap ">
                    {selectedCampaignType && allCampaign?.length > 0 ? (
                      <div className="position-relative">
                        <label className="fw-bold fs-6">Campaigns</label>
                        <div className="d-flex align-items-center">
                          <Select
                            style={{ width: "250px" }}
                            className="mb-2 mt-0"
                            size="large"
                            placeholder="Select Campaigns"
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            maxTagCount={"responsive"}
                            options={allCampaign?.map((d) => {
                              return {
                                ...d,
                                label: d?.campaign_name,
                                value: d?.campaign_id,
                              };
                            })}
                            value={selectedCampaign?.map((d) => d?.campaign_id)}
                            onChange={(e, _) => {
                              setSelectedCampaign(_);
                              clearDropDownVal("ad_group_list");
                            }}
                          />
                          <div className="mx-2">
                            {nextStepLoading?.ad_group_loading ? (
                              <Spin />
                            ) : (
                              <i
                                onClick={() => {
                                  if (selectedCampaign?.length > 0) {
                                    const campaignList = selectedCampaign?.map(
                                      (d) => d?.campaign_id
                                    );
                                    console.log(campaignList, "-campaignList");
                                    clearDropDownVal("ad_group_list");
                                    setNextStepLoading({
                                      ...nextStepLoading,
                                      ad_group_loading: true,
                                    });
                                    GetAllCampaignListDetails(
                                      "ad_group",
                                      selectedCampaignType,
                                      campaignList
                                    );
                                  } else {
                                    DisplayNotification(
                                      "info",
                                      "Info",
                                      "Please select campaign"
                                    );
                                  }
                                }}
                                className="ki-outline ki-right cursor-pointer"
                                style={{
                                  fontSize: "25px",
                                  background: "#deedde",
                                  borderRadius: "5px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {selectedCampaign?.length > 0 &&
                    ad_group_list?.length > 0 ? (
                      <div className="position-relative">
                        <label className="fw-bold fs-6">Ad Groups</label>
                        <div className="d-flex align-items-center">
                          <Select
                            style={{ width: "250px" }}
                            className="mb-2 mt-0"
                            size="large"
                            placeholder="Select Ad Groups"
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.ad_group_name ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            maxTagCount={"responsive"}
                            options={ad_group_list?.map((d) => {
                              return {
                                ...d,
                                label: (
                                  <div>
                                    {d?.ad_group_name}
                                    <div className="text-muted fs-7">
                                      Campaign:{d?.campaign_name}
                                    </div>
                                  </div>
                                ),
                                value: d?.ad_group_id,
                              };
                            })}
                            value={selected_ad_group_list?.map(
                              (d) => d?.ad_group_id
                            )}
                            onChange={(e, _) => {
                              // const campaign_id = selectedCampaign?.map(
                              //   (d) => d?.campaign_id
                              // );
                              const newData = _.map(
                                ({ label, ...rest }) => rest
                              );
                              set_selected_ad_group_list(newData);
                              clearDropDownVal("keyword_type");
                              // GetAllCampaignListDetails(
                              //   "keyword_type",
                              //   selectedCampaignType,
                              //   campaign_id,
                              //   e
                              // );
                            }}
                          />
                          <div className="mx-2">
                            {nextStepLoading?.keyword_type_loading ? (
                              <Spin />
                            ) : (
                              <i
                                onClick={() => {
                                  if (selected_ad_group_list?.length > 0) {
                                    const campaign_id = selectedCampaign?.map(
                                      (d) => d?.campaign_id
                                    );
                                    clearDropDownVal("keyword_type");
                                    setNextStepLoading({
                                      ...nextStepLoading,
                                      keyword_type_loading: true,
                                    });
                                    GetAllCampaignListDetails(
                                      "keyword_type",
                                      selectedCampaignType,
                                      campaign_id,
                                      selected_ad_group_list
                                    );
                                  } else {
                                    DisplayNotification(
                                      "info",
                                      "Info",
                                      "Please select ad group"
                                    );
                                  }
                                }}
                                className="ki-outline ki-right"
                                style={{
                                  fontSize: "25px",
                                  background: "#deedde",
                                  borderRadius: "5px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {selected_ad_group_list?.length > 0 &&
                    keyword_type_list?.length > 0 ? (
                      <div className="position-relative">
                        <label className="fw-bold fs-6">Keyword Type</label>
                        <div className="d-flex align-items-center">
                          <Select
                            style={{ width: "250px" }}
                            className="mb-2 mt-0"
                            size="large"
                            placeholder="Select Keyword Type"
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.keyword_type ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            maxTagCount={"responsive"}
                            options={keyword_type_list?.map((d) => {
                              return {
                                ...d,
                                label: (
                                  <div>
                                    {d?.keyword_type}
                                    <div className="text-muted fs-7">
                                      Ad Group:{d?.ad_group_name}
                                    </div>
                                  </div>
                                ),
                                value: d?.id,
                              };
                            })}
                            value={selected_keyword_type_list?.map(
                              (d) => d?.id
                            )}
                            onChange={(e, _) => {
                              // const campaign_id = selectedCampaign?.map(
                              //   (d) => d?.campaign_id
                              // );
                              const newData = _.map(
                                ({ label, ...rest }) => rest
                              );
                              set_selected_keyword_type_list(newData);
                              clearDropDownVal("keyword");
                              // GetAllCampaignListDetails(
                              //   "keyword",
                              //   selectedCampaignType,
                              //   campaign_id,
                              //   selected_ad_group_list,
                              //   e
                              // );
                            }}
                          />{" "}
                          <div className="mx-2">
                            {nextStepLoading?.keyword_loading ? (
                              <Spin />
                            ) : (
                              <i
                                onClick={() => {
                                  if (selected_keyword_type_list?.length > 0) {
                                    const campaign_id = selectedCampaign?.map(
                                      (d) => d?.campaign_id
                                    );
                                    clearDropDownVal("keyword");
                                    setNextStepLoading({
                                      ...nextStepLoading,
                                      keyword_loading: true,
                                    });
                                    GetAllCampaignListDetails(
                                      "keyword",
                                      selectedCampaignType,
                                      campaign_id,
                                      selected_ad_group_list,
                                      selected_keyword_type_list
                                    );
                                  } else {
                                    DisplayNotification(
                                      "info",
                                      "Info",
                                      "Please select ad keyword type"
                                    );
                                  }
                                }}
                                className="ki-outline ki-right"
                                style={{
                                  fontSize: "25px",
                                  background: "#deedde",
                                  borderRadius: "5px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {selected_keyword_type_list?.length > 0 &&
                    keyword_list?.length > 0 ? (
                      <div className="position-relative">
                        <label className="fw-bold fs-6">Keywords</label>
                        <div className="d-flex align-items-center">
                          <Select
                            style={{ width: "250px" }}
                            className="me-2 mb-2 mt-0"
                            size="large"
                            placeholder="Select Keyword"
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                              (option?.keyword ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            maxTagCount={"responsive"}
                            options={keyword_list?.map((d) => {
                              return {
                                ...d,
                                label: (
                                  <div>
                                    {d?.keyword}
                                    <div className="text-muted fs-7">
                                      Ad Group:{d?.ad_group_name}
                                    </div>
                                  </div>
                                ),
                                value: d?.keyword_id,
                              };
                            })}
                            value={selected_keyword_list?.map(
                              (d) => d?.keyword_id
                            )}
                            onChange={(e, _) => {
                              const newData = _.map(
                                ({ label, ...rest }) => rest
                              );
                              set_selected_keyword_list(newData);
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="mx-2">
              <i
                onClick={() => {
                  SaveAllHeaderData();
                }}
                className="ki-outline ki-right"
                style={{
                  fontSize: "25px",
                  background: "#deedde",
                  borderRadius: "5px",
                }}
              />
            </div> */}
                  </div>
                </div>
              </div>
              <div className="separator separator-dashed my-2 " />
              <div className="table-responsive mt-10">
                {loading ? (
                  [...Array(5)]?.map((d, i) => {
                    return (
                      <div className="row my-10" key={i}>
                        <div className="col-2">
                          <Skeleton.Button block active />
                        </div>
                        <div className="col-2">
                          <Skeleton.Button block active />
                        </div>
                        <div className="col-2">
                          <Skeleton.Button block active />
                        </div>
                        <div className="col-2">
                          <Skeleton.Button block active />
                        </div>
                        <div className="col-2">
                          <Skeleton.Button block active />
                        </div>
                        <div className="col-2">
                          <Skeleton.Button block active />
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <EsfTable
                    // footer={() => 'Control your spend with bid multipliers for each day of the week or each hour of the day.'}
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                  />
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="text-end">
                    {flag !== "view" && (
                      <button
                        onClick={() => {
                          if (!profileName || selectedCampaign?.length == 0) {
                            DisplayNotification(
                              "info",
                              "Info",
                              "Please enter profile name and select campaign first"
                            );
                          } else {
                            saveChanges();
                          }
                        }}
                        className="btn btn-primary mt-10 "
                        disabled={saving}
                      >
                        Save Changes
                        {saving && (
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        )}
                      </button>
                    )}
                    <button
                      className="btn btn-light-danger ms-5 mt-10"
                      onClick={() => {
                        setFlag("create");
                        setUpdateData({});
                        setIsOpenDaypartingConfig(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DaypartingConfiguration;
